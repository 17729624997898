import router from "../router";

export default class RouterService {
    static build() {
        return new RouterService();
    }
    async toDashboard() {
        await router.replace({name: 'dashboard'});
    }
}
