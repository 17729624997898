import { global } from "@/config/constants";

export default {
  methods: {
    setServidorChamefacil(url, api_url) {
      global.api.baseUrlChamefacil = url + "/api";
      global.api.baseUrlChamefacil_api = api_url + "/api";
    },
  },
};
