<template>
  <div>
    <div class="p-lg-3">
      <div class="row" justify-content-between>
        <h3 class="mt-0 mb-2 font-weight-bold text-left">
          Relatório Quantitativo por Profissional
        </h3>
      </div>
    </div>

    <div class="mt-2 mx-1 d-flex" style="gap: 1rem">
      <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        :return-value.sync="datas"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="computedFormatDate"
            label="Data inicial - Data final"
            prepend-icon="mdi-calendar"
            clearable
            v-bind="attrs"
            v-on="on"
            @click:clear="datas = null"
            outlined
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="datas"
          no-title
          range
          scrollable
          color="green darken-4"
        >
          <v-spacer></v-spacer>
          <v-btn text @click="menu = false"> Cancelar </v-btn>
          <v-btn dark color="success" @click="$refs.menu.save(datas)">
            OK
          </v-btn>
        </v-date-picker>
      </v-menu>
      <v-select
        :items="unidades"
        label="Unidade"
        item-value="id"
        v-model="unidadeSelected"
        outlined
      ></v-select>

      <v-select
        v-model="filtroPesquisaSelected"
        :items="filtroPesquisa"
        label="Profissionais"
        item-text="nome"
        item-value="id"
        return-object
        multiple
        outlined
      >
        <template v-slot:prepend-item>
          <v-list-item ripple @mousedown.prevent @click="toggle">
            <v-list-item-action>
              <v-icon :color="likesAllProfissional ? 'indigo darken-4' : ''">
                {{ icon }}
              </v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Selecionar Todos</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider class="mt-2"></v-divider>
        </template>
        <!-- ... Restante do seu código ... -->
      </v-select>

      <!--<v-autocomplete
        :items="filtroPesquisa"
        label="Profissionais"
        item-text="nome"
        item-value="id"
        return-object
        v-model="filtroPesquisaSelected"
        outlined
      ></v-autocomplete> -->

      <v-col class="d-flex">
        <v-speed-dial
          v-model="fab"
          :direction="'bottom'"
          :open-on-hover="hover"
          :transition="transition"
        >
          <template v-slot:activator>
            <v-btn
              class="mb-7 btnWid"
              style="width: 9rem; height: 3.2rem"
              dark
              color="primary"
            >
              <v-icon v-if="fab"> mdi-close </v-icon>
              <span v-else> Exportar </span>
            </v-btn>
          </template>

          <div class="mt-n5">
            <v-btn
              dark
              color="green"
              class="btnWid"
              @click="exportarPdfAtendimentos"
            >
              PDF
            </v-btn>
            <v-btn
              dark
              color="indigo"
              class="btnWid"
              @click="exportarExcelAtendimentos"
            >
              EXCEL
            </v-btn>
          </div>
        </v-speed-dial>
      </v-col>
    </div>
  </div>
</template>
<script>
import UnidadeService from "../services/unidade_service";
import TipoProcedimentoService from "../services/tipo_procedimento_service";
import ProcedimentoService from "../services/procedimento_service";
import RelatorioAtendimentosService from "../services/relatorio_atendimentos_service";
import Mixin from "@/mixins/vuex_mixin.js";

export default {
  mixins: [Mixin],
  data() {
    return {
      loading: false,
      menu: false,
      datas: null,
      tipoFiltro: [{ id: 100, descricao: "Profissional" }],
      tipoFiltroSelected: "",
      filtroPesquisa: [],
      filtroPesquisaSelected: [],
      loadingFiltroPesquisa: false,
      unidades: [],
      unidadeSelected: "",
      options: {
        page: 1,
        pageCount: 0,
        itemsPerPage: 15,
        ordenacao: false,
        desc: false,
      },
      totalList: 10,
      perfil: null,
      usuarioId: null,
      fab: false,
      services: {
        unidadeService: UnidadeService.build(),
        tipoProcedimentoService: TipoProcedimentoService.build(),
        procedimentoService: ProcedimentoService.build(),
        relatorioAtendimentosService: RelatorioAtendimentosService.build(),
      },
      rules: {
        required: (value) => !!value || "Campo obrigatório.",
      },
    };
  },
  computed: {
    computedFormatDate() {
      const datasFormatadas =
        this.datas &&
        this.datas.map((item) =>
          item
            .split("-")
            .reverse()
            .join("/")
        );
      return datasFormatadas;
    },
    likesAllProfissional() {
      return this.filtroPesquisaSelected.length === this.filtroPesquisa.length;
    },
    likesSomeProfissional() {
      return (
        this.filtroPesquisaSelected.length > 0 && !this.likesAllProfissional
      );
    },
    icon() {
      if (this.likesAllProfissional) return "mdi-close-box";
      if (this.likesSomeProfissional) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
  },
  watch: {
    /*async unidadeSelected() {
      this.loadingFiltroPesquisa = true;
      await this.services.relatorioAtendimentosService
        .getProfissionaisAssistentesMedicos(this.unidadeSelected)
        .then((resp) => resp.json())
        .then((resp) => {
          const profissionais = [
            { id: 0, nome: "Todos" },
            ...resp.data.profissionais,
          ];
          this.filtroPesquisa = profissionais;
        });

      this.loadingFiltroPesquisa = false;
    },*/
    async unidadeSelected() {
      if (this.unidadeSelected !== null && this.unidadeSelected !== undefined) {
        this.loadingFiltroPesquisa = true;

        // Chame o serviço para obter os procedimentos correspondentes
        this.filtroPesquisa = this.filtroPesquisa.concat(
          await this.services.relatorioAtendimentosService
            .getProfissionaisAssistentesMedicos(this.unidadeSelected)
            .then((resp) => resp.json())
            .then((resp) => {
              return resp.data.profissionais.map((item) => {
                return {
                  id: item.id,
                  nome: item.nome,
                };
              });
            })
        );

        this.loadingFiltroPesquisa = false;
      }
    },
  },
  mounted() {
    this.setUnidades();
    this.unidadeSelected = JSON.parse(
      sessionStorage.getItem("vuex")
    ).unidade.id.toString();
    this.perfil = JSON.parse(sessionStorage.getItem("vuex")).perfil.id;
    this.usuarioId = JSON.parse(sessionStorage.getItem("vuex")).usuario.id;
    this.setServicos();
  },
  methods: {
    setUnidades() {
      this.services.unidadeService
        .getUnidadeByUsuarioLogado()
        .then((response) => {
          return response.json();
        })
        .then((response) => {
          this.unidades = response.data.map((unidade) => {
            return { text: unidade.unidade, id: unidade.id_unidade };
          });
        })
        .then(() => {});
    },
    toggle() {
      this.$nextTick(() => {
        if (this.likesAllProfissional) {
          this.filtroPesquisaSelected = [];
        } else {
          this.filtroPesquisaSelected = [...this.filtroPesquisa];
        }
      });
    },
    async setServicos() {
      await this.services.tipoProcedimentoService
        .getTipoProcedimentos()
        .then((resp) => resp.json())
        .then((resp) =>
          resp.data.tipo_procedimentos.filter((p) => p.id != 1 && p.id != 7)
        )
        .then((resp) => resp.map((p) => this.tipoFiltro.push(p)));
      console.log(this.tipoFiltro);
    },
    getAtendimentos() {
      console.log(this.computedFormatDate);
      console.log(this.unidadeSelected);
      console.log(this.tipoFiltroSelected);
    },
    exportarPdfAtendimentos() {
      if (!this.datas) {
        this.$_ACTIONS_showSnackbarMessage({
          message: "Selecione uma data para o relatório",
          color: "error",
        });
        return;
      }

      const dataInicial = this.datas[0].split("-").join("");
      const dataFinal = this.datas[1].split("-").join("");
      const filtroPesquisaIds = this.filtroPesquisaSelected.map(
        (item) => item.id
      );

      this.services.relatorioAtendimentosService
        .pdfRelatorioAtendimentosProfissionalQuantitativo(
          dataInicial,
          dataFinal,
          this.unidadeSelected,
          filtroPesquisaIds
        )
        .then((resp) => {
          if (resp.status) {
            this.$_ACTIONS_showSnackbarMessage({
              message: "Falha ao carregar",
              color: "error",
            });
            return;
          }
          this.$_ACTIONS_showSnackbarMessage({
            message: "Dados encontrados com sucesso! Gerando PDF...",
            color: "sucess",
          });
          let link = document.createElement("a");
          // link.target = "_blank";
          link.download = `${dataInicial}-${dataFinal}.pdf`;
          let url = window.URL.createObjectURL(resp);
          link.href = url;
          link.click();
        })
        .catch(() => {
          this.$_ACTIONS_showSnackbarMessage({
            message: "Falha ao carregar",
            color: "error",
          });
        });
    },
    exportarExcelAtendimentos() {
      this.loadingPdf = true;
      if (!this.datas) {
        this.$_ACTIONS_showSnackbarMessage({
          message: "Selecione uma data para o relatório",
          color: "error",
        });
        return;
      }

      const dataInicial = this.datas[0].split("-").join("");
      const dataFinal = this.datas[1].split("-").join("");
      const filtroPesquisaIds = this.filtroPesquisaSelected.map(
        (item) => item.id
      );

      this.services.relatorioAtendimentosService
        .excelRelatorioAtendimentosProfissionalQuantitativo(
          dataInicial,
          dataFinal,
          this.unidadeSelected,
          filtroPesquisaIds
        )
        .then((resp) => {
          console.log(resp);
          if (resp.status) {
            this.$_ACTIONS_showSnackbarMessage({
              message: "Falha ao carregar",
              color: "error",
            });
            return;
          }
          this.$_ACTIONS_showSnackbarMessage({
            message: "Dados encontrados com sucesso! Gerando PDF...",
            color: "sucess",
          });
          let link = document.createElement("a");
          // link.target = "_blank";
          link.download = `relatorio-atendimento-profissional.xlsx`;
          let url = window.URL.createObjectURL(resp);
          link.href = url;
          link.click();
        })
        .catch(() => {
          this.$_ACTIONS_showSnackbarMessage({
            message: "Falha ao carregar",
            color: "error",
          });
        })
        .finally(() => {
          this.loadingPdf = false;
        });
    },
  },
};
</script>

<style>
.btnWid {
  width: 9rem;
}
</style>
