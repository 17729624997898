<template>
  <div>
    <div class="p-lg-3">
      <div class="row justify-content-between">
        <h3 class="mt-0 mb-2 font-weight-bold text-left">Minhas Consultas</h3>
      </div>
    </div>
    <v-data-table
      v-on:update:sort-by="options.ordenacao = true"
      :footer-props="{ 'items-per-page-options': [5, 10, 15, 20] }"
      :search="search"
      :headers="headers"
      :items="consultas"
      :options.sync="options"
      :server-items-length="totalList"
      class="elevation-1"
      :loading="loading"
      :loading-text="'Carregando...'"
    >
      <!-- <template v-slot:top> -->
      <!-- <v-toolbar flat> -->
      <!-- DIALOG DE CADASTRO -->
      <!-- <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-spacer></v-spacer>
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Pesquisar pelo nome do pet"
                single-line
                hide-details
              />
            </v-toolbar> -->
      <!-- </template> -->
      <template v-slot:item.actions="{ item }">
        <div v-if="!item.isCancelado">
          <div class="col-6 text-left">
            <button
              v-if="item.isConfirmado"
              class="btn btn-app-primary btn-rounded font-weight-bold"
            >
              <span> Acessar </span>
            </button>
          </div>
          <v-row v-if="!item.isConfirmado" class="mb-2">
            <!--            <div class="col-3 text-left mx-5">-->
            <!--              <button class="btn btn-app-primary btn-rounded font-weight-bold">-->
            <!--                <span> Reagendar </span>-->
            <!--              </button>-->
            <!--            </div>-->

            <button
              @click="showCancelarConsulta(item)"
              class="btn btn-app-primary btn-rounded font-weight-bold"
              v-if="item.isCancelado"
            >
              {{ item.isCancelado }}
              <span> Cancelar </span>
            </button>
            <v-dialog v-model="dialogCancelarConsulta" max-width="500px">
              <v-card>
                <div v-if="!loadingCancelar">
                  <v-card-title class="headline" style="word-break: normal"
                    >Tem certeza de que deseja desmarcar a consulta?
                  </v-card-title>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="#3E682A" text @click="closeCancelarConsulta">
                      Não
                    </v-btn>
                    <v-btn
                      class="btn-danger"
                      text
                      style="color: #fff !important"
                      @click="cancelarConsulta"
                    >
                      Sim
                    </v-btn>
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </div>
                <v-card-actions v-else>
                  <v-spacer></v-spacer>
                  <v-spacer></v-spacer>
                  <v-row>
                    <v-progress-circular
                      class="py-5 my-5"
                      :size="70"
                      :width="5"
                      indeterminate
                      color="green"
                    ></v-progress-circular>
                  </v-row>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <button
              @click="getMeeting(item)"
              class="btn btn-app-primary btn-rounded font-weight-bold m-0"
              v-if="item.statusMeeting != null"
            >
              <span> Acessar Meeting </span>
            </button>
          </v-row>
        </div>
      </template>
      <template v-slot:no-data>
        <p class="text-center">Nenhum registro encontrado</p>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import Mixin from "@/mixins/vuex_snackbar_mixin.js";
import VuexUsuarioMixin from "@/mixins/vuex_usuario_mixin.js";
import MinhasConsultasService from "@/services/minhas_consultas_service.js";

export default {
  mixins: [Mixin, VuexUsuarioMixin],
  components: {},
  data: () => ({
    loadingCancelar: false,
    consultaCancelar: {},
    dialogCancelarConsulta: false,
    search: "",
    options: {
      page: 1,
      pageCount: 0,
      itemsPerPage: 15,
      order: false,
      desc: false,
    },
    totalList: 10,
    consultas: [],
    headers: [
      {
        text: "data",
        align: "start",
        sortable: true,
        value: "data",
      },
      { text: "Pet", value: "pet", sortable: false },
      { text: "Especialidade", value: "especialidade", sortable: false },
      { text: "Unidade", value: "unidade", sortable: false },
      { text: "Status", value: "status", sortable: false },
      { text: "", align: "end", value: "actions", sortable: false },
    ],
    loading: false,
    minhasConsultasService: MinhasConsultasService.build(),
  }),

  watch: {
    search() {},
    options: {
      handler() {
        // this.options.desc = this.options.sortDesc[0] ?? false
        this.setMinhaConsulta();
      },
      deep: true,
    },
  },
  beforeCreate() {},
  mounted() {
    this.setMinhaConsulta();
  },
  computed: {},
  methods: {
    async setMinhaConsulta() {
      this.loading = true;
      await this.minhasConsultasService
        .getMinhasConsultas(this.getPacienteId(), this.getPaginationParams())
        .then(async (response) => {
          if (response.status !== 200) return;
          const { data } = await response.json();
          const { pagination, consultas } = data;
          this.totalList = pagination.num_rows;
          this.consultas = consultas.map((consulta) =>
            this.mapConsulta(consulta)
          );
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getPacienteId() {
      return this.$_GETTERS_usuario.id;
    },
    mapConsulta(consulta) {
      return {
        data: new Date(
          consulta.data + " " + consulta.hora_inicio
        ).toLocaleString("pt-BR"),
        pet: consulta.nome_pet,
        especialidade: consulta.procedimento,
        isConfirmado: false,
        status: consulta.status ?? "Agendado",
        isCancelado: false, // consulta.status === "cancelado",
        agendamentoId: consulta.id_agendamento,
        unidade: consulta.unidade_descricao ?? "Não informado",
        statusMeeting: consulta.status_meeting,
      };
    },
    getPaginationParams() {
      return {
        page: this.options.page,
        per_page: this.options.itemsPerPage,
        sort: this.options.order,
        desc: this.options.desc,
        search: this.search,
      };
    },
    showCancelarConsulta(consulta) {
      this.dialogCancelarConsulta = true;
      this.consultaCancelar = consulta;
    },
    closeCancelarConsulta() {
      this.dialogCancelarConsulta = false;
    },
    async cancelarConsulta() {
      this.loadingCancelar = true;
      await this.minhasConsultasService
        .cancelarConsulta(this.consultaCancelar.agendamentoId)
        .then(async (response) => {
          if (response.status !== 200) this.showMensagemErro();
          const { data } = await response.json();
          this.$_ACTIONS_showSnackbarMessage({
            text: data.message,
            color: "success",
          });
          this.setMinhaConsulta();
        })
        .finally(() => {
          this.loadingCancelar = false;
          this.closeCancelarConsulta();
        });
    },
    showMensagemErro() {
      this.$_ACTIONS_showSnackbarMessage({
        message: "Erro ao desmarcar consulta.",
        color: "error",
      });
    },
  },
};
</script>
<style scoped>
.btn-app-aguardo {
  background-color: #f0a211 !important;
  color: black !important;
}
</style>
