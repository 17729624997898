import { LogoHeader, FormatarData, DataHj } from "./utils_docs";

// function contrato(pet,tutor,unidade,hospital) {
export function AtestadoSaude(
  nomeHospitalConvenio,
  informacoes_unidade,
  tutor,
  pet,
  convenioPublico,
  responsavel
) {
  let imagem = LogoHeader(convenioPublico);
  console.log("informacoes_unidade", informacoes_unidade);

  let modelo = {
    content: [
      {
        columns: [
          {
            image: imagem,
            width: 50,
            align: "center",
          },
          {
            width: "*",
            text: [
              {
                text: `Unidade: ${informacoes_unidade.nome}\n`,
                fontSize: 8,
                alignment: "right",
              },
              {
                text: `${informacoes_unidade.logradouro}, ${informacoes_unidade.numero} - ${informacoes_unidade.cidade}\n`,
                fontSize: 8,
                alignment: "right",
              },
              {
                text: `CEP: ${informacoes_unidade.cep}\n`,
                fontSize: 8,
                alignment: "right",
              },
              {
                text: `Tel: ${informacoes_unidade.telefone}\n`,
                fontSize: 8,
                alignment: "right",
              },
              {
                text: `Cnpj: ${informacoes_unidade.cnpj}\n`,
                fontSize: 8,
                alignment: "right",
              },
            ],
          },
        ],
      },
      {
        margin: [0, 10, 0, 5],
        table: {
          widths: ["*", "*", "*", "*", "*"],
          heights: ["*", 30],
          body: [
            [
              { text: "Laboratório", style: "contentTable" },
              { text: "Nome comercial", style: "contentTable" },
              { text: "Data da aplicação", style: "contentTable" },
              {
                text: "Validade da vacinação",
                style: "contentTable",
              },
              { text: "Nº do lote", style: "contentTable" },
            ],
            [
              { text: " " },
              { text: " " },
              { text: " " },
              { text: " " },
              { text: " " },
            ],
          ],
        },
      },
      {
        text: "ATESTADO DE SAÚDE PARA VIAGENS DE CÃES E GATOS",
        alignment: "center",
        style: "header",
        bold: true,
      },
      {
        margin: [0, 10, 0, 10],
        table: {
          widths: ["*", "*", "*", "*", "*", "*", "*", "*"],
          body: [
            [
              {
                text: "1. IDENTIFICAÇÃO DO PROPRIETÁRIO",
                style: ["sublilhado", "marginTable"],
                bold: true,
                colSpan: 8,
              },
              {},
              {},
              {},
              {},
              {},
              {},
              {},
            ],
            [
              {
                text: "Nome completo:",
                style: ["marginTable"],
                bold: true,
                colSpan: 2,
              },
              {},
              { text: `${tutor.nome}`, style: ["marginTable"], colSpan: 6 },
              {},
              {},
              {},
              {},
              {},
            ],
            [
              {
                text: "CPF/Passaporte:",
                style: ["marginTable"],
                bold: true,
                colSpan: 2,
              },
              {},
              { text: `${tutor.cpf}`, style: ["marginTable"], colSpan: 2 },
              {},
              {
                text: "Telefone:",
                style: ["marginTable"],
                bold: true,
                colSpan: 2,
              },
              {},
              {
                text: `${tutor.telefone_1}`,
                style: ["marginTable"],
                colSpan: 2,
              },
              {},
            ],
            [
              {
                text: "Endereço:",
                style: ["marginTable"],
                bold: true,
                colSpan: 2,
              },
              {},
              {
                text: `${tutor.logradouro}, ${tutor.numero}`,
                style: ["marginTable"],
                colSpan: 6,
              },
              {},
              {},
              {},
              {},
              {},
            ],
            [
              {
                text: "Cidade:",
                style: ["marginTable"],
                bold: true,
                colSpan: 2,
              },
              {},
              { text: `${tutor.cidade}`, style: ["marginTable"], colSpan: 2 },
              {},
              { text: "UF:", style: ["marginTable"], bold: true, colSpan: 2 },
              {},
              { text: `${tutor.estado}`, style: ["marginTable"], colSpan: 2 },
              {},
            ],
            [
              {
                text: "2. IDENTIFICAÇÃO DO ANIMAL",
                style: ["sublilhado", "marginTable"],
                bold: true,
                colSpan: 8,
              },
              {},
              {},
              {},
              {},
              {},
              {},
              {},
            ],
            [
              { text: "Nome:", style: ["marginTable"], bold: true },
              { text: `${pet.nome}`, style: ["marginTable"], colSpan: 3 },
              {},
              {},
              {
                text: "Data de nascimento:",
                style: ["marginTable"],
                bold: true,
                colSpan: 2,
              },
              {},
              {
                text: `${FormatarData(pet.nascimento)}`,
                style: ["marginTable"],
                colSpan: 2,
              },
              {},
            ],
            [
              {
                text: "Espécie:",
                style: ["marginTable"],
                bold: true,
              },
              {
                text: `${pet.especie}`,
                style: ["marginTable"],
                colSpan: 3,
              },
              {},
              {},
              {
                text: "Sexo:",
                style: ["marginTable"],
                bold: true,
              },
              {
                text: `${pet.sexo}`,
                style: ["marginTable"],
              },
              {
                text: "Porte:",
                style: ["marginTable"],
                bold: true,
              },
              {
                text: `${pet.porte}`,
                style: ["marginTable"],
              },
            ],

            [
              { text: "Raça:", style: ["marginTable"], bold: true },
              { text: `${pet.raca}`, style: ["marginTable"], colSpan: 3 },
              {},
              {},
              { text: "Pelagem:", style: ["marginTable"], bold: true },
              { text: `${pet.cor}`, style: ["marginTable"], colSpan: 3 },
              {},
              {},
            ],
            [
              { text: "Microchip:", style: ["marginTable"], bold: true },
              { text: `${pet.chip}`, style: ["marginTable"], colSpan: 3 },
              {},
              {},
              {
                text: "Data de aplicação:",
                style: ["marginTable"],
                bold: true,
                colSpan: 2,
              },
              {},
              {
                text: `${pet.dataAplicacao}`,
                style: ["marginTable"],
                colSpan: 2,
              },
              {},
            ],
            [
              {
                text: [
                  {
                    text: "3. VACINA ANTI-RÁBICA:\n\n\n\n",
                    style: ["sublilhado"],
                    bold: true,
                  },
                  {
                    text:
                      "*para viagens à União Europeia é necessário anexar laudo sorológico conforme ",
                    style: ["textSmall"],
                  },
                  {
                    text: "Regulamento (UE) 576/2013.",
                    style: ["colorAzul"],
                  },
                ],
                style: ["marginTable"],
                colSpan: 8,
              },
            ],
            [
              {
                text: [
                  {
                    text: "4. INFORMAÇÕES SANITÁRIAS\n",
                    style: ["sublilhado"],
                    bold: true,
                  },
                  {
                    text:
                      "Atesto para os devidos fins que o animal acima identificado, foi por mim examinado, está clinicamente sadio, não apresenta sinais de doenças infectocontagiosas e parasitárias, inclusive livre da presença de miíases, e até onde alcança meu conhecimento não foi diagnosticado positivamente para leishmaniose, portanto está apto para o transporte.",
                  },
                ],
                style: ["marginTable"],
                colSpan: 8,
              },
            ],
            [
              {
                text: [
                  {
                    text: "5. TRATAMENTO ANTIPARASITÁRIO\n",
                    style: ["sublilhado"],
                    bold: true,
                  },
                  {
                    text: "ANTIPARASITÁRIO INTERNO:\n\n\n",
                    bold: true,
                  },
                  {
                    text: "ANTIPARASITÁRIO EXTERNO:\n\n\n",
                    bold: true,
                  },
                  {
                    text:
                      "*aplicados dentro dos 15 (quinze) dias anteriores à emissão do CVI no caso de viagens ao Mercosul",
                    style: ["textSmall"],
                  },
                ],
                style: ["marginTable"],
                colSpan: 8,
              },
            ],
            [
              {
                text: [
                  {
                    text: "6. INFORMAÇÕES ADICIONAIS\n",
                    style: ["sublilhado"],
                    bold: true,
                  },
                  {
                    text:
                      "INUTILIZAR ESTE CAMPO CASO NÃO SEJA NECESSÁRIO.\n\n\n\n",
                  },
                ],
                style: ["marginTable"],
                colSpan: 8,
              },
            ],
            [
              {
                text: [
                  {
                    text: `Município/UF:${informacoes_unidade.cidade} ${
                      informacoes_unidade.estado
                    } Data: ${DataHj()}\n`,
                    style: "sublilhadoUnidade",
                    margin: [0, 0, 0, 4],
                    alignment: "center",
                  },
                  {
                    text: `Médico Veterinário: ${
                      responsavel.med
                        ? responsavel.nome
                        : "                                                                    "
                    } - CRMV:  ${responsavel.med ? responsavel.crmv : ""}`,
                    alignment: "center",
                  },
                ],
                style: ["marginTable"],
                colSpan: 8,
              },
            ],
          ],
        },
        layout: {
          hLineColor: "#9a9a9a",
          vLineColor: "#9a9a9a",
        },
      },
      {
        margin: [0, 10, 0, 4],
        pageBreak: "before",
        table: {
          widths: ["*", "*", "*", "*"],
          heights: ["*", 70, "*", 70],
          body: [
            [
              {
                text: "Data de Aplicação",
                bold: true,
              },
              {
                text: "Laboratório",
                bold: true,
              },
              {
                text: "Nome Comercial(s)",
                bold: true,
              },
              {
                text: "Princípio ativo(s)",
                bold: true,
              },
            ],
            [
              {
                text: " ",
              },
              {
                text: " ",
              },
              {
                text: " ",
              },
              {
                text: " ",
              },
            ],
            [
              {
                text: "Data de Aplicação",
                bold: true,
              },
              {
                text: "Laboratório",
                bold: true,
              },
              {
                text: "Nome Comercial(s)",
                bold: true,
              },
              {
                text: "Princípio ativo(s)",
                bold: true,
              },
            ],
            [
              {
                text: " ",
              },
              {
                text: " ",
              },
              {
                text: " ",
              },
              {
                text: " ",
              },
            ],
          ],
        },
      },
      {
        text: "Informações sobre o trânsito internacional de cães e gatos:",
        style: "textXtrSmall",
      },
      {
        text:
          "http://www.agricultura.gov.br/animal/animais-de-companhia/transporte-internaciona",
        link:
          "http://www.agricultura.gov.br/animal/animais-de-companhia/transporte-internaciona",
        style: "link",
        alignment: "center",
      },
      {
        text: "INFORMAÇÕES SOBRE O TRÂNSITO INTERNACIONAL DE CÃES E GATOS",
        style: "title",
      },
      {
        text:
          "Para viagens internacionais, o atestado acima precisa obrigatoriamente ser encaminhado em conjunto com os demais documentos exigidos conforme o país de destino (devidamente preenchido e assinado por Médico Veterinário com registro válido junto ao Conselho de Medicina Veterinária) ao Ministério da Agricultura, Pecuária e Abastecimento, onde será emitido o Certificado Veterinário Internacional (CVI).",
        style: "text",
      },
      {
        text:
          "Este modelo de atestado atende aos requisitos sanitários dos principais destinos internacionais (Canadá, Chile, EUA, Mercosul, México e União Europeia), contudo o período de validade e os requisitos sanitários variam conforme cada país. É responsabilidade do proprietário verificar as exigências sanitárias atualizadas junto à Embaixada, ao Consulado ou ao órgão veterinário oficial do país de destino.",
        style: "text",
      },
      {
        text:
          "Exames adicionais podem ser solicitados por alguns países, como o teste sorológico contra raiva pela União Europeia.",
        style: "text",
      },
      {
        text:
          "A implantação do microchip é obrigatória para a União Europeia e o tratamento antiparasitário é obrigatório para os países do Mercosul, Chile e México.",
        style: "text",
      },
      {
        text:
          "Mais informações sobre o trânsito internacional de cães e gatos:",
        bold: true,
        style: "text",
      },
      {
        text:
          "http://www.agricultura.gov.br/animal/animais-de-companhia/transporte-internaciona",
        link:
          "http://www.agricultura.gov.br/animal/animais-de-companhia/transporte-internaciona",
        style: "link",
      },
    ],
    styles: {
      header: {
        fontSize: 12,
        bold: true,
        alignment: "center",
        margin: [0, 5, 0, 5],
      },
      sublilhado: {
        fontSize: 11,
        bold: true,
        decoration: "underline",
      },
      sublilhadoUnidade: {
        bold: true,
        decoration: "underline",
      },
      textSmall: {
        fontSize: 6,
      },
      textXtrSmall: {
        fontSize: 9,
        margin: [0, 0, 0, 0],
        alignment: "center",
      },
      link: {
        fontSize: 9,
        color: "#2986CC",
        decoration: "underline",
        margin: [0, 0, 0, 20],
      },
      title: {
        fontSize: 13,
        bold: true,
        alignment: "center",
        margin: [0, 0, 0, 10],
      },
      colorAzul: {
        fontSize: 6,
        color: "#2986CC",
        decoration: "underline",
      },
      text: {
        fontSize: 10,
        margin: [0, 0, 0, 10],
      },
      contentTable: {
        bold: true,
        alignment: "center",
        margin: [0, 5, 0, 5],
      },
      marginTable: {
        margin: [0, 4, 0, 4],
      },
    },
    defaultStyle: {
      fontSize: 9,
    },
    footer: function(currentPage, pageCount) {
      return {
        columns: [
          { width: 20, text: "" },
          {
            width: "*",
            text: {
              text: `${nomeHospitalConvenio}`,
              fontSize: 6,
            },
          },
          {
            width: "*",
            text: {
              text: "Página " + currentPage.toString() + " de " + pageCount,
              alignment: "right",
              fontSize: 6,
            },
          },
          { width: 20, text: "" },
        ],
        margin: [0, 10, 0, 0],
      };
    },
  };

  return modelo;
}
