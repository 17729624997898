var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.pacientes,"expanded":_vm.expanded,"item-key":"IdChamadaInterna","show-expand":""},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.DataCriacao",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.DataCriacao && item.DataCriacao.split(" ")[0] .split("-") .reverse() .join("/")))])]}},{key:"item.HoraCriacao",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.DataCriacao && item.DataCriacao.split(" ")[1]))])]}},{key:"item.tutor",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.NomeTutor || item.UsuarioSenha))])]}},{key:"item.urgencia",fn:function(ref){
var item = ref.item;
return [_c('span',{class:_vm.urgenciaAtendimento(item.ClassificacaoAtendimento)},[_vm._v(" "+_vm._s(item.ClassificacaoAtendimento)+" ")])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"font-weight-bold text-left py-3 bg-white",attrs:{"colspan":headers.length}},[_vm._v(" Observações: "),_c('b',{staticClass:"red--text font-weight-regular"},[_vm._v(_vm._s(item.ObservacaoExtra))])])]}},{key:"item.chamar",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex justify-content-between"},[_c('v-btn',{attrs:{"small":"","depressed":"","color":"success"},on:{"click":function($event){return _vm.chamarSenha(item.IdChamadaInterna)}}},[_vm._v(" Chamar ")]),(_vm.perfilId == 1)?_c('v-icon',{staticClass:"mr-2",attrs:{"color":"primary","title":"Prontuários"},on:{"click":function($event){return _vm.listProntuarios(item)}}},[_vm._v("fas fa-file-medical-alt ")]):_vm._e()],1)]}}])}),(_vm.dialogProntuario)?_c('v-dialog',{attrs:{"fullscreen":"","hide-overlay":"","transition":"dialog-bottom-transition"},model:{value:(_vm.dialogProntuario),callback:function ($$v) {_vm.dialogProntuario=$$v},expression:"dialogProntuario"}},[_c('prontuarios',{attrs:{"consultas":_vm.prontuarios},on:{"close":function($event){_vm.dialogProntuario = false}}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }