<template>
  <div>
    <v-card min-height="100vh">
      <v-toolbar style="background-color: #1daf80; color: #fff">
        <v-btn icon dark @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>
          <span class="headline">Galeria</span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-card-text>
        <v-container>
          <div class="row">
            <!-- FOTOS DE ANTES -->
            <div class="col-lg-4">
              <h3>Antes</h3>
              <div class="card shadow-none">
                <p>Arraste e solte a foto aqui</p>
                <draggable class="list-group" :list="fotoAntes" group="people">
                  <div
                    class="list-group-item"
                    v-for="(foto, i) in fotoAntes"
                    :key="i"
                  >
                    <v-img
                      :src="`${foto.imagem}`"
                      height="150"
                      class="text-right pa-2"
                      contain
                    ></v-img>
                  </div>
                </draggable>
              </div>
            </div>

            <!-- FOTOS DE DEPOIS -->

            <div class="col-lg-4">
              <h3>Depois</h3>
              <div class="card shadow-none">
                <p>Arraste e solte a foto aqui</p>
                <draggable class="list-group" :list="fotoDepois" group="people">
                  <div
                    class="list-group-item"
                    v-for="(foto, i) in fotoDepois"
                    :key="i"
                  >
                    <v-img
                      :src="`${foto.imagem}`"
                      height="150"
                      class="text-right pa-2"
                      contain
                    ></v-img>
                  </div>
                </draggable>
              </div>
            </div>

            <div class="col-lg-4">
              <h3 class="mb-3">Descrição:</h3>
              <p>Adicione uma observação</p>
              <v-textarea v-model="descricao" outlined rows="3"></v-textarea>
              <v-btn
                color="btn-style-green"
                :loading="loadingClassificar"
                @click="classificar_fotos"
              >
                <span> Classificar </span>
              </v-btn>
            </div>

            <!-- GALERIA GERAL -->
            <div class="col-lg-12">
              <h3>Todas as fotos</h3>
              <div>
                <v-btn
                  class="mx-2"
                  fab
                  dark
                  color="#3e682a"
                  v-show="addFoto"
                  @click="addFoto = false"
                >
                  <v-icon dark> mdi-plus </v-icon>
                </v-btn>
                <div class="mb-2" v-show="!addFoto">
                  <v-img
                    lazy-src="https://picsum.photos/id/11/10/6"
                    max-height="150"
                    max-width="250"
                    class="m-auto"
                    :src="preview"
                  ></v-img>
                  <v-file-input
                    v-model="foto"
                    accept="image/png, image/jpeg, image/bmp"
                    placeholder="Adicione uma foto"
                    prepend-icon="mdi-camera"
                    @click:clear="clean"
                    @change="onChange($event)"
                  ></v-file-input>
                  <v-textarea
                    label="Observação"
                    class="col-5 m-auto"
                    v-model="obs"
                    rows="3"
                  ></v-textarea>
                  <v-btn dark color="#3e682a" @click="enviarFoto">
                    Salvar
                  </v-btn>
                </div>
              </div>
              <div class="mt-8" v-if="galeriaFotos.length > 0">
                <v-item-group v-model="selected">
                  <draggable class="row" :list="galeriaFotos" group="people">
                    <div
                      class="col-lg-3"
                      v-for="(foto, i) in galeriaFotos"
                      :key="i"
                    >
                      <v-item>
                        <v-img
                          lazy-src="https://picsum.photos/id/11/10/6"
                          :src="foto.imagem"
                          height="150"
                          class="text-right pa-2"
                          style="border: 3px solid transparent;"
                        >
                          <v-btn
                            @click="dialogConfirmDeletar(foto.id)"
                            class="ml-auto"
                            small
                            icon
                          >
                            <v-hover v-slot="{ hover }">
                              <v-icon
                                medium
                                :color="hover ? '#F44336' : 'gray'"
                              >
                                fas fa-times</v-icon
                              >
                            </v-hover>
                          </v-btn>
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height ma-0"
                              align="center"
                              justify="center"
                            >
                              <v-progress-circular
                                indeterminate
                                color="grey lighten-5"
                              ></v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                      </v-item>
                    </div>
                  </draggable>
                </v-item-group>
              </div>
              <div v-else>
                <p v-if="!buscando" class="text-center">
                  Nenhuma foto encontrada
                </p>
                <v-progress-circular
                  v-else
                  indeterminate
                  color="green"
                ></v-progress-circular>
              </div>
            </div>
          </div>
        </v-container>
        <confirmation-dialog
          v-if="confirmDeletar"
          :enabled="confirmDeletar"
          :dialogColor="'error'"
          :title="'Deletar Foto'"
          :confirmationMessage="'Você tem certeza que deseja deletar a foto?'"
          :confirmationButtonMessage="'Confirmar'"
          :abortButtonMessage="'Cancelar'"
          :confirmationButtonColor="'error'"
          @abort="confirmDeletar = false"
          @confirm="deletarClassificacao"
        ></confirmation-dialog>
        <v-dialog v-model="dialogLoading" hide-overlay persistent width="300">
          <v-card color="white">
            <v-card-text>
              Carregando...
              <v-progress-linear
                indeterminate
                color="green lighten-3"
                class="mb-0"
              ></v-progress-linear>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import Mixin from "@/mixins/vuex_snackbar_mixin.js";
import FotoService from "@/services/fotos_service.js";
import ConfirmationDialog from "@/components/dialogs/confirmation";
import draggable from "vuedraggable";

export default {
  components: {
    draggable,
    ConfirmationDialog,
  },
  props: {
    paciente: Object,
  },
  mixins: [Mixin],
  beforeMount() {
    this.carragarFotos();
  },
  data: () => ({
    loadingClassificar: false,
    buscando: false,
    addFoto: true,
    dialogLoading: false,
    foto: null,
    obs: "",
    preview: "",
    galeriaFotos: [],
    selected: [],
    descricao: "",
    deletedItem: "",
    confirmDeletar: false,
    //Antes e Depois
    fotoAntesSelecionada: {},
    fotoDepoisSelecionada: {},
    fotoAntes: [],
    fotoDepois: [],
    modelGaleriaAntes: null,
    modelGaleriaDepois: null,
  }),
  methods: {
    // srcbase64img (GaleriaFoto) {
    //     const rgxjpeg = /[a-z]+\/jpeg[a-z]+64/;
    //     const rgxpng = /[a-z]+\/png[a-z]+64/;
    //     var src = ""
    //     var base64=""
    //     if(rgxpng.test(GaleriaFoto.imagem)){
    //       base64 = GaleriaFoto.imagem.replace(rgxpng, '')
    //       src = `data:image/png;base64,${base64}`;
    //     }
    //     if(rgxjpeg.test(GaleriaFoto.imagem)){
    //       base64 = GaleriaFoto.imagem.replace(rgxjpeg, '') ;
    //       src = `data:image/jpeg;base64,${base64}`;
    //     }
    //     return src;
    // },
    clean() {
      this.addFoto = true;
    },
    onChange() {
      const file = document.querySelector("input[type=file]").files[0];
      const reader = new FileReader();
      reader.onloadend = (e) => {
        this.preview = e.target.result;
      };
      if (file) {
        reader.readAsDataURL(file);
      }
    },
    enviarFoto() {
      const reader = new FileReader();
      reader.onloadend = (e) => {
        this.salvarFoto(e);
      };
      if (this.foto) {
        reader.readAsDataURL(this.foto);
      }
    },
    salvarFoto(e) {
      if (this.obs == "") {
        this.$_ACTIONS_showSnackbarMessage({
          message: "Campo observação é obrigatório.",
          color: "error",
        });
        return;
      }
      const fotoService = new FotoService();
      this.dialogLoading = true;
      const params = {
        id_clinica: 1,
        id_paciente: this.paciente.id_paciente,
        id_medico: 1,
        descricao: this.obs,
        image: e.target.result,
      };
      fotoService.register(
        {
          onSucess: (status) => (body) => {
            if (status === 200) {
              this.carragarFotos();
              this.$_ACTIONS_showSnackbarMessage({
                message: body.message,
                color: "sucess",
              });
            }
          },
          onError: (error) => {
            console.log(error);
          },
          onEnd: () => {
            this.dialogLoading = false;
            this.foto = null;
            this.addFoto = true;
            this.preview = "";
            this.obs = "";
          },
        },
        params
      );
    },
    carragarFotos() {
      this.buscando = true;
      this.galeriaFotos = [];
      const usuario = {
        paciente: this.paciente.id_paciente,
      };
      const fotoService = new FotoService();
      fotoService.buscar_fotos_nao_classificadas(
        {
          onSucess: (status) => (body) => {
            if (status === 200) {
              body.data.galeria.forEach((foto) => {
                const img = {
                  descricao: foto.descricao,
                  id: foto.id,
                  id_clinica: foto.id_clinica,
                  id_medico: foto.id_medico,
                  id_paciente: foto.id_paciente,
                  imagem: "",
                };
                this.galeriaFotos.push(img);
                this.searchPhoto(foto.id);
              });
            }
          },
          onError: (error) => {
            console.log(error);
          },
          onEnd: () => {
            this.buscando = false;
          },
        },
        usuario
      );
    },
    classificar_fotos() {
      const fotoService = new FotoService();
      this.loadingClassificar = true;
      let params = {
        antes: this.fotoAntes[0].id,
        depois: this.fotoDepois[0].id,
        id_clinica: 1,
        id_medico: 1,
        descricao: this.descricao,
      };
      fotoService.classificar(
        {
          onSucess: (status) => (body) => {
            if (status === 200) {
              this.carragarFotos();
              this.$_ACTIONS_showSnackbarMessage({
                message: body.message,
                color: "sucess",
              });
            }
          },
          onError: (error) => {
            console.log(error);
          },
          onEnd: () => {
            this.loadingClassificar = false;
            this.fotoAntes = [];
            this.fotoDepois = [];
            this.descricao = "";
          },
        },
        params
      );
    },
    searchPhoto(id) {
      const fotoService = new FotoService();
      console.log(id);
      fotoService.getFoto(
        {
          onSucess: (status) => (body) => {
            if (status === 200) {
              for (let i = 0, len = this.galeriaFotos.length; i < len; i++) {
                if (this.galeriaFotos[i].id === id) {
                  this.galeriaFotos[i].imagem = body.data[0].imagem;
                  break;
                }
              }
            }
          },
          onError: (error) => {
            console.log(error);
          },
          onEnd: () => {},
        },
        id
      );
    },
    dialogConfirmDeletar(id) {
      this.deletedItem = id;
      console.log(this.deletedItem);
      this.confirmDeletar = true;
    },
    deletarClassificacao() {
      this.confirmDeletar = false;
      const fotoService = new FotoService();
      fotoService.deleteFoto(
        {
          onSucess: (status) => (body) => {
            if (status === 200) {
              this.$_ACTIONS_showSnackbarMessage({
                message: body.message,
                color: "sucess",
              });
              this.carragarFotos();
            }
          },
          onError: (error) => {
            console.log(error);
          },
          onEnd: () => {},
        },
        this.deletedItem
      );
    },
  },
};
</script>
<style scoped>
.btn-style-green {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background-color: #1daf80 !important;
  height: 30px !important;
  font-size: 15 px;
  padding: 10px !important;
  border-radius: 50px;
  margin: 20px auto;
}
</style>
