<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    offset-y
    origin="top center"
    bottom
    transition="scale-transition"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn class="mx-1" text v-bind="attrs" v-on="on">
        <template v-if="statusCaixa">
          <v-icon left style="color: #afa !important" class="icon-caixa">
            mdi-cash-register
          </v-icon>
          <span class="text-caixa aberto" style="color: #afa !important"
            >Aberto
          </span>
        </template>
        <template v-else>
          <v-icon left style="color: #ccc !important" class="icon-caixa">
            mdi-cash-register
          </v-icon>
          <span class="text-caixa aberto" style="color: #ccc !important"
            >Fechado
          </span>
        </template>
      </v-btn>
    </template>

    <v-card>
      <div class="maxWidth" v-if="!loading">
        <div class="container" v-if="statusCaixa">
          <div class="row">
            <div class="col-12 text-left">
              <p><strong>Usuario:</strong> {{ usuario.nome }}</p>
              <p><strong>Unidade:</strong> {{ unidade.nome }}</p>
              <p>
                <strong>Data Abertura:</strong>
                {{ formatarData(caixaInfo.data_abertura) }}
              </p>
              <p>
                <strong>Hora Abertura:</strong> {{ caixaInfo.hora_abertura }}
              </p>
              <p>
                <strong>Saldo Abertura:</strong>
                {{ formatarSaldo(caixaInfo.saldo_abertura) }}
              </p>
              <p>
                <strong>Saldo em Dinheiro:</strong>
                {{ formatarSaldo(saldoDinheiro) }}
              </p>
            </div>
          </div>
          <div class="row mt-0">
            <div class="col-12">
              <v-btn
                class="w-100"
                dark
                color="#770707"
                @click="openModal('sangriaCaixa')"
              >
                <v-icon left class="icon-caixa">
                  mdi-arrow-down-bold
                </v-icon>
                <span class="text-caixa aberto">Sangria</span>
              </v-btn>
            </div>
          </div>
          <div class="row mt-0">
            <div class="col-12">
              <v-btn
                class="w-100"
                dark
                color="#077748"
                @click="openModal('entradaCaixa')"
              >
                <v-icon left class="icon-caixa">
                  mdi-arrow-up-bold
                </v-icon>
                <span class="text-caixa aberto">Entrada</span>
              </v-btn>
            </div>
          </div>
          <div class="row mt-0">
            <div class="col-12">
              <v-btn
                class="w-100"
                dark
                color="#02004f"
                @click="openModal('fecharCaixa')"
              >
                Fechar Caixa
              </v-btn>
            </div>
          </div>
        </div>
        <div class="container" v-else>
          <div class="row">
            <div class="col-12 text-left">
              <p><strong>Usuario:</strong> {{ usuario.nome }}</p>
              <p><strong>Unidade:</strong> {{ unidade.razao_social }}</p>
            </div>
          </div>
          <div class="row mt-0">
            <div class="col-12">
              <v-btn
                class="w-100"
                dark
                color="#004f09"
                @click="openModal('abrirCaixa')"
              >
                Abrir Caixa
              </v-btn>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-center p-4" v-else>
        <v-progress-circular
          indeterminate
          color="#1daf80"
        ></v-progress-circular>
      </div>
    </v-card>
    <div>
      <v-dialog persistent v-model="dialogAbrirCaixa" max-width="750px">
        <abrir-caixa
          v-if="dialogAbrirCaixa"
          @close="dialogAbrirCaixa = false"
          @response="reponseDialog"
        />
      </v-dialog>
      <v-dialog persistent v-model="dialogFecharCaixa" max-width="750px">
        <fechar-caixa
          v-if="dialogFecharCaixa"
          :caixa="caixaInfoModal"
          @close="dialogFecharCaixa = false"
          @response="reponseDialogGerarPdf($event)"
        />
      </v-dialog>
      <v-dialog persistent v-model="dialogSangriaCaixa" max-width="750px">
        <sangria-caixa
          v-if="dialogSangriaCaixa"
          :caixa="caixaInfoModal"
          @close="dialogSangriaCaixa = false"
          @response="reponseDialog"
        />
      </v-dialog>
      <v-dialog persistent v-model="dialogEntradaCaixa" max-width="750px">
        <entrada-caixa
          v-if="dialogEntradaCaixa"
          :caixa="caixaInfoModal"
          @close="dialogEntradaCaixa = false"
          @response="reponseDialog"
        />
      </v-dialog>
      <v-dialog v-model="dialogGerarPdf" max-width="500px">
        <v-card>
          <v-card-title class="text-h5 toolbar-style">
            Caixa Encerrado
          </v-card-title>

          <v-card-text>
            <h4 class="pt-5 mb-0">
              Gostaria de gerar o ralatório do caixa encerrado?
            </h4>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-btn color="warn" text @click="closeDialogPdf">
              Cancelar
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="detalheCaixaPdf">
              Gerar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </v-menu>
</template>

<script>
import Mixin from "@/mixins/vuex_mixin.js";
import CaixaService from "../../services/caixa_service";
import UnidadeService from "../../services/unidade_service";
import AbrirCaixa from "@/components/caixa/abertura-caixa.vue";
import FecharCaixa from "@/components/caixa/fechamento-caixa.vue";
import SangriaCaixa from "@/components/caixa/sangria-caixa.vue";
import EntradaCaixa from "@/components/caixa/entrada-caixa.vue";

import ExternoEvento from "@/router/eventExterno.js";

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { RelatorioCaixa } from "@/components/documentos/modelos/relatorio_caixa.js";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default {
  mixins: [Mixin],
  components: { AbrirCaixa, FecharCaixa, SangriaCaixa, EntradaCaixa },
  data: () => ({
    statusCaixa: false,
    menu: false,
    caixaInfo: {},
    caixaPdf: {},
    loading: true,
    services: {
      caixaService: CaixaService.build(),
      unidadeService: UnidadeService.build(),
    },
    saldoDinheiro: "0",
    dialogAbrirCaixa: false,
    dialogFecharCaixa: false,
    dialogSangriaCaixa: false,
    dialogEntradaCaixa: false,
    dialogGerarPdf: false,
    caixaValido: false,
    unidade: {},
    caixaInfoModal: {},
    usuario: {},
    unidadeId: ~~JSON.parse(sessionStorage.vuex).unidade.id,
    usuarioId: ~~JSON.parse(sessionStorage.vuex).usuario.id,
  }),
  // beforeMount(){
  //   window.onbeforeunload = function() {
  //       return "";
  //     };
  // },
  watch: {
    menu() {
      this.getUser();
      this.checkCaixa();
    },
  },
  async mounted() {
    this.getUser();
    this.checkCaixa();
    // Ouvindo o evento 'funcao-executada' emitido pelo comanda.component
    ExternoEvento.$on("checkCaixa", this.checkCaixa);
  },
  destroyed() {
    // Removendo o ouvinte do evento quando o componente for destruído
    ExternoEvento.$off("checkCaixa", this.checkCaixa);
  },
  methods: {
    // closeWindow() {
    //   // Adiciona um evento de fechamento de janela ou aba
    //   window.addEventListener("beforeunload", this.handleClose);
    // },
    // handleClose(event) {
    //   // Exibe o alerta quando a janela ou aba está prestes a ser fechada
    //   event.preventDefault();
    //   event.returnValue = "";
    //   alert("Você está prestes a fechar a janela ou aba.");
    // },
    async getUser() {
      this.unidade = await JSON.parse(sessionStorage.getItem("vuex")).unidade;
      this.usuario = await JSON.parse(sessionStorage.getItem("vuex")).usuario
        .dados;
    },
    reponseDialog() {
      this.checkCaixa();
    },
    closeDialogPdf() {
      this.dialogGerarPdf = false;
      this.checkCaixa();
    },
    checkCaixa() {
      this.loading = true;
      this.caixaInfo = {};
      this.services.caixaService
        .checkCaixaByUser(this.usuarioId, this.unidadeId)
        .then(async (resp) => {
          const response = await resp.json();
          if (resp.status == 200) {
            this.caixaInfo = response.data;
            // this.statusCaixa = true;
            // this.caixaValido = true;
            this.detalheCaixa();
          } else if (resp.status == 206) {
            this.statusCaixa = false;
            this.caixaValido = false;
            this.loading = false;
          }
        })
        .catch((error) => {
          this.loading = false;
          console.error(error);
        })
        .finally(() => {});
    },
    detalheCaixa() {
      this.saldoDinheiro = 0;
      this.services.caixaService
        .getCaixaResumo(
          this.caixaInfo.id_usuario,
          this.caixaInfo.id_unidade,
          this.caixaInfo.id
        )
        .then(async (resp) => {
          const response = await resp.json();
          if (resp.status == 200) {
            this.saldoDinheiro = parseFloat(
              response.data.resumo.saldo.replaceAll(",", "")
            ).toFixed(2);
            this.statusCaixa = true;
            this.caixaValido = true;
            this.loading = false;
          } else if (resp.status == 206) {
            this.checkCaixa();
            console.log(response);
          } else {
            console.log(response);
          }
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;

          console.error(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    fecharCaixa() {
      this.menu = false;
      this.dialogFecharCaixa = true;
    },
    abrirCaixa() {
      this.menu = false;
      this.dialogAbrirCaixa = true;
    },
    formatarSaldo(saldo) {
      return new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      }).format(saldo);
    },
    formatarData(data) {
      if (data) {
        return data
          .split("-")
          .reverse()
          .join("/");
      } else {
        return "";
      }
    },
    async openModal(tipo) {
      this.caixaInfoModal = await this.caixaInfo;
      switch (tipo) {
        case "abrirCaixa":
          this.dialogAbrirCaixa = true;
          break;
        case "entradaCaixa":
          this.dialogEntradaCaixa = true;
          break;
        case "fecharCaixa":
          this.dialogFecharCaixa = true;
          break;
        case "sangriaCaixa":
          this.dialogSangriaCaixa = true;
          break;
        default:
          break;
      }
      this.menu = false;
    },
    reponseDialogGerarPdf(info) {
      this.caixaPdf = {};
      this.caixaPdf = info;
      this.dialogFecharCaixa = false;
      this.dialogGerarPdf = true;
    },
    detalheCaixaPdf() {
      this.services.caixaService
        .getCaixaResumoPdf(this.caixaPdf.id_unidade, this.caixaPdf.id)
        .then(async (resp) => {
          const response = await resp.json();
          if (resp.status == 200) {
            this.getUnidadeInfo(response.data, this.caixaPdf.id_unidade);
          } else if (resp.status == 206) {
            console.error(response);
          } else {
            console.error(response);
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {});
    },
    getUnidadeInfo(caixa, unidadeId) {
      this.services.unidadeService
        .getUnidadeById(unidadeId)
        .then(async (resp) => {
          let unidade = resp.data.unidade[0];
          if (unidade) {
            this.gerarPdf(caixa, unidade);
            this.caixaPdf = {};
            this.closeDialogPdf();
          } else {
            console.error(resp);
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {});
    },
    gerarPdf(response, unidade) {
      const docArquivo = RelatorioCaixa(
        response.resumo,
        response.detalhe,
        unidade
      );
      pdfMake.createPdf(docArquivo).download(`Relatorio_caixa.pdf`);
    },
  },
};
</script>
<style scoped>
.icon-caixa {
  font-size: 25px !important;
  margin-right: 8px !important;
}
.text-caixa {
  font-weight: bold !important;
}
.maxWidth {
  max-width: 250px;
}
.toolbar-style {
  background-color: #1daf80;
  color: white;
}
</style>
