<template>
  <v-card>
    <v-toolbar class="bg-verde" dark>
      <v-btn icon dark @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>
        <span class="headline">Edição de Valor</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items> </v-toolbar-items>
    </v-toolbar>
    <div class="px-5">
      <form class="p-lg-3 row">
        <div class="col-lg-12 form-group clearfix mb-n3">
          <v-text-field
            label="Procedimento"
            outlined
            v-model="procedimentoDescricao"
            readonly
            style="padding-top: 0 !important"
          ></v-text-field>
        </div>

        <div class="col-lg-12 form-group clearfix mb-n3">
          <v-text-field
            label="Convênio"
            outlined
            style="padding-top: 0 !important"
            readonly
            v-model="convenioDescricao"
          ></v-text-field>
        </div>

        <div class="col-lg-12 form-group clearfix mb-n3">
          <v-text-field
            label="Unidade"
            outlined
            style="padding-top: 0 !important"
            readonly
            v-model="unidadeDescricao"
          ></v-text-field>
        </div>

        <div class="col-lg-12 form-group clearfix mb-n3">
          <v-text-field
            label="Valor Cliente"
            outlined
            style="padding-top: 0 !important"
            v-model="novoValor"
          ></v-text-field>
        </div>

        <div class="col-lg-12 form-group clearfix mb-n3">
          <v-text-field
            label="Valor Interno"
            outlined
            style="padding-top: 0 !important"
            v-model="novoValorInterno"
          ></v-text-field>
        </div>

        <div class="col-lg-12 form-group clearfix mb-3">
            <label>Valor de Repasse Medico</label>
            <v-text-field
                    style="padding-top: 0 !important"
                    v-model="novoValorRepasseMedico"
            ></v-text-field>
        </div>

        <div class="col-md-12 text-right">
          <button
            @click.prevent="salvarEdicao"
            class="btn btn-app-primary btn-rounded font-weight-bold"
          >
            <span v-if="!loading"> Salvar Edição </span>
            <v-progress-circular
              v-else
              indeterminate
              color="white"
            ></v-progress-circular>
          </button>
        </div>
      </form>
    </div>
  </v-card>
</template>

<script>
import Mixin from "@/mixins/vuex_mixin.js";
import ProcedimentoValorService from "../../services/procedimento_valor_service.js";

export default {
  mixins: [Mixin],

  data: () => ({
    singleSelect: false,
    loading: false,
    formCadastro: "",
    novoValor: "",
    novoValorInterno: "",
    novoValorRepasseMedico: "",
    procedimentoDescricao: "",
    convenioDescricao: "",
    unidadeDescricao: "",
    status: "",
    procedimentoValorService: ProcedimentoValorService.build(),
    rules: {
      required: (value) => !!value || "Campo obrigatório.",
    },
    statusProcedimentoValor: [
      { id: "0", text: "Inativo" },
      { id: "1", text: "Ativo" },
    ],
  }),
  props: {
    procedimentoValor: {
      type: Object,
      required: true,
    },
  },
  async mounted() {
    this.novoValor = this.procedimentoValor.valor;
    this.novoValorInterno = this.procedimentoValor.valor_interno;
    this.novoValorRepasseMedico = this.procedimentoValor.valor_repasse_medico;
    this.procedimentoDescricao = this.procedimentoValor.descricaoProcedimento;
    this.convenioDescricao = this.procedimentoValor.descricaoConvenio;
    this.unidadeDescricao = this.procedimentoValor.descricaoUnidade;
    this.status = this.procedimentoValor.status;
  },
  methods: {
    async salvarEdicao() {
      this.loading = true;
      try {
        this.loading = true;
        const formData = new FormData();
        formData.append("descricao", this.procedimentoValor.id);
        formData.append("valor", this.novoValor);
        formData.append("valor_interno", this.novoValorInterno);
        formData.append("valor_repasse_medico", this.novoValorRepasseMedico);
        const response = await this.procedimentoValorService.editar(
          this.procedimentoValor.id,
          formData
        );
        this.$_ACTIONS_showSnackbarMessage({
          message:
            response.status === 200
              ? "O Valor foi atualizado com sucesso."
              : this.$global.messages.internalServerError,
          color: response.status === 200 ? "sucess" : "error",
        });
        response.status === 200 && this.$emit("response", this.setor);
      } catch (e) {
        console.error({ e });
      }
      this.loading = false;
    },
  },
  computed: {
    allowEdition() {
      return true;
    },
  },
};
</script>
