import HTTPService from "@/services/http_service.js";

export default class DisponibilidadeService extends HTTPService {
  resource = "agenda/televet/disponibilidade";

  static build() {
    return new DisponibilidadeService();
  }

  getDisponibilidade(unidadeId,especialidadeId,dataInicio,dataFinal) {
    dataInicio = dataInicio.replaceAll("-", "");
    dataFinal = dataFinal.replaceAll("-", "");
    return this.get(`${this.resource}/${unidadeId}/${especialidadeId}/${dataInicio}/${dataFinal}`);
  }

  postCriarDisponibilidade(model) {
    return this.post(this.resource, { ...model });
  }
  
  // postUpdateStatusDisponibilidade(model) {
  //   return this.post(`${this.resource}/update-status`, model);
  // }
postUpdateStatusDisponibilidade(responseFunctions, model) {
  this.jsonRequest(
    responseFunctions,
    "POST",
    `${this.resource}/update-status`, model
  );
}
}
