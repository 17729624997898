<template>
  <v-card>
    <v-toolbar style="background-color: #077748; color: #fff">
      <!-- <v-toolbar style="background-color: #1daf80; color: #fff"> -->
      <v-btn icon dark @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>
        <span class="headline">Entrada Caixa</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items> </v-toolbar-items>
    </v-toolbar>
    <div class="px-8 py-6">
      <!-- <button
        @click.prevent="teste"
        class="btn btn-app-primary btn-rounded font-weight-bold"
      >
        <span> Check Caixa </span>
      </button> -->
      <v-card>
        <div>
          <v-card-title class="mx-2"><h3>Caixa</h3></v-card-title>
          <div class="px-3 row md-0 justify-content-between">
            <div class="col-3 text-start">
              <p style="text-capitalize">
                <strong>Usuario:</strong><br />
                {{ getNomeUsuario() }}
              </p>
            </div>
            <div class="col-3 text-start">
              <p>
                <strong>Unidade:</strong><br />
                {{ getUnidade() }}
              </p>
            </div>
            <div class="col-3 text-start">
              <p>
                <strong>Data Abertura:</strong><br />
                {{ formatarData(caixa.data_abertura) }}
              </p>
            </div>
            <div class="col-3 text-start">
              <p>
                <strong>Hora Abertura:</strong><br />
                {{ caixa.hora_abertura }}
              </p>
            </div>
          </div>
        </div>
      </v-card>
      <div class="row justify-content-center mt-5">
        <div class="col-lg-8 form-group clearfix ">
          <v-textarea
            label="Observação"
            v-model="observacao"
            placeholder=" "
            rows="2"
            counter="255"
            maxlength="255"
          ></v-textarea>
        </div>
      </div>
      <div class="row justify-content-center ">
        <div class="col-lg-4 form-group clearfix mb-3">
          <v-text-field
            v-model="valorCaixa"
            v-money="moneyConfig"
            @keydown="verificarTecla($event)"
            type="text"
            step="0.01"
            min="0"
            label="Valor de Entrada"
            style="padding-top: 0 !important"
          ></v-text-field>
        </div>
        <div class="col-4 text-right ">
          <v-btn
            color="#077748"
            @click.prevent="entradaCaixa"
            :dark="formatValor(valorCaixa) > 0 && observacao != ''"
            :disabled="formatValor(valorCaixa) <= 0 || observacao == ''"
          >
            <span v-if="!loading"> Realizar Entrada </span>
            <v-progress-circular
              v-else
              indeterminate
              color="white"
            ></v-progress-circular>
          </v-btn>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
import Mixin from "@/mixins/vuex_mixin.js";
import CaixaService from "../../services/caixa_service";
import { VMoney } from "v-money";

export default {
  mixins: [Mixin],
  props: {
    caixa: {
      type: Object,
      required: true,
    },
  },
  directives: { money: VMoney },
  data: () => ({
    moneyConfig: {
      decimal: ",",
      thousands: ".",
      prefix: "R$ ",
      suffix: "",
      precision: 2,
      masked: true,
    },
    valorCaixa: 0.0,
    observacao: "",
    loading: false,
    services: {
      caixaService: CaixaService.build(),
    },
    unidadeId: ~~JSON.parse(sessionStorage.vuex).unidade.id,
    unidade: ~~JSON.parse(sessionStorage.vuex).unidade.razap_social,
    usuarioId: ~~JSON.parse(sessionStorage.vuex).usuario.id,
    usuario: ~~JSON.parse(sessionStorage.vuex).usuario.dados.nome,
  }),
  mounted() {},
  watch: {},
  methods: {
    getNomeUsuario() {
      return JSON.parse(
        sessionStorage.getItem("vuex")
      ).usuario.dados.nome.toLowerCase();
    },
    getUnidade() {
      return JSON.parse(
        sessionStorage.getItem("vuex")
      ).unidade.nome.toLowerCase();
    },
    verificarTecla(event) {
      if (
        ((event.keyCode >= 65 && event.keyCode <= 90) ||
          (event.keyCode >= 97 && event.keyCode <= 122)) &&
        ![96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 110, 188, 190].includes(
          event.keyCode
        )
      ) {
        event.preventDefault();
      }
    },
    formatarSaldo(saldo) {
      return new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      }).format(saldo);
    },
    entradaCaixa() {
      this.loading = true;
      let body = {
        unidade: this.caixa.id_unidade,
        caixa: this.caixa.id,
        valor: this.formatValor(this.valorCaixa),
        observacao: this.observacao,
      };
      this.services.caixaService
        .entradaCaixa(body)
        .then(async (resp) => {
          const response = await resp.json();
          this.loading = false;
          this.mostrarFeedback(resp.status, response);
          if (resp.status === 200) {
            this.$emit("response");
            this.$emit("close");
          }
        })
        .catch((error) => {
          this.loading = false;
          console.error(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    mostrarFeedback(status, response) {
      this.$_ACTIONS_showSnackbarMessage({
        message:
          status == 200 ? "Entrada realizada com sucesso!" : response.message,
        color: status == 200 ? "sucess" : "error",
      });
    },
    resetForm() {
      this.valorCaixa = "";
    },
    getFormData() {
      return {
        valorCaixa: this.valorCaixa,
      };
    },
    formatarData(data) {
      if (data) {
        return data
          .split("-")
          .reverse()
          .join("/");
      } else {
        return "-";
      }
    },
    formatValor(valor) {
      if (typeof valor == "string") {
        const newValor = parseFloat(
          valor
            .replaceAll("R$", "")
            .replaceAll(".", "")
            .replaceAll(",", ".")
        ).toFixed(2);
        return newValor;
      } else {
        return valor;
      }
      // return parseFloat(valor).toFixed(2)
    },
  },
};
</script>
<style scoped>
.text-capitalize {
  text-transform: capitalize !important;
}
</style>
