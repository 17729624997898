// Tipo de Ambientes
// Dev
// Local
// Homolog
// PreProd
// Prod
const tipoAmbiente = "Homolog";
const urlDev = "https://fc2e-138-121-121-66.ngrok-free.app/";
const caminhoDev = "anclivepa_pv_dev/public";
const ambiente = setAmbiente(tipoAmbiente);

const global = {
  api: {
    baseURL: ambiente.baseURL,
    baseUrlChamefacil: "",
    baseUrlChamefacil_api: "",
  },
  consultas: {
    tempoMinimo: 1,
    idTipoProcedimentoConsulta: 5,
  },
  tiposProcedimentos: {
    //consulta: 5,
    //cirurgia: 6,
    consulta: 2,
    cirurgia: 3,
    compromissoPessoal: 7,
  },
  utils: {
    diasDaSemana: [
      { value: 0, label: "Domingo" },
      { value: 1, label: "Segunda-feira" },
      { value: 2, label: "Terça-feira" },
      { value: 3, label: "Quarta-feira" },
      { value: 4, label: "Quinta-feira" },
      { value: 5, label: "Sexta-feira" },
      { value: 6, label: "Sábado" },
    ],
  },
  profiles: [
    { id: 1, description: "admin" },
    { id: 2, description: "medico" },
    { id: 3, description: "assistente" },
    { id: 4, description: "paciente" },
    { id: 56, description: "recepcao" },
  ],
  snackbar: {
    colors: {
      sucess: "#3E682A",
      error: "error",
      warning: "#FFCA28",
      blue: "#5684da",
    },
  },
  messages: {
    internalServerError:
      "Ocorreu um erro durante o processamento da sua solicitação. Tente novamente dentro de alguns minutos. Se o serviço continuar sem funcionar, contate o administrador do sistema.",
  },
};
export { global };

function setAmbiente(tipoAmbiente) {
  let url = "";

  // Dev
  // Local
  // Homolog
  // PreProd
  // Prod
  switch (tipoAmbiente) {
    case "Dev":
      url = `${urlDev}${caminhoDev}`;
      break;
    case "Local":
      url = `http://localhost/pettree_api${caminhoDev}`;
      break;
    case "Homolog":
      url = "https://pettreeapi.pv.homolog.prolins.com.br/v1/public";
      break;
    case "PreProd":
      url = "https://pettreeapi.pv.tst.prolins.com.br/v1/public";
      break;
    case "Prod":
      url = "https://api.anclivepa-pv.pettree.com.br/v1/public";
      break;
    default:
      url = "https://pettreeapi.pv.homolog.prolins.com.br/v1/public";
      break;
  }
  let ambiente = {
    baseURL: url,
  };
  return ambiente;
}
