import { FormatarData, FormatoMoeda, LogoHeader } from "./utils_docs";

export function RelatorioCaixa(resumo, detalhes, unidade) {
  let content = [];

  let keysArr = getArrKey(resumo);

  content.push(getHeader(unidade));
  // content.push({
  //   text: `Relatório de Caixa`,
  //   style: "titleRelatorio",
  // });
  let fechamento = "-";
  if (resumo.data_fechamento != "1900-01-01") {
    fechamento = `${FormatarData(resumo.data_fechamento)} - ${
      resumo.hora_fechamento
    }`;
  }

  content.push({
    style: "tableInfo",
    layout: {
      hLineColor: "#6c6c6c",
      vLineColor: "#6c6c6c",
    },
    table: {
      widths: "*",
      headerRows: 1,

      body: [
        [
          {
            text: "Usuário",
            style: "tInfo",
            border: [false, false, true, false],
          },
          {
            text: "Caixa",
            style: "tInfo",
            border: [false, false, true, false],
          },
          {
            text: "Unidade",
            style: "tInfo",
            border: [false, false, true, false],
          },
          {
            text: "Abertura",
            style: "tInfo",
            border: [false, false, true, false],
          },
          {
            text: "Fechamento",
            style: "tInfo",
            border: [false, false, false, false],
          },
        ],
        [
          {
            text: resumo.usuario,
            style: "cInfo",
            border: [false, false, true, false],
          },
          {
            text: resumo.id_caixa,
            style: "cInfo",
            border: [false, false, true, false],
          },
          {
            text: resumo.unidade,
            style: "cInfo",
            border: [false, false, true, false],
          },
          {
            text: `${FormatarData(resumo.data_abertura)} - ${
              resumo.hora_abertura
            }`,
            style: "cInfo",
            border: [false, false, true, false],
          },
          {
            text: fechamento,
            style: "cInfo",
            border: [false, false, false, false],
          },
        ],
      ],
    },
  });
  content.push({
    margin: [0, 0, 0, 20],
    layout: {
      hLineColor: "#6c6c6c",
      vLineColor: "#6c6c6c",
    },
    table: {
      widths: "*",
      headerRows: 1,
      body: [
        [
          {
            text: "Saldo Abertura",
            style: "tInfo",
            border: [false, false, true, false],
          },
          {
            text: "Total Entradas",
            style: "tInfo",
            border: [false, false, true, false],
          },
          {
            text: "Total Sangrias",
            style: "tInfo",
            border: [false, false, true, false],
          },
          {
            text: "Total Recebimentos",
            style: "tInfo",
            border: [false, false, true, false],
          },
          {
            text: "Total Estornos",
            style: "tInfo",
            border: [false, false, true, false],
          },
          {
            text: "Saldo Dinheiro",
            style: "tInfo",
            border: [false, false, false, false],
          },
        ],
        [
          {
            text: `${FormatoMoeda(resumo.saldo_abertura)}`,
            style: "cInfo",
            border: [false, false, true, false],
          },
          {
            text: `${FormatoMoeda(resumo.entradas)}`,
            style: "cInfo",
            border: [false, false, true, false],
          },
          {
            text: `${FormatoMoeda(resumo.sangrias)}`,
            style: "cInfo",
            border: [false, false, true, false],
          },
          {
            text: `${FormatoMoeda(resumo.recebimentos)}`,
            style: "cInfo",
            border: [false, false, true, false],
          },
          {
            text: `${FormatoMoeda(resumo.estornos_total)}`,
            style: "cInfo",
            border: [false, false, true, false],
          },
          {
            text: `${FormatoMoeda(resumo.saldo)}`,
            style: "cInfo",
            border: [false, false, false, false],
          },
        ],
      ],
    },
  });

  for (let indexKey = 0; indexKey < keysArr.length; indexKey++) {
    const elementKey = keysArr[indexKey];
    content.push({
      style: "tableHeader",
      layout: {
        hLineColor: "#6c6c6c",
        vLineColor: "#6c6c6c",
      },
      table: {
        widths: "*",
        headerRows: 1,
        body: [
          [
            {
              text: elementKey.keyDesc,
              style: "header",
              border: [false, false, false, true],
            },
          ],
        ],
      },
    });
    if (detalhes[elementKey.key]) {
      if (elementKey.activeHeader) {
        let headerItens = [];
        headerItens = detalheHeader(elementKey.header);
        content.push({
          table: {
            body: headerItens,
            widths: "*",
          },
          style: "tableMargin",
          layout: {
            hLineColor: "#6c6c6c",
            vLineColor: "#6c6c6c",
          },
        });
      }
      let bodyItens = [];
      bodyItens = detalheBody(
        detalhes[elementKey.key],
        elementKey.table,
        elementKey.tableObs
      );
      content.push({
        table: {
          body: bodyItens,
          widths: "*",
        },
        style: "tableMargin",
        layout: {
          hLineColor: "#6c6c6c",
          vLineColor: "#6c6c6c",
        },
      });
    } else {
      content.push({
        text: `Sem Registros de ${elementKey.key}`,
        style: "subTitle",
      });
    }
  }

  let modelo = {
    content: content,
    styles: {
      titleRelatorio: {
        fontSize: 12,
        bold: true,
        alignment: "center",
        margin: [0, 10, 0, 15],
      },
      tableInfo: {
        margin: [0, 0, 0, 5],
      },
      tInfo: {
        fontSize: 7,
        bold: true,
      },
      cInfo: {
        fontSize: 7,
      },
      header: {
        fontSize: 14,
        bold: true,
        alignment: "center",
        margin: [0, 2, 0, 2],
      },
      tableHeader: {
        margin: [0, 5, 0, 15],
      },
      tableMargin: {
        margin: [0, 10, 0, 15],
      },
      subTitle: {
        fontSize: 10,
        alignment: "center",
        margin: [0, 0, 0, 20],
      },
      tableH: {
        fontSize: 8,
        bold: true,
        alignment: "center",
        margin: [0, 2, 0, 2],
      },
      tableC: {
        fontSize: 8,
        alignment: "center",
        margin: [0, 2, 0, 2],
      },
      tableO: {
        fontSize: 8,
        alignment: "left",
        margin: [0, 2, 0, 2],
      },
    },
    defaultStyle: {
      fontSize: 9,
    },
    footer: function(currentPage, pageCount) {
      return {
        columns: [
          { width: 20, text: "" },
          {
            width: "*",
            text: {
              text: `Caixa ${resumo.id_caixa} - ${resumo.usuario} - ${resumo.unidade}`,
              fontSize: 6,
            },
          },
          {
            width: "*",
            text: {
              text: "Página " + currentPage.toString() + " de " + pageCount,
              alignment: "right",
              fontSize: 6,
            },
          },
          { width: 20, text: "" },
        ],
        margin: [0, 10, 0, 0],
      };
    },
  };

  return modelo;
}

function detalheHeader(header) {
  let headerContent = [];
  let itemDesc = [];
  let itemValor = [];
  for (let indexDesc = 0; indexDesc < header.length; indexDesc++) {
    const elementDesc = header[indexDesc];
    itemDesc.push({
      text: elementDesc.descricao,
      style: "tableH",
      border: [false, false, false, true],
    });
    itemValor.push({
      text: elementDesc.valor,
      style: "tableC",
      border: [false, false, false, false],
    });
  }
  headerContent.push(itemDesc);
  headerContent.push(itemValor);
  return headerContent;
}

function detalheBody(detalhesKey, tableKey, tableObs) {
  let content = [];
  let header = [];
  let body = [];
  for (let index = 0; index < tableKey.length; index++) {
    const elementHeader = tableKey[index];
    header.push({
      text: elementHeader.descricao,
      style: "tableH",
      border: [false, false, false, true],
    });
  }
  content.push(header);
  for (let index = 0; index < detalhesKey.length; index++) {
    const elementBody = detalhesKey[index];
    body = [];
    for (let indexKey = 0; indexKey < tableKey.length; indexKey++) {
      const key = tableKey[indexKey].valor;
      const tipo = tableKey[indexKey].tipo;
      let valor = "";
      if (tipo == "date") {
        valor = FormatarData(elementBody[key]);
      } else if (tipo == "number") {
        valor = FormatoMoeda(elementBody[key]);
      } else {
        valor = elementBody[key];
      }
      body.push({
        text: valor,
        style: "tableC",
        border: [false, false, false, false],
        fillColor: index % 2 === 0 ? "lightgray" : null,
      });
    }
    content.push(body);
    if (tableObs) {
      body = [];
      const length = tableKey.length;
      body.push({
        text: `Observação: ${elementBody.observacao}`,
        colSpan: length,
        style: "tableO",
        border: [false, false, false, false],
        fillColor: index % 2 === 0 ? "lightgray" : null,
      });
      for (let count = 0; count < -length; count++) {
        body.push({});
      }
      content.push(body);
    }
  }
  return content;
}

function getHeader(unidade) {
  return {
    style: "titleRelatorio",
    table: {
      widths: "*",
      body: [
        [
          {
            stack: [
              {
                image: LogoHeader(false),
                width: 50,
              },
            ],
            alignment: "left",
            border: [false, false, false, false],
          },
          {
            stack: [
              {
                text: `Relatório de Caixa`,
              },
            ],
            border: [false, false, false, false],
          },
          {
            stack: [
              {
                width: "*",
                text: [
                  {
                    text: `Unidade: ${unidade.nome_fantasia}\n`,
                    fontSize: 8,
                    alignment: "right",
                  },
                  {
                    text: `${unidade.logradouro}, ${unidade.numero} - ${unidade.cidade}\n`,
                    fontSize: 8,
                    alignment: "right",
                  },
                  {
                    text: `CEP: ${unidade.cep}\n`,
                    fontSize: 8,
                    alignment: "right",
                  },
                  {
                    text: `Tel: ${unidade.telefone}\n`,
                    fontSize: 8,
                    alignment: "right",
                  },
                  {
                    text: `Cnpj: ${unidade.cnpj}\n`,
                    fontSize: 8,
                    alignment: "right",
                  },
                ],
              },
            ],
            border: [false, false, false, false],
          },
        ],
      ],
    },
  };
}

function getArrKey(resumo) {
  const arr = [
    {
      key: "recebimentos",
      keyDesc: "Recebimentos",
      activeHeader: true,
      header: [
        {
          descricao: "Dinheiro",
          valor: FormatoMoeda(resumo.recebimentos_dinheiro),
        },
        {
          descricao: "Crédito Cliente ",
          valor: FormatoMoeda(resumo.recebimento_credito_cliente),
        },
        {
          descricao: "Cartão Crédito",
          valor: FormatoMoeda(resumo.recebimento_credito),
        },
        {
          descricao: "Cartão Debito ",
          valor: FormatoMoeda(resumo.recebimento_debito),
        },
        { descricao: "Pix", valor: FormatoMoeda(resumo.recebimento_pix) },
      ],
      tableObs: false,
      table: [
        {
          valor: "forma_pagamento",
          descricao: "Forma Pagamento",
          tipo: "string",
        },
        { valor: "maquineta", descricao: "Maquineta", tipo: "string" },
        { valor: "codauth", descricao: "COd. Aut.", tipo: "string" },
        { valor: "valor", descricao: "Valor", tipo: "number" },
        { valor: "data_movimento", descricao: "Data", tipo: "date" },
        { valor: "hora_movimento", descricao: "Hora", tipo: "string" },
      ],
    },
    {
      key: "entradas",
      keyDesc: "Entradas",
      activeHeader: false,
      header: [],
      tableObs: true,
      table: [
        { valor: "valor", descricao: "Valor", tipo: "number" },
        { valor: "data_movimento", descricao: "Data", tipo: "date" },
        { valor: "hora_movimento", descricao: "Hora", tipo: "string" },
        // { valor: "observacao", descricao: "Observação" },
      ],
    },
    {
      key: "sangrias",
      keyDesc: "Sangrias",
      activeHeader: false,
      header: [],
      tableObs: true,
      table: [
        { valor: "valor", descricao: "Valor", tipo: "number" },
        { valor: "data_movimento", descricao: "Data", tipo: "date" },
        { valor: "hora_movimento", descricao: "Hora", tipo: "string" },
        // { valor: "observacao", descricao: "Observação" },
      ],
    },
    {
      key: "estornos",
      keyDesc: "Estornos",
      activeHeader: true,
      header: [
        {
          descricao: "Dinheiro",
          valor: FormatoMoeda(resumo.estornos_dinheiro),
        },
        {
          descricao: "Crédito Cliente ",
          valor: FormatoMoeda(resumo.estornos_credito_cliente),
        },
        {
          descricao: "Cartão Crédito",
          valor: FormatoMoeda(resumo.estornos_cartao_credito),
        },
        {
          descricao: "Cartão Débito ",
          valor: FormatoMoeda(resumo.estornos_cartao_debito),
        },
        { descricao: "Pix", valor: FormatoMoeda(resumo.estornos_pix) },
      ],
      tableObs: false,
      table: [
        { valor: "descricao", descricao: "Descrição", tipo: "string" },
        { valor: "valor", descricao: "Valor", tipo: "number" },
        { valor: "data_movimento", descricao: "Data", tipo: "date" },
        { valor: "hora_movimento", descricao: "Hora", tipo: "string" },
      ],
    },
  ];
  return arr;
}
