<template>
  <div>
    <div class="p-lg-3">
      <div class="row" justify-content-between>
        <h3 class="mt-0 mb-2 font-weight-bold text-left">
          Servidores Chamefacil
        </h3>
      </div>
    </div>

    <v-data-table
      v-on:update:sort-by="options.ordenacao = true"
      :footer-props="{ 'items-per-page-options': [5, 10, 15, 20] }"
      :search="search"
      :headers="headers"
      :items="servidores"
      :options.sync="options"
      :server-items-length="totalList"
      :loading="loading"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <!-- DIALOG CADASTRO -->
          <v-dialog v-model="dialogCadastrar" max-width="500px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-if="allowCadastrarBtn"
                dark
                class="m-2 bg-verde"
                rounded
                v-bind="attrs"
                v-on="on"
              >
                Novo Servidor
              </v-btn>
            </template>
            <ModalCadastro
              v-if="dialogCadastrar"
              @close="dialogCadastrar = false"
              @response="atualizarServidores"
            />
          </v-dialog>
        </v-toolbar>

        <!-- DIALOG EDIÇÃO -->
        <v-dialog v-if="dialogEdicao" v-model="dialogEdicao" max-width="500px">
          <ModalEdicao
            v-if="dialogEdicao"
            :servidorEdicao="servidorEdicao"
            @close="dialogEdicao = false"
            @response="atualizarServidores"
          />
        </v-dialog>
      </template>

      <template v-slot:no-data>
        <p class="text-center">Nenhum registro encontrado</p>
        <v-btn class="bg-verde my-3" rounded dark @click="atualizarServidores">
          Atualizar
        </v-btn>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon
          v-if="allowCadastrarBtn"
          small
          class="mr-2"
          @click="editItem(item)"
        >
          mdi-pencil
        </v-icon>
        <!-- <v-icon v-if="allowCadastrarBtn" small @click="deleteItem(item)">
          mdi-delete
        </v-icon> -->
      </template>
    </v-data-table>
  </div>
</template>

<script>
import ModalCadastro from "../components/servidores_chamefacil/modal_cadastro.vue";
import ModalEdicao from "../components/servidores_chamefacil/modal_edicao.vue";
import ServidoresChamefacilService from "@/services/servidores_chamefacil_service.js";

export default {
  components: { ModalCadastro, ModalEdicao },
  data() {
    return {
      servidores: [],
      loading: false,
      headers: [
        {
          text: "ID",
          align: "start",
          sortable: true,
          value: "id",
        },
        {
          text: "Host",
          align: "start",
          sortable: true,
          value: "host",
        },
        {
          text: "Api",
          align: "start",
          value: "api",
          sortable: false,
        },
        {
          text: "Status",
          align: "center",
          value: "status",
          sortable: false,
        },
        { text: "Ações", align: "end", value: "actions", sortable: false },
      ],
      search: "",
      options: {
        page: 1,
        pageCount: 0,
        itemsPerPage: 15,
        ordenacao: false,
        desc: false,
      },
      totalList: 10,
      dialogCadastrar: false,
      dialogEdicao: false,
      servidorEdicao: {},
      services: {
        servidoresChamefacilService: ServidoresChamefacilService.build(),
      },
    };
  },

  computed: {
    allowCadastrarBtn() {
      const perfil = JSON.parse(sessionStorage.getItem("vuex")).perfil.id;
      return perfil == 1;
    },
  },

  watch: {
    options: {
      handler() {
        this.options.desc = this.options.sortDesc[0] ?? false;
        this.atualizarServidores();
      },
      deep: true,
    },
  },

  mounted() {
    this.atualizarServidores();
  },

  methods: {
    atualizarServidores() {
      this.dialogCadastrar = false;
      this.dialogEdicao = false;

      const status = {
        1: "Ativo",
        2: "Inativo",
        3: "Suspenso",
      };

      this.services.servidoresChamefacilService
        .getServidoresChamefacil({
          page: this.options.page,
          per_page: this.options.itemsPerPage,
        })
        .then((resp) => resp.json())
        .then((resp) => {
          this.servidores = resp.data.dados.map((serv) => {
            return {
              ...serv,
              status: status[serv.status],
            };
          });

          this.totalList = resp.data.paginacao.num_rows;
        });
    },
    editItem(item) {
      this.servidorEdicao = item;
      this.dialogEdicao = true;
    },
  },
};
</script>

<style></style>
