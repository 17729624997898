import HTTPService from "@/services/http_service.js";

export default class RelatorioAtendimentosService extends HTTPService {
  static build() {
    return new RelatorioAtendimentosService();
  }

  async relatorioTutorPorUnidadePdf(dataInicial, dataFinal, unidadeId) {
    return await this.get(
      `relatorio/estatistico/cadastro-tutor/pdf/${dataInicial}/${dataFinal}/${unidadeId}`
    ).then((resp) => {
      if (resp.status != 200) return resp;
      return resp.blob();
    });
  }
  async relatorioTutorPorUnidadeExcel(
    dataInicial,
    dataFinal,
    unidadeId
  ) {
    return await this.get(
      `relatorio/estatistico/cadastro-tutor/excel/${dataInicial}/${dataFinal}/${unidadeId}`
    ).then((resp) => {
      if (resp.status != 200) return resp;
      return resp.blob();
    });
  }
}
