<template>
  <v-card>
    <span v-if="renderLoading">
      <v-card-title>
        <span class="headline">Edição de raca</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-progress-circular size="50" indeterminate color="primary" />
        </v-container>
      </v-card-text>
    </span>
    <span v-else>
      <v-card-title>
        <span class="headline">Edição de raca</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-text-field v-model="nome" label="Raça" />
            </v-col>
            <v-col cols="12">
              <v-select
                v-model="especieId"
                :items="especieSelectList"
                label="Espécie"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="$emit('close')">
          Cancelar
        </v-btn>
        <v-btn
          class="btn-app-primary"
          style="color: #fff !important;"
          :loading="loading"
          text
          @click="salvarEdicao"
          :disabled="!allowEdition"
        >
          Salvar
        </v-btn>
      </v-card-actions>
    </span>
  </v-card>
</template>

<script>
import Mixin from "@/mixins/vuex_mixin.js";
import RacaService from "../../services/raca_service";
import EspecieService from "../../services/especie_service";

export default {
  mixins: [Mixin],
  data: () => ({
    nome: "",
    especieId: 0,
    singleSelect: false,
    loading: false,
    renderLoading: false,
    racaService: RacaService.build(),
    especieService: EspecieService.build(),
    especies: [],
    options: {
      page: 1,
      pageCount: 0,
      itemsPerPage: 15,
      ordenacao: false,
      desc: false,
    },
  }),
  props: {
    racaId: { type: Number, required: true },
  },
  watch: {
    especieId() {
      console.log(this.especieId);
    },
  },
  async mounted() {
    console.log("mounted");
    this.renderLoading = true;
    // const [especies, raca] = await Promise.all([
    //   this.especieService.getEspecieList(),
    //   this.racaService.getDetalhesByRacaId(this.racaId)
    // ]);
    // console.log({especies,raca})
    const raca = await this.racaService.getDetalhesByRacaId(this.racaId);
    this.nome = raca.nome;
    this.especieId = raca.especieId;
    this.especies = await this.especieService
      .getEspeciesPaginatedList({
        page: this.options.page,
        per_page: this.options.itemsPerPage,
        search: this.search,
        sort: this.options.ordenacao,
        desc: this.options.desc,
      })
      .then((resp) => {
        return resp.json();
      })
      .then((resp) => {
        return resp.data.especies;
      });

    this.renderLoading = false;
    if (!raca.racaId) {
      console.log(`Raca ${this.racaId} not found.`);
      this.$emit("close");
    }
  },
  methods: {
    async salvarEdicao() {
      this.loading = true;
      const success = await this.racaService.editarRaca(this.racaId, {
        nome: this.nome,
        especieId: this.especieId,
      });
      this.loading = false;

      if (success) {
        this.$emit("response", this.raca);
      } else {
        this.$_ACTIONS_showSnackbarMessage({
          message: this.$global.messages.internalServerError,
          color: "error",
        });
      }
    },
  },
  computed: {
    allowEdition() {
      return true;
    },
    especieSelectList() {
      return this.especies.map((e) => ({
        text: e.nome,
        value: e.id,
        disabled: false,
        divider: false,
        header: "",
      }));
    },
  },
};
</script>
