import HTTPService from "@/services/http_service.js";

export default class AgendamentoSalaService extends HTTPService {
  resource = "agendamento-sala";

  static build() {
    return new AgendamentoSalaService();
  }

  async getSalaAgendamento(idRequisicaoProcedimento) {
    return await this.get(`${this.resource}/${idRequisicaoProcedimento}`);
  }
}
