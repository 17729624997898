import HttpService from "./http_service";

export default class ServidoresChamefacilService extends HttpService {
  resource = (endpoint) => {
    return "chamefacil/server/" + endpoint;
  };

  static build() {
    return new ServidoresChamefacilService();
  }

  registerServidorChamefacil(responseFunctions, servidor) {
    this.request(responseFunctions, "POST", this.resource("new"), servidor);
  }

  editServidorChamefacil(responseFunctions, servidor) {
    console.log(servidor);
    this.jsonRequest(responseFunctions, "PUT", this.resource("edit"), servidor);
  }

  getServidoresChamefacil(pagination) {
    return this.get(this.resource("list"), pagination);
  }
}
