import HTTPService from "@/services/http_service.js";

export default class AuditoriaService extends HTTPService {
  static build() {
    return new AuditoriaService();
  }
  async getConsultasbyComandaId(comandaId) {
    return await this.get(`audit/comanda/prontuario/${comandaId}`);
  }
  async getAssistentesbyRequisicaoId(requisicaoId) {
    return await this.get(`audit/comanda/assistentes-disp/${requisicaoId}`);
  }

  async auditoriaExecutarRequisicao(detalhesExecucao) {
    return this.post("audit/comanda/exec-exame", detalhesExecucao);
  }

  async auditoriaExcluirItemComanda(responseFunctions, requisicaoId) {
    // return this.post("audit/comanda/excluir-exame", {
    //   requisicao_id: requisicaoId,
    // });
    this.jsonRequest(responseFunctions, "POST", "audit/comanda/excluir-exame", {
      requisicao_id: requisicaoId,
    });
  }

  auditoriaIncluirExameComanda(responseFunctions, itemComanda) {
    return this.request(
      responseFunctions,
      "POST",
      "audit/comanda/new-req-exame",
      itemComanda
    );
  }
}
