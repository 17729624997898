<template>
  <v-card>
    <v-toolbar class="bg-verde mb-5">
      <h3 class="text-white">
        Novo Orçamento
      </h3>
      <v-spacer></v-spacer>
      <v-btn icon @click="$emit('close')" dark>
        <v-icon>mdi-close-thick</v-icon>
      </v-btn>
    </v-toolbar>

    <v-card-text class="py-5">
      <div class="d-flex" style="gap: 1rem">
        <v-text-field
          outlined
          disabled
          v-if="isEdit"
          label="Cliente"
          v-model="orcamentoDetalhes.cliente_nome"
        ></v-text-field>
        <v-autocomplete
          v-else
          outlined
          label="Cliente"
          :loading="loadingSearch"
          v-model="tutorSelected"
          :items="tutoresList"
          item-text="nome"
          item-value="id_cliente"
          :search-input.sync="searchTutor"
          @keydown.enter="searchTutorbyName(searchTutor)"
          hide-no-data
          hide-selected
        ></v-autocomplete>
        <v-text-field
          outlined
          disabled
          v-if="isEdit"
          label="Pet"
          v-model="orcamentoDetalhes.pet_nome"
        ></v-text-field>
        <v-autocomplete
          v-else
          outlined
          label="Pet"
          :loading="loadingSearch"
          :items="petsList"
          v-model="petSelected"
          item-text="nome_pet"
          return-object
        ></v-autocomplete>
      </div>
      <v-autocomplete
        outlined
        label="Procedimento"
        multiple
        :items="procedimentos"
        item-text="procedimento"
        return-object
        v-model="procedimentosSelected"
        ref="inputProcedimentoOrcamento"
      >
        <template v-slot:selection="{ item }">
          {{
            item == procedimentosSelected[procedimentosSelected.length - 1]
              ? item.procedimento
              : ""
          }}
        </template>
        <template v-slot:item="{ item }">
          <v-list-item-content>
            <v-list-item-title
              v-html="item.procedimento + ' - ' + item.tipo_procedimento"
            />
            <v-list-item-subtitle
              class="mt-1"
              v-html="item.tipo_convenio + ' - ' + formatoMoeda(item.valor)"
            />
          </v-list-item-content>
        </template>
      </v-autocomplete>
      <v-data-table
        :items-per-page="5"
        :headers="headersProcedimentos"
        :items="procedimentosSelected"
        class="elevation-1 mb-5 border"
      >
        <template v-slot:item.quantidade="{ item }">
          <div class="d-flex align-center w-75" style="gap: 1rem">
            <b class="border p-1 px-5">{{ item.qtdProcedimento }}</b>
            <div class="d-flex flex-column align-center">
              <v-icon size="20" @click="item.qtdProcedimento++">
                mdi-plus
              </v-icon>
              <v-icon
                color="red"
                size="20"
                @click="item.qtdProcedimento > 1 && item.qtdProcedimento--"
              >
                mdi-minus
              </v-icon>
            </div>
          </div>
        </template>

        <template v-slot:item.valor="{ item }">
          {{ formatoMoeda(item.valor * item.qtdProcedimento) }}
        </template>

        <template v-slot:item.actions="{ item }">
          <v-icon color="error" title="Excluir" @click="deleteItem(item)">
            mdi-delete-outline
          </v-icon>
        </template>
      </v-data-table>
      <div class="d-flex justify-content-between mt-5" style="gap: 1rem">
        <div class="d-flex col-6 p-0" style="gap: 1rem">
          <v-text-field
            outlined
            class="col-4"
            inputmode="numeric"
            label="Validade"
            suffix="dias"
            v-model="validadeOrcamento"
          ></v-text-field>
          <v-text-field
            outlined
            type="text"
            readonly
            label="Valor Total"
            :value="formatoMoeda(valorTotalOrcamento)"
          >
          </v-text-field>
        </div>
        <v-text-field
          outlined
          class="col-6"
          type="date"
          label="Data de Criação"
          disabled
          v-model="dataCriacaoOrcamento"
        ></v-text-field>
      </div>
    </v-card-text>
    <v-card-actions class="pb-5 d-flex flex-row-reverse mr-3">
      <v-btn
        class="bg-verde"
        dark
        @click="salvarOrcamento"
        :disabled="loadingOrcamento"
        >Salvar</v-btn
      >
      <v-btn
        color="error"
        class="mr-3"
        text
        dark
        @click="$emit('close')"
        :disabled="loadingOrcamento"
        >Cancelar</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import PacienteService from "../../services/paciente_service";
import PetsService from "../../services/pets_service";
import OrcamentoService from "../../services/orcamento_service";
import Mixin from "@/mixins/vuex_mixin.js";
import VuexSnackbarMixin from "@/mixins/vuex_snackbar_mixin.js";

export default {
  mixins: [Mixin, VuexSnackbarMixin],
  props: {
    isEdit: Boolean,
    orcamentoDetalhes: Object,
  },
  data() {
    return {
      loadingOrcamento: false,
      procedimentos: [],
      headersProcedimentos: [
        {
          text: "Quantidade",
          align: "start",
          value: "quantidade",
        },
        { text: "Procedimento", align: "start", value: "procedimento" },
        { text: "Convenio", align: "start", value: "tipo_convenio" },
        { text: "Valor", align: "start", value: "valor" },
        { text: "", align: "end", value: "actions" },
      ],
      services: {
        pacienteService: new PacienteService(),
        petsService: PetsService.build(),
        orcamentoService: OrcamentoService.build(),
      },
      searchProcedimento: "",
      searchTutor: "",
      tutoresList: [],
      tutorSelected: {},
      petsList: [],
      petSelected: {},
      procedimentosSelected: [],
      loadingSearch: false,
      validadeOrcamento: 10,
      unidadeId: {},
      dataCriacaoOrcamento: "",
    };
  },
  watch: {
    // searchProcedimento(nome) {
    //   if (!nome) {
    //     return;
    //   }
    //   if (nome.length > 2) {
    //     // console.log(nome);
    //     this.searchProcedimentoByName(nome);
    //   }
    // },
    searchTutor(nome) {
      if (!nome) {
        return;
      }
      if (nome.length > 2) {
        this.searchTutorbyName(nome);
      }
    },
    tutorSelected() {
      this.searchPetByClienteId(this.tutorSelected);
    },
  },
  mounted() {
    this.unidadeId = JSON.parse(sessionStorage.getItem("vuex")).unidade.id;
    this.getProcedimentos();
    if (this.isEdit) {
      this.carregarDadosOrcamento();
    } else {
      this.dataCriacaoOrcamento = new Date()
        .toLocaleDateString()
        .split("/")
        .reverse()
        .join("-");
    }
  },
  computed: {
    valorTotalOrcamento() {
      if (this.procedimentosSelected.length) {
        return this.procedimentosSelected
          .map((proced) => parseFloat(proced.valor) * proced.qtdProcedimento)
          .reduce((valorAnterior, valorAtual) => valorAnterior + valorAtual);
      } else {
        return 0;
      }
    },
  },
  methods: {
    // searchProcedimentoByName(nome) {
    //   this.procedimentos = this.mock
    //     .filter((proced) => {
    //       console.log(proced.nome.toLowerCase().includes(nome.toLowerCase()));
    //       return proced.nome.includes(nome);
    //     })
    //     .map((proced) => {
    //       return {
    //         ...proced,
    //         qtdProcedimento: 1,
    //       };
    //     });
    // },
    deleteItem(item) {
      this.procedimentosSelected = this.procedimentosSelected.filter(
        (proced) => proced != item
      );
    },
    salvarOrcamento() {
      if (
        !this.petSelected.id_pet ||
        !this.procedimentosSelected.length ||
        this.validadeOrcamento < 1
      ) {
        this.$_ACTIONS_showSnackbarMessage({
          message: "Preencha os campos corretamente",
          color: "error",
        });
        return;
      }

      if (this.isEdit) {
        this.editarOrcamento();
        return;
      }

      this.loadingOrcamento = true;

      const orcamento = {
        id_cliente: this.tutorSelected,
        id_pet: this.petSelected.id_pet,
        id_unidade: this.unidadeId,
        valor: this.valorTotalOrcamento.toString(),
        itens: this.procedimentosSelected.map((proced) => {
          return {
            id_procedimento_valor: proced.id_procedimento_valor,
            valor: proced.valor,
            quantidade: proced.qtdProcedimento,
          };
        }),
        validade: this.validadeOrcamento,
      };

      const responseFunction = {
        onSucess: (status) => (resp) => {
          if (status == 200) {
            this.$_ACTIONS_showSnackbarMessage({
              message: resp.message,
              color: "sucess",
            });
            this.$emit("close");
          } else {
            this.$_ACTIONS_showSnackbarMessage({
              message: resp.message,
              color: "error",
            });
          }
        },
        onError: () => {
          this.$_ACTIONS_showSnackbarMessage({
            message: this.$global.messages.internalServerError,
            color: "error",
          });
        },
        onEnd: () => {
          this.loadingOrcamento = false;
        },
      };

      this.services.orcamentoService.cadastrarOrcamento(
        orcamento,
        responseFunction
      );
    },
    editarOrcamento() {
      this.loadingOrcamento = true;

      const orcamento = {
        id_cliente: this.tutorSelected,
        id_pet: this.petSelected.id_pet,
        id_unidade: this.unidadeId,
        valor: this.valorTotalOrcamento.toString(),
        itens: this.procedimentosSelected.map((proced) => {
          return {
            id_procedimento_valor: proced.id_procedimento_valor,
            valor: proced.valor,
            quantidade: proced.qtdProcedimento,
          };
        }),
        validade: this.validadeOrcamento,
        id_orcamento: this.orcamentoDetalhes.id,
      };

      const responseFunction = {
        onSucess: (status) => (resp) => {
          if (status == 200) {
            this.$_ACTIONS_showSnackbarMessage({
              message: resp.message,
              color: "sucess",
            });
            this.$emit("close");
          } else {
            this.$_ACTIONS_showSnackbarMessage({
              message: resp.message,
              color: "error",
            });
          }
        },
        onError: () => {
          this.$_ACTIONS_showSnackbarMessage({
            message: this.$global.messages.internalServerError,
            color: "error",
          });
        },
        onEnd: () => {
          this.loadingOrcamento = false;
          this.$emit("updateOrcamentos");
        },
      };

      this.services.orcamentoService.editarOrcamento(
        orcamento,
        responseFunction
      );
    },
    searchTutorbyName(nome) {
      if (
        !nome ||
        !(nome = nome.trim()) ||
        nome.length < 3 ||
        this.loadingSearch
      ) {
        return;
      }
      this.loadingSearch = true;
      this.tutoresList = [];
      this.services.pacienteService.getPacienteByName(
        {
          onSucess: (status) => (body) => {
            if (status === 200) {
              this.tutoresList = body.data.pacientes;
            } else {
              this.tutoresList = [];
            }
          },
          onError: (error) => {
            console.error(error);
            this.tutoresList = [];
          },
          onEnd: () => {
            this.loadingSearch = false;
          },
        },
        nome
      );
    },
    async searchPetByClienteId(id) {
      await this.services.petsService.getPetByTutorId(id).then((resp) => {
        this.petsList = resp;
      });
    },
    getProcedimentos() {
      this.services.orcamentoService
        .getProcedimentosValor(this.unidadeId)
        .then((resp) => {
          if (resp.status == 200) {
            return resp.json();
          }
        })
        .then((resp) => {
          this.procedimentos = resp.data.map((resp) => {
            return {
              ...resp,
              id_procedimento_valor: resp.id_procediemto_valor,
              qtdProcedimento: 1,
            };
          });
        });
    },
    formatoMoeda(valor) {
      return new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      }).format(valor);
    },
    carregarDadosOrcamento() {
      this.validadeOrcamento = Number(this.orcamentoDetalhes.validade);
      this.procedimentosSelected = this.orcamentoDetalhes.itens;
      this.dataCriacaoOrcamento = this.orcamentoDetalhes.data_criacao;
      this.tutorSelected = this.orcamentoDetalhes.id_cliente;
      this.petSelected = { id_pet: this.orcamentoDetalhes.id_pet };
    },
  },
};
</script>
