<template>
  <div>
    <div class="p-lg-3">
      <div class="row" justify-content-between>
        <h3
          class="mt-0 mb-2 font-weight-bold text-left"
          style="letter-spacing: 0.09rem;"
        >
          Relatório - Procedimentos Executados
        </h3>
      </div>
    </div>

    <div class="container">
      <v-row align="center" class="my-3">
        <v-col class="d-flex col-4">
          <v-select
            :items="unidades"
            label="Unidade"
            item-value="id"
            v-model="unidadeSelected"
          ></v-select>
        </v-col>
        <v-col class="d-flex col-4">
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="datas"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="computedFormatDate"
                label="Data inicial - Data final"
                prepend-icon="mdi-calendar"
                clearable
                v-bind="attrs"
                v-on="on"
                @click:clear="datas = null"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="datas"
              no-title
              range
              scrollable
              color="green darken-4"
            >
              <v-spacer></v-spacer>
              <v-btn text @click="menu = false"> Cancelar </v-btn>
              <v-btn dark color="success" @click="$refs.menu.save(datas)">
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col class="d-flex" style="margin-bottom: 9px;">
          <!-- <v-btn dark color="indigo" class="btnWid" @click="gerarRelatorio"> -->
          <!-- </v-btn> -->
          <button
            :disabled="verificarForm()"
            @click.prevent="gerarRelatorio"
            class="btn btn-app-primary btn-rounded "
          >
            Gerar Relatório
          </button>
        </v-col>
      </v-row>
      <!-- <div class="row">
        <div class="col-9">
          <v-row align="center" class="my-3">
            <v-col class="d-flex col-4">
              <v-select
                :items="unidades"
                label="Unidade"
                item-value="id"
                v-model="unidadeSelected"
              ></v-select>
            </v-col>
            <v-col class="d-flex col-4">
              <v-menu
                ref="menu"
                v-model="menu"
                :close-on-content-click="false"
                :return-value.sync="datas"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="computedFormatDate"
                    label="Data inicial - Data final"
                    prepend-icon="mdi-calendar"
                    clearable
                    v-bind="attrs"
                    v-on="on"
                    @click:clear="datas = null"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="datas"
                  no-title
                  range
                  scrollable
                  color="green darken-4"
                >
                  <v-spacer></v-spacer>
                  <v-btn text @click="menu = false"> Cancelar </v-btn>
                  <v-btn dark color="success" @click="$refs.menu.save(datas)">
                    OK
                  </v-btn>
                </v-date-picker>
              </v-menu>
            </v-col>
          </v-row>
        </div>
        <div class="col-3">
          <v-row align="center" class="my-3">
            <v-col class="d-flex">
              <button
                :disabled="verificarForm()"
                @click.prevent="gerarRelatorio"
                class="btn btn-app-primary btn-rounded "
              >
                Gerar Relatório
              </button>
            </v-col>
          </v-row>
        </div>
      </div> -->
    </div>
    <div class="container" v-if="loadingRelatorio">
      <div class="row justify-content-center">
        <div class="col-10">
          <div style="display: inline-block;">
            <h3 class="mb-3">
              Aguarde seu relatório está sendo processado...
            </h3>
            <v-progress-linear
              indeterminate
              height="6"
              rounded
              color="#1daf80"
            ></v-progress-linear>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import RelatorioService from "../services/relatorio_service";
import UnidadeService from "../services/unidade_service";
import Mixin from "@/mixins/vuex_mixin.js";

export default {
  components: {},
  mixins: [Mixin],
  data: () => ({
    datas: null,
    menu: false,
    unidades: [],
    unidadeSelected: "",
    services: {
      unidadeService: UnidadeService.build(),
      relatorioService: RelatorioService.build(),
    },
    loadingRelatorio: false,
  }),
  watch: {},
  beforeMount() {
    this.setUnidades();
  },
  computed: {
    computedFormatDate() {
      const datasFormatadas =
        this.datas &&
        this.datas.map((item) =>
          item
            .split("-")
            .reverse()
            .join("/")
        );
      return datasFormatadas;
    },
  },
  methods: {
    setUnidades() {
      this.services.unidadeService
        .getUnidadeByUsuarioLogado()
        .then((response) => {
          return response.json();
        })
        .then((response) => {
          this.unidades = response.data.map((unidade) => {
            return { text: unidade.unidade, id: unidade.id_unidade };
          });
        });
    },
    verificarForm() {
      const dataInicial = this.datas ? this.datas[0] : "";
      const dataFinal = this.datas ? this.datas[1] : "";
      const unidade = this.unidadeSelected ? this.unidadeSelected : "";

      if (!dataInicial || !dataFinal || !unidade || this.loadingRelatorio) {
        return true;
      } else {
        return false;
      }
    },
    gerarRelatorio() {
      this.loadingRelatorio = true;

      this.datas.sort();

      const dataInicial = this.datas ? this.datas[0] : "";
      const dataFinal = this.datas ? this.datas[1] : "";
      const unidade = this.unidadeSelected ? this.unidadeSelected : "";

      if (!dataInicial || !dataFinal || !unidade) {
        this.$_ACTIONS_showSnackbarMessage({
          message: "Preencha os campos corretamente",
          color: "error",
        });
        this.loadingRelatorio = false;
        return;
      }
      this.services.relatorioService
        .relatorioProcedimentoExecutado({
          unidade: unidade,
          dataInicial: dataInicial,
          dataFinal: dataFinal,
        })
        .then(async (resp) => {
          if (resp.status) {
            this.$_ACTIONS_showSnackbarMessage({
              message: "Falha ao carregar",
              color: "error",
            });
            return;
          }
          this.$_ACTIONS_showSnackbarMessage({
            message:
              "Dados encontrados com sucesso! Gerando arquivo para Excel...",
            color: "sucess",
          });
          let link = document.createElement("a");
          // link.target = "_blank";
          link.download = `Relatório Procedimentos Executados - ${dataInicial
            .split("-")
            .reverse()
            .join("-")} - ${dataFinal
            .split("-")
            .reverse()
            .join("-")}.xlsx`;
          let url = window.URL.createObjectURL(resp);
          link.href = url;
          link.click();
        })
        .catch(() => {
          this.$_ACTIONS_showSnackbarMessage({
            message: "Falha ao carregar",
            color: "error",
          });
        })
        .finally(() => {
          this.loadingRelatorio = false;
        });
      return;
    },
  },
};
</script>
<style scoped>
.btnWid {
  width: 7rem;
}
</style>
