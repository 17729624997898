<template>
  <v-card>
    <v-toolbar style="background-color: #1daf80; color: #fff">
      <v-btn icon dark @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>
        <span class="headline"
          >Detalhamento de Caixa - {{ caixa.nome_criado_por }}</span
        >
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items> </v-toolbar-items>
    </v-toolbar>
    <div class="px-10 py-4">
      <div class="container">
        <div class="px-3 row md-0 justify-content-center">
          <div class="col-3 text-center">
            <p style="text-capitalize">
              <strong>Usuario:</strong><br />
              {{ caixa.nome_criado_por }}
            </p>
            <p style="text-capitalize">
              <strong>Unidade:</strong><br />
              {{ caixa.unidade }}
            </p>
            <p style="text-capitalize">
              <strong>Saldo informado:</strong><br />
              {{ caixa.saldo_informado_formatada }}
            </p>
          </div>
          <div class="col-3 text-center">
            <p>
              <strong>Data Abertura:</strong><br />
              {{ caixa.data_abertura_formatada }}
            </p>
            <p>
              <strong>Hora Abertura:</strong><br />
              {{ caixa.hora_abertura }}
            </p>
            <p>
              <strong>Saldo Abertura:</strong><br />
              {{ caixa.saldo_abertura_formatada }}
            </p>
          </div>
          <div class="col-3 text-center">
            <p>
              <strong>Data Fechamento:</strong><br />
              {{ caixa.data_fechado_formatada }}
            </p>
            <p>
              <strong>Hora Fechamento:</strong><br />
              {{ caixa.hora_fechado_formatada }}
            </p>
            <p>
              <strong>Saldo Fechamento:</strong><br />
              {{ caixa.saldo_fechamento_formatada }}
            </p>
          </div>
        </div>
      </div>
      <v-divider />
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12">
            <v-data-table
              :headers="headers"
              :items="detalhe"
              :items-per-page="5"
              class="elevation-1"
              single-expand
              item-key="id"
              show-expand
            >
              <template #item.data-table-expand="{ item, expand, isExpanded }">
                <td
                  v-if="
                    item.tipo_movimento_id == 4 || item.tipo_movimento_id == 3
                  "
                  class="text-start"
                >
                  <v-btn
                    icon
                    @click="expand(!isExpanded)"
                    class="v-data-table__expand-icon"
                    :class="{ 'v-data-table__expand-icon--active': isExpanded }"
                  >
                    <v-icon>mdi-chevron-down</v-icon>
                  </v-btn>
                </td>
              </template>
              <template v-slot:expanded-item="{ headers, item }">
                <td class="text-start" :colspan="headers.length">
                  <strong>Observação:</strong> {{ item.observacao }}
                </td>
              </template>
            </v-data-table>
          </div>
        </div>
      </div>
    </div>
  </v-card>
</template>

<script>
import Mixin from "@/mixins/vuex_mixin.js";
import CaixaService from "../../services/caixa_service";

export default {
  mixins: [Mixin],
  props: {
    caixa: {
      type: Object,
      required: true,
    },
    caixaId: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    detalhe: [],
    loading: false,
    services: {
      caixaService: CaixaService.build(),
    },
    headers: [
      { text: "ID", value: "id", sortable: false },
      { text: "Tipo Movimento", value: "tipo_movimento", sortable: false },
      { text: "Tipo Estorno", value: "tipo_estorno", sortable: false },
      { text: "Forma Pagamento", value: "forma_pagamento", sortable: false },
      { text: "Maquineta", value: "maquineta_formatado", sortable: false },
      { text: "Cod Autenticação", value: "codauth_formatado", sortable: false },
      { text: "Dinheiro", value: "dinheiroFormatado", sortable: false },
      { text: "Troco", value: "trocoFormatado", sortable: false },
      { text: "Valor", value: "valorFormatado", sortable: false },
      {
        text: "Data Movimento",
        value: "data_movimento_formatada",
        sortable: false,
      },
      { text: "Hora Movimento", value: "hora_movimento", sortable: false },
      { text: "", value: "data-table-expand", sortable: false },
    ],
  }),
  mounted() {
    this.getDetalhe();
  },
  watch: {},
  methods: {
    getDetalhe() {
      this.loading = true;
      this.services.caixaService
        .getCaixaById(this.caixaId)
        .then(async (resp) => {
          const response = await resp.json();
          this.detalhe = [];
          if (resp.status !== 200) return;
          for (let index = 0; index < response.data.length; index++) {
            const element = response.data[index];
            const novoElement = {
              ...element,
              valorFormatado: this.formatarSaldo(element.valor),
              dinheiroFormatado: this.formatarSaldo(element.dinheiro),
              trocoFormatado: this.formatarSaldo(element.troco),
              data_movimento_formatada: this.formatarData(
                element.data_movimento
              ),
              maquineta_formatado: this.checkFormPag(element.forma_pagamento_id)
                ? element.maquineta
                : "",
              codauth_formatado: this.checkFormPag(element.forma_pagamento_id)
                ? element.codauth
                : "",
            };
            this.detalhe.push(novoElement);
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    checkFormPag(pagId) {
      if (pagId == 1 || pagId == 2) {
        return true;
      } else {
        return false;
      }
    },
    formatarData(data) {
      if (data) {
        return data
          .split("-")
          .reverse()
          .join("/");
      } else {
        return "-";
      }
    },
    formatarSaldo(saldo) {
      if (saldo) {
        saldo = saldo.replaceAll(",", "");
        return new Intl.NumberFormat("pt-BR", {
          style: "currency",
          currency: "BRL",
        }).format(saldo);
      } else {
        return "-";
      }
    },
  },
};
</script>
