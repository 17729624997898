import HTTPService from "@/services/http_service.js";

export default class ProcedimentoService extends HTTPService {
  resource = "procedimento";

  static build() {
    return new ProcedimentoService();
  }

  cadastrar(responseFunctions, procedimento) {
    this.request(responseFunctions, "POST", this.resource, procedimento);
  }

  getProcedimentos(responseFunctions, descricao) {
    let imprevisto = descricao ?? "";
    if (imprevisto === "") {
      this.request(responseFunctions, "GET", this.resource, null);
    } else {
      this.request(responseFunctions, "GET", this.resource, null, descricao);
    }
  }

  async findAll() {
    try {
      const response = await this.get("procedimento");
      if (response.status !== 200) return [];
      const { data } = await response.json();
      return data.procedimentos.map((p) => ({
        id: p.id,
        descricao: p.descricao,
        tipoProcedimentoId: p.id_tipo_procedimento,
        tipoProcedimento: p.tipo_procedimento,
      }));
    } catch (e) {
      return [];
    }
  }

  async getProcedimentosPorUnidadeEprocedimento(
    unidadeId,
    tipo_procedimento_id
  ) {
    try {
      const response = await this.get(
        `procedimento/lista/unidade/tipo/${unidadeId}/${tipo_procedimento_id}`
      );
      if (response.status !== 200) return [];
      const { data } = await response.json();
      return data;
      // return data.procedimentos.map((p) => ({
      //   id: p.id,
      //   descricao: p.descricao,
      //   tipoProcedimentoId: p.id_tipo_procedimento,
      //   tipoProcedimento: p.tipo_procedimento,
      // }));
    } catch (e) {
      return [];
    }
  }

  async getProcedimentosPorUnidade(unidadeId) {
    try {
      const response = await this.get(`procedimento/unidade/${unidadeId}`);
      if (response.status !== 200) return [];
      const { data } = await response.json();
      return data;
      // return data.procedimentos.map((p) => ({
      //   id: p.id,
      //   descricao: p.descricao,
      //   tipoProcedimentoId: p.id_tipo_procedimento,
      //   tipoProcedimento: p.tipo_procedimento,
      // }));
    } catch (e) {
      return [];
    }
  }
  async getProcedimentosPorUnidadeRelatorio(unidadeId) {
    try {
      const response = await this.get(
        `procedimento/lista/unidade/${unidadeId}`
      );
      if (response.status !== 200) return [];
      const { data } = await response.json();
      return data;
      // return data.procedimentos.map((p) => ({
      //   id: p.id,
      //   descricao: p.descricao,
      //   tipoProcedimentoId: p.id_tipo_procedimento,
      //   tipoProcedimento: p.tipo_procedimento,
      // }));
    } catch (e) {
      return [];
    }
  }

  async getProcedimentosPorConvenioUnidade(unidadeId, tipoConvenio) {
    try {
      const response = await this.get(
        `procedimento/lista/unidade/tipoconvenio/${unidadeId}/${tipoConvenio}`
      );
      if (response.status !== 200) return [];
      const { data } = await response.json();
      return data;
    } catch (e) {
      return [];
    }
  }

  getList(responseFunctions) {
    this.request(responseFunctions, "GET", this.resource, null);
  }

  getAll(onSucess, onError, onEnd, page, per_page, filter, order, desc) {
    const responseFunctions = {
      onSucess,
      onError,
      onEnd,
    };
    let queryparams = {
      page: page,
      per_page: per_page,
    };
    if (filter === "") {
      if (order) {
        let queryparams = {
          page: page,
          per_page: per_page,
          sort: desc ? "descricao" : "-descricao",
        };
        this.request(
          responseFunctions,
          "GET",
          this.resource,
          null,
          queryparams
        );
      } else {
        this.request(
          responseFunctions,
          "GET",
          this.resource,
          null,
          queryparams
        );
      }
    } else {
      let queryparams = {
        page: page,
        per_page: per_page,
        descricao: filter,
      };
      if (order) {
        let queryparams = {
          page: page,
          per_page: per_page,
          descricao: filter,
          sort: desc ? "descricao" : "-descricao",
        };
        this.request(
          responseFunctions,
          "GET",
          this.resource,
          null,
          queryparams
        );
      } else {
        this.request(
          responseFunctions,
          "GET",
          this.resource,
          null,
          queryparams
        );
      }
    }
  }

  deleteProcedimento({ onSucess, onError, onEnd }, { id }) {
    // ESTÁ SENDO UTILIZADO POST PARA EXCLUSÃO PORQUE A UMBLER INFORMOU ERRO DE CORS AO USAR O MÉTODO DELETE. CORRIGIR.
    const uri = `${this.resource}/${id}/deletar`;
    this.request({ onSucess, onError, onEnd }, "POST", uri);
  }

  updateDescricaoProcedimento({ onSucess, onError, onEnd }, procedimento) {
    const uri = `${this.resource}/${procedimento.id}`;
    this.request({ onSucess, onError, onEnd }, "POST", uri, procedimento);
  }

  searchByDescricao(vm) {
    // Para usar esse método, o componente deve ter como atributos em data
    // "procedimentos" (array) e "loading"(boolean)
    return (descricao) => {
      if (!descricao) {
        vm.procedimentos = [];
        vm.procedimento = "";
      }
      if (
        !descricao ||
        !(descricao = descricao.trim()) ||
        descricao.length < 3 ||
        vm.loading_procedimento
      ) {
        return;
      }
      vm.loading_procedimento = true;
      vm.procedimentos = [];
      let queryParam = {
        descricao: descricao,
        tipo_procedimento: vm.detalhesAgenda.idTipoProcedimento,
      };
      //   const procedimentoService = new ProcedimentoService();
      const requestFunctions = {
        onSucess: (status) => (body) => {
          if (status === 200) {
            vm.procedimentos = body.data.procedimentos;
          } else {
            vm.procedimentos = [];
          }
        },
        onError: (error) => {
          console.error(error);
          vm.procedimentos = [];
        },
        onEnd: () => {
          vm.loading_procedimento = false;
        },
      };
      this.getProcedimentos(requestFunctions, queryParam);
    };
  }

  pdfRelatorioProcedimentos(
    dataInicial,
    dataFinal,
    unidade,
    convenio,
    execucao
  ) {
    return this.get(
      `relatorio/servicos-realizados/pdf/${dataInicial}/${dataFinal}/${unidade}/${convenio}/${execucao}`
    ).then((resp) => {
      if (resp.status != 200) return resp;
      return resp.blob();
    });
  }

  pdfProcedimentosFull(dataInicial, dataFinal, unidade, convenio, execucao) {
    return this.get(
      `relatorio/servicos-comanda-full/pdf/${dataInicial}/${dataFinal}/${unidade}/${convenio}/${execucao}`
    ).then((resp) => {
      if (resp.status != 200) return resp;
      return resp.blob();
    });
  }

  excelRelatorioProcedimentos(
    dataInicial,
    dataFinal,
    unidade,
    convenio,
    execucao
  ) {
    return this.get(
      `relatorio/servicos-realizados/excel/${dataInicial}/${dataFinal}/${unidade}/${convenio}/${execucao}`
    ).then((resp) => {
      if (resp.status != 200) return resp;
      return resp.blob();
    });
  }

  excelProcedimentosFull(dataInicial, dataFinal, unidade, convenio, execucao) {
    return this.get(
      `relatorio/servicos-comanda-full/excel/${dataInicial}/${dataFinal}/${unidade}/${convenio}/${execucao}`
    ).then((resp) => {
      if (resp.status != 200) return resp;
      return resp.blob();
    });
  }

  excelRelatorioProcedimentosQuantitativo(
    dataInicial,
    dataFinal,
    unidade,
    convenio,
    tipoProcedimento,
    procedimento
  ) {
    const requestBody = {
      dataInicial,
      dataFinal,
      unidade,
      convenio,
      tipoProcedimento,
      procedimento, // Enviando a lista de IDs como parte do corpo da solicitação
    };

    return this.post(
      "relatorio/servicos-realizados-quantitativo/excel",
      requestBody,
      {
        responseType: "blob",
      }
    ).then((resp) => {
      if (resp.status !== 200) return resp;
      return resp.blob();
    });
  }

  pdfRelatorioProcedimentosQuantitativo(
    dataInicial,
    dataFinal,
    unidade,
    convenio,
    tipoProcedimento,
    procedimento
  ) {
    const requestBody = {
      dataInicial,
      dataFinal,
      unidade,
      convenio,
      tipoProcedimento,
      procedimento, // Enviando a lista de IDs como parte do corpo da solicitação
    };

    return this.post(
      "relatorio/servicos-realizados-quantitativo/pdf",
      requestBody,
      {
        responseType: "blob",
      }
    ).then((resp) => {
      if (resp.status !== 200) return resp;
      return resp.blob();
    });
  }
}
