import HTTPService from "@/services/http_service.js";

export default class CaixaService extends HTTPService {
  resource = "caixa";
  resourceRel = "relatorio/caixa";

  static build() {
    return new CaixaService();
  }

  checkCaixaByUser(idUsuario, idUnidade) {
    return this.get(`${this.resource}/aberto/${idUsuario}/${idUnidade}`);
  }

  getCaixaById(idCaixa) {
    return this.get(`${this.resource}/detalhe/${idCaixa}`);
  }

  getCaixaResumo(idusuario, idUnidade, idCaixa) {
    return this.get(
      `${this.resource}/aberto/resumo/${idusuario}/${idUnidade}/${idCaixa}`
    );
  }

  getCaixaResumoPdf(idUnidade, idCaixa) {
    return this.get(`${this.resource}/resumo/${idUnidade}/${idCaixa}`);
  }

  getCaixaList(idUnidade, dataInicio, dataFim) {
    return this.get(
      `${this.resource}/unidade/list/${idUnidade}/${dataInicio}/${dataFim}`
    );
  }

  getCaixaPaginatedList(
    idUnidade,
    dataInicio,
    dataFim,
    status,
    { page, per_page }
  ) {
    const filter = {
      page,
      per_page,
    };
    return this.get(
      `${this.resource}/unidade/list/${idUnidade}/${dataInicio}/${dataFim}/${status}`,
      filter
    );
  }

  abrirCaixaByUser(idUsuario, idUnidade, body) {
    return this.post(`${this.resource}/abrir/${idUsuario}/${idUnidade}`, body);
  }

  fecharCaixaByUser(body) {
    return this.post(`${this.resource}/fechar`, body);
  }

  sangriaCaixa(body) {
    return this.post(`${this.resource}/sangria`, body);
  }

  entradaCaixa(body) {
    return this.post(`${this.resource}/entrada`, body);
  }

  relatorioExcel(unidadeId, dataInicio, dataFim, status) {
    return this.get(
      `${this.resourceRel}/unidade/excel/periodo/${unidadeId}/${dataInicio}/${dataFim}/${status}`
    ).then((resp) => {
      if (resp.status !== 200) return resp;
      return resp.blob();
    });
  }

  relatorioExcelbyId(id) {
    return this.get(`${this.resourceRel}/excel/${id}`).then((resp) => {
      if (resp.status !== 200) return resp;
      return resp.blob();
    });
  }

  relatorioPdfbyId(id) {
    return this.get(`${this.resourceRel}/pdf/${id}`).then((resp) => {
      if (resp.status !== 200) return resp;
      return resp.blob();
    });
  }
}
