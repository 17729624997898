<template>
  <v-card>
    <v-toolbar style="background-color: #1daf80; color: #fff">
      <v-btn icon dark @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>
        <span class="headline">Abrir Caixa</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items> </v-toolbar-items>
    </v-toolbar>
    <div class="p-5">
      <!-- <button
        @click.prevent="teste"
        class="btn btn-app-primary btn-rounded font-weight-bold"
      >
        <span> Check Caixa </span>
      </button> -->
      <div class="p-lg-3 row justify-content-center">
        <div class="col-lg-4 form-group clearfix mb-3">
          <v-text-field
            v-model="valorCaixa"
            v-money="moneyConfig"
            @keydown="verificarTecla($event)"
            type="text"
            step="0.01"
            min="0"
            label="Valor Inicial do Caixa"
            style="padding-top: 0 !important"
          ></v-text-field>
        </div>
      </div>

      <v-card>
        <div>
          <v-card-title class="mx-2"
            ><h3>Resumo abertura de caixa</h3></v-card-title
          >
          <div class="px-3 row justify-content-between">
            <div
              class="col-md-10 text-left"
              style="text-transform: capitalize;"
            >
              <p>
                <strong>Usuario:</strong>
                {{ getNomeUsuario() }}
              </p>
              <p>
                <strong>Unidade:</strong>
                {{ getUnidade() }}
              </p>
              <p>
                <strong>Saldo inicial caixa:</strong>
                {{ valorCaixa }}
              </p>
            </div>
          </div>
          <div class="row justify-content-center">
            <div class="col-10 text-right my-2">
              <button
                :disabled="valorCaixa < 0"
                @click.prevent="abrirCaixa"
                class="btn btn-app-primary btn-rounded font-weight----ld"
              >
                <span v-if="!loading"> Abrir Caixa </span>
                <v-progress-circular
                  v-else
                  indeterminate
                  color="white"
                ></v-progress-circular>
              </button>
            </div>
          </div>
        </div>
      </v-card>
    </div>
  </v-card>
</template>

<script>
import Mixin from "@/mixins/vuex_mixin.js";
import CaixaService from "../../services/caixa_service";
import { VMoney } from "v-money";

export default {
  mixins: [Mixin],
  directives: { money: VMoney },
  data: () => ({
    moneyConfig: {
      decimal: ",",
      thousands: ".",
      prefix: "R$ ",
      suffix: "",
      precision: 2,
      masked: true,
    },
    valorCaixa: 0.0,
    loading: false,
    services: {
      caixaService: CaixaService.build(),
    },
    unidadeId: ~~JSON.parse(sessionStorage.vuex).unidade.id,
    unidade: ~~JSON.parse(sessionStorage.vuex).unidade.razap_social,
    usuarioId: ~~JSON.parse(sessionStorage.vuex).usuario.id,
    usuario: ~~JSON.parse(sessionStorage.vuex).usuario.dados.nome,
  }),
  mounted() {},
  watch: {},
  methods: {
    getNomeUsuario() {
      return JSON.parse(
        sessionStorage.getItem("vuex")
      ).usuario.dados.nome.toLowerCase();
    },
    getUnidade() {
      return JSON.parse(
        sessionStorage.getItem("vuex")
      ).unidade.nome.toLowerCase();
    },
    verificarTecla(event) {
      if (
        ((event.keyCode >= 65 && event.keyCode <= 90) ||
          (event.keyCode >= 97 && event.keyCode <= 122)) &&
        ![96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 110, 188, 190].includes(
          event.keyCode
        )
      ) {
        event.preventDefault();
      }
    },
    formatarSaldo(saldo) {
      return new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      }).format(saldo);
    },
    abrirCaixa() {
      this.loading = true;
      let body = {
        saldo_inicial: this.formatValor(this.valorCaixa),
      };
      this.services.caixaService
        .abrirCaixaByUser(this.usuarioId, this.unidadeId, body)
        .then(async (resp) => {
          const response = await resp.json();
          this.loading = false;
          this.mostrarFeedback(resp.status, response);
          if (resp.status == 200) {
            this.$emit("response");
            this.$emit("close");
          }
        })
        .catch((error) => {
          this.loading = false;
          console.error(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    mostrarFeedback(status, response) {
      this.$_ACTIONS_showSnackbarMessage({
        message: status == 200 ? "Aberto com sucesso!" : response.message,
        color: status == 200 ? "sucess" : "error",
      });
    },
    resetForm() {
      this.valorCaixa = "";
    },
    getFormData() {
      return {
        valorCaixa: this.valorCaixa,
      };
      //   const formData = new FormData();
      //   formData.append("descricao", this.setorDescricao);
      //   formData.append("id_centro_custo", this.centroCustoId);
      //   return formData;
    },
    formatValor(valor) {
      if (typeof valor == "string") {
        const newValor = parseFloat(
          valor
            .replaceAll("R$", "")
            .replaceAll(".", "")
            .replaceAll(",", ".")
        ).toFixed(2);
        return newValor;
      } else {
        return valor;
      }
      // return parseFloat(valor).toFixed(2)
    },
  },
};
</script>
