<template>
  <div class="d-flex flex-wrap col-12">
    <div class="d-flex flex-colunm col-12">
     <label>{{ pergunta.texto_pergunta }}</label>
    </div>
    <div class="d-flex flex-wrap col-12">
      <v-radio-group
        >
          <v-radio
            row
            v-for="n in pergunta.opcoes"
            :key="n.id"
            @change="respondido(n)"
            :label="n.descricao"
            :value="n.descricao"
          ></v-radio>
        </v-radio-group>
    </div>
  </div>
</template>
<script>
import Mixin from "@/mixins/vuex_snackbar_mixin.js";

export default {
  mixins: [Mixin],
  props:{
    pergunta:Object,
  },
 beforeMount(){
    console.log("pergunta check")
    console.log(this.pergunta)
     this.resposta = {
      id_pergunta:this.pergunta.id,
      resposta:[this.pergunta.opcoes[0].descricao]
    }
  },
  data: () => ({
    resposta:{},
  }),
  methods:{
    respondido(opt){
      this.resposta.resposta[0] = +opt.id
      this.$emit("resposta", this.resposta)
    }
  }
};
</script>
<style scoped>
.v-progress-circular {
  margin: 1rem;
}
label{
  font-weight: 900;
  font-size: 1.2rem;
}
.v-application {
    line-height: .01;
}
</style>