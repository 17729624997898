import HTTPService from "@/services/http_service.js";

export default class OrcamentoService extends HTTPService {
  static build() {
    return new OrcamentoService();
  }

  getProcedimentosValor(unidadeId) {
    return this.get(
      `procedimentovalor/procedimentos/unidade/${unidadeId}`
    ).then((resp) => {
      return resp;
    });
  }

  cadastrarOrcamento(orcamento, responseFunctions) {
    this.jsonRequest(responseFunctions, "POST", "orcamento-cliente", orcamento);
  }

  editarOrcamento(orcamento, responseFunctions) {
    this.jsonRequest(
      responseFunctions,
      "POST",
      "orcamento-cliente/update",
      orcamento
    );
  }

  getOrcamentosByOrcamentoId(idOrcamento) {
    return this.get(`orcamento-cliente/${idOrcamento}`);
  }

  getDetalhesOrcamentoById(idOrcamento) {
    return this.get(`orcamento-cliente/detalhe/${idOrcamento}`);
  }

  getOrcamentosByClienteId(unidadeId, idCliente) {
    return this.get(`orcamento-cliente/unidade/${unidadeId}/${idCliente}`);
  }

  getOrcamentosByPetId(unidadeId, idPet) {
    return this.get(`orcamento-pet/unidade/${unidadeId}/${idPet}`);
  }

  gerarComanda(orcamento, responseFunctions) {
    this.jsonRequest(
      responseFunctions,
      "POST",
      "orcamento-cliente/torequisicao",
      orcamento
    );
  }
}
