<template>
  <v-card>
    <v-toolbar class="bg-verde mb-5">
      <h3 class="text-white">
        Gerar Comanda
      </h3>
      <v-spacer></v-spacer>
      <v-btn icon @click="$emit('close')" dark>
        <v-icon>mdi-close-thick</v-icon>
      </v-btn>
    </v-toolbar>

    <v-card-text class="py-5">
      <div class="mb-2">
        <p>
          Selecione os itens do orçamento que deseja adicionar à comanda.
        </p>
      </div>
      <v-data-table
        v-model="itensSelecionados"
        :items-per-page="5"
        :headers="headersItensOrcamento"
        :items="itensOrcamento"
        class="border"
        show-select
      >
        <template v-slot:item.valor_unidade="{ item }">
          {{ formatoMoeda(item.valor_unidade) }}
        </template>

        <template v-slot:item.valor="{ item }">
          {{ formatoMoeda(item.valor) }}
        </template>
      </v-data-table>
    </v-card-text>

    <v-card-actions class="d-flex justify-content-between ">
      <div class="ml-3">
        <h4 class="font-weight-regular">
          Total:
          <b>{{ formatoMoeda(valorTotalComanda) }}</b>
        </h4>
      </div>
      <div class="pb-5 pt-5 d-flex flex-row-reverse">
        <v-btn
          class="bg-verde"
          dark
          @click="gerarComanda"
          :disabled="loadingGerarComanda"
          >Gerar Comanda</v-btn
        >
        <v-btn
          color="error"
          class="mr-3"
          text
          dark
          @click="$emit('close')"
          :disabled="loadingGerarComanda"
          >Cancelar</v-btn
        >
      </div>
    </v-card-actions>
  </v-card>
</template>

<script>
import Mixin from "@/mixins/vuex_mixin.js";
import VuexSnackbarMixin from "@/mixins/vuex_snackbar_mixin.js";
import OrcamentoService from "../../services/orcamento_service";

export default {
  mixins: [Mixin, VuexSnackbarMixin],
  props: {
    orcamento: Object,
  },
  data() {
    return {
      itensOrcamento: [],
      headersItensOrcamento: [
        {
          text: "Procedimento",
          align: "start",
          value: "procedimento",
        },
        {
          text: "Valor unitário",
          align: "center",
          sortable: false,
          value: "valor_unidade",
        },
        {
          text: "Quantidade",
          align: "center",
          sortable: false,
          value: "quantidade",
        },
        {
          text: "Valor Total",
          align: "center",
          sortable: false,
          value: "valor",
        },
      ],
      itensSelecionados: [],
      loadingGerarComanda: false,
      services: {
        orcamentoService: OrcamentoService.build(),
      },
    };
  },
  beforeMount() {
    this.getDetalhesOrcamento();
    this.unidadeId = JSON.parse(sessionStorage.getItem("vuex")).unidade.id;
  },
  computed: {
    valorTotalComanda() {
      if (this.itensSelecionados.length) {
        return this.itensSelecionados
          .map((item) => parseFloat(item.valor_unidade) * item.quantidade)
          .reduce((valorAnterior, valorAtual) => valorAnterior + valorAtual);
      } else {
        return 0;
      }
    },
  },
  methods: {
    async getDetalhesOrcamento() {
      this.itensOrcamento = await this.services.orcamentoService
        .getDetalhesOrcamentoById(this.orcamento.id)
        .then((resp) => resp.json())
        .then((resp) => {
          console.log(resp.data);
          return resp.data.itens;
        });
    },
    gerarComanda() {
      if (!this.itensSelecionados.length) {
        this.$_ACTIONS_showSnackbarMessage({
          message: "Selecione os itens que deseja adicionar à comanda",
          color: "error",
        });
        return;
      }
      this.loadingGerarComanda = true;

      const orcamento = {
        id_cliente: this.orcamento.id_cliente,
        id_pet: this.orcamento.id_pet,
        id_unidade: this.unidadeId,
        valor: this.valorTotalComanda.toString(),
        itens: this.itensSelecionados.map((proced) => {
          return {
            id: proced.id,
          };
        }),
        validade: this.orcamento.validade,
        id_orcamento: this.orcamento.id,
      };

      const responseFunction = {
        onSucess: (status) => (resp) => {
          if (status == 200) {
            this.$_ACTIONS_showSnackbarMessage({
              message: resp.message,
              color: "sucess",
            });
            this.$emit("close");
          } else {
            this.$_ACTIONS_showSnackbarMessage({
              message: resp.message,
              color: "error",
            });
          }
        },
        onError: () => {
          this.$_ACTIONS_showSnackbarMessage({
            message: this.$global.messages.internalServerError,
            color: "error",
          });
        },
        onEnd: () => {
          this.loadingGerarComanda = false;
          this.$emit("updateOrcamentos");
        },
      };

      this.services.orcamentoService.gerarComanda(orcamento, responseFunction);
    },
    formatoMoeda(valor) {
      return new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      }).format(valor);
    },
  },
};
</script>

<style></style>
