<template>
  <div>
    <v-card min-height="100vh">
      <v-toolbar style="background-color: #3e682a; color: #fff">
        <v-btn icon dark @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>
          <span class="headline">Arquivos Médicos</span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-card-text>
        <v-container>
          <v-data-table
            v-on:update:sort-by="options.ordenacao = true"
            :footer-props="{ 'items-per-page-options': [5, 10, 15, 20] }"
            :search="search"
            :headers="headers"
            :items="listArquivos"
            :options.sync="options"
            :server-items-length="totalList"
            :loading="loading"
            sort-by="descricao"
            class="elevation-1"
          >
            <template v-slot:top class="my-4">
              <v-toolbar flat>
                <div class="row justify-content-end mt-2">
                  <div class="col-4">
                    <v-text-field
                      v-model="search"
                      append-icon="mdi-magnify"
                      label="Pesquisar"
                      single-line
                      hide-details
                    />
                  </div>
                </div>
              </v-toolbar>
            </template>
            <template v-slot:item.actions="{ item }">
              <v-icon
                class="mr-2"
                small
                @click="donwloadItem(item)"
                title="Baixar arquivo"
              >
                fas fa-download
              </v-icon>
            </template>
            <template v-slot:no-data>
              <p class="text-center">Nenhum registro encontrado</p>
              <v-btn color="#3E682A" dark @click="atualizarArquivos">
                Atualizar
              </v-btn>
            </template>
          </v-data-table>
        </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import DocumentoService from "@/services/documentos_service.js";
import Mixin from "@/mixins/vuex_snackbar_mixin.js";

export default {
  mixins: [Mixin],

  props: {
    arquivos: Array,
    paciente: Object,
  },
  mounted() {
    this.atualizarArquivos();
  },
  data: () => ({
    file: "",
    listArquivos: [],
    search: "",
    totalList: 10,
    options: {
      page: 1,
      pageCount: 0,
      itemsPerPage: 15,
      ordenacao: false,
      desc: false,
    },
    headers: [
      {
        text: "Nome",
        align: "start",
        sortable: true,
        value: "descricao",
      },
      { text: "Ações", align: "end", value: "actions", sortable: false },
    ],
    loading: false,
  }),

  watch: {
    options: {
      handler() {
        this.options.desc = this.options.sortDesc[0] ?? false;
        this.atualizarArquivos();
      },
      deep: true,
    },
    search() {
      this.atualizarArquivos();
    },
    paciente() {
      this.atualizarArquivos();
    },
  },

  methods: {
    atualizarArquivos() {
      const documentoService = new DocumentoService();
      this.loading = true;
      this.listArquivos = [];
      documentoService.buscar_arquivos_exames(
        {
          onSucess: (status) => (body) => {
            if (status === 200) {
              this.totalList = body.data.pagination.num_rows;
              this.listArquivos = body.data.arquivo;
            } else {
              console.log(body);
            }
          },
          onError: (error) => {
            console.log(error);
          },
          onEnd: () => {
            this.loading = false;
          },
        },
        this.paciente.id_paciente,
        this.paciente.id_pet,
        this.options.page,
        this.options.itemsPerPage,
        this.search,
        this.options.ordenacao,
        this.options.desc
      );
    },
    donwloadItem(item) {
      const documentoService = new DocumentoService();
      this.loading = true;
      documentoService.ver_arquivo_exame(
        {
          onSucess: (status) => (body) => {
            if (status === 200) {
              var file = document.createElement("a"); //Create <a>
              const listFilesApplication = ["pdf", "doc", "docx"];
              const ext = listFilesApplication.includes(body.data.ext)
                ? "application/"
                : "image/";
              file.href =
                "data:" + ext + body.data.ext + ";base64," + body.data.arquivo;
              file.download = body.data.descricao + "." + body.data.ext;
              file.click();
            } else {
              console.log(body);
            }
          },
          onError: (error) => {
            console.log(error);
          },
          onEnd: () => {
            this.loading = false;
          },
        },
        item.id
      );
    },
  },
};
</script>
