import HTTPService from "@/services/http_service.js";

export default class RelatorioService extends HTTPService {
  resource = "relatorio";

  static build() {
    return new RelatorioService();
  }

  relatorioProcedimentoExecutado(model) {
    return this.post(
      `${this.resource}/executados/unidade/periodo/excel`,
      model
    ).then((resp) => {
      if (resp.status !== 200) return resp;
      return resp.blob();
    });
  }
  //   return this.post(
  //     "/executados/unidade/periodo/excel",
  //     requestBody,
  //     {
  //       responseType: "blob",
  //     }
  //   ).then((resp) => {
  //     if (resp.status !== 200) return resp;
  //     return resp.blob();
  //   });
  // }
}
