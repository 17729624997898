import HTTPService from "@/services/http_service.js";
// import { global } from "@/config/constants";

export default class RequisicaoExameService extends HTTPService {
  resource = "requisicao";

  static build() {
    return new RequisicaoExameService();
  }

  async getRequisicoesAbertasAtendimentoMedico(petId, unidadeId) {
    const resp = await this.get(
      `${this.resource}/atender/pet/unidade/${petId}/${unidadeId}`
    );

    return resp.json();
  }

  async getRequisicoesAbertas(petId, unidadeId) {
    return await this.get(`${this.resource}/pet/exames/${petId}/${unidadeId}`);
  }

  async getRequisicoesAbertasPagas(petId, unidadeId) {
    return await this.get(
      `${this.resource}/pet/exames/${petId}/${unidadeId}?status_comanda=2`
    );
  }

  async getRequisicoesAbertasBasico(petId, unidadeId) {
    return await this.get(
      `${this.resource}/pet/unidade/exames/emaberto/${petId}/${unidadeId}`
    );
  }

  async getRequisicoesInternacoesAbertas(petId, unidadeId) {
    return await this.get(
      `${this.resource}/pet/internacao/${petId}/${unidadeId}`
    );
  }

  async getRequisicoesInternacoesAbertasPagas(petId, unidadeId) {
    return await this.get(
      `${this.resource}/pet/internacao/${petId}/${unidadeId}?status_comanda=2`
    );
  }

  async getRequisicoesCirurgiasAbertas(petId, unidadeId) {
    return await this.get(
      `${this.resource}/pet/cirurgia/${petId}/${unidadeId}`
    );
  }

  async getRequisicoesMedicacoesAbertas(petId, unidadeId) {
    return await this.get(
      `${this.resource}/pet/medicacao/${petId}/${unidadeId}`
    );
  }

  async getRequisicoesMedicacoesAbertasPagas(petId, unidadeId) {
    return await this.get(
      `${this.resource}/pet/medicacao/${petId}/${unidadeId}?status_comanda=2`
    );
  }

  async getRequisicoesConsultas(petId) {
    return await this.get(`${this.resource}/pet/consulta/${petId}`);
  }

  getRequisicoesConsultasByUnidade(unidadeId) {
    return this.get(`${this.resource}/consulta/aberta/${unidadeId}`);
  }

  getRequisicoesExamesByUnidade(unidadeId) {
    return this.get(`${this.resource}/exames/aberta/${unidadeId}`);
  }

  cadastrarRequisicaoExame(requisicao) {
    return this.post(this.resource, { ...requisicao });
  }

  requisitarExamePet(requisicao) {
    return this.post(`${this.resource}/recepcao/exame`, { ...requisicao });
  }

  requisitarConsulta(idTutor, idPet, idUnidade, requisicao) {
    return this.post(`requisicao/consulta/${idTutor}/${idPet}/${idUnidade}`, {
      ...requisicao,
    });
  }

  requisitarInternacao(idTutor, idPet, idUnidade, requisicao) {
    // const tutor = idTutor;
    // console.log(tutor);
    return this.post(`requisicao/internacao/${idTutor}/${idPet}/${idUnidade}`, {
      ...requisicao,
    });
  }

  excluirRequisicaoConsulta(requisicaoId) {
    return this.post(`${this.resource}/consulta/cancelar/${requisicaoId}`);
  }

  marcarExecutado(requisicaoId) {
    requisicaoId = ~~requisicaoId;
    return this.post(`requisicao/marcar-executado/${requisicaoId}`);
  }

  excluirRequisicao(requisicaoId) {
    return this.post(`${this.resource}/exame/cancelar-medico/${requisicaoId}`);
  }

  excluirRequisicaoAssistente(requisicaoId) {
    return this.post(
      `${this.resource}/exame/cancelar-assistente/${requisicaoId}`
    );
  }

  setEncaminharRequisicaoPorSenhaChamefacil(infoRequisicao) {
    return this.post("cf/recepcao/chamada/encaminhar", infoRequisicao);
  }

  setAguardarRequisicaoPorSenhaChamefacil(infoRequisicao) {
    return this.post("cf/recepcao/chamada/aguardar", infoRequisicao);
  }

  getMedicoRequisicaoPorSenhaChamefacil(
    idSenha,
    idPet,
    idUnidade,
    idFilaInterna
  ) {
    return this.get(
      `cf/medico/chamada/search/${idSenha}/${idPet}/${idUnidade}/${idFilaInterna}`
    );
  }

  getRecepcaoRequisicaoPorSenhaChamefacil(idSenha, idPet, idUnidade) {
    return this.get(
      `cf/recepcao/chamada/search/${idSenha}/${idPet}/${idUnidade}`
    );
  }
}
