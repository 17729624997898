<template>
  <v-card>
    <v-card-title>
      <span class="headline">Cadastrar convênio</span>
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-row>
          <v-col cols="12">
            <div class="col-lg-12 form-group clearfix mb-3">
              <v-text-field
                v-model="descricao"
                label="Descrição"
              ></v-text-field>
            </div>

            <div class="col-lg-12 form-group clearfix mb-3">
              <v-text-field v-model="codigo" label="Código"></v-text-field>
            </div>

            <div class="col-lg-12 form-group clearfix mb-3">
              <label>
                Tipo de Convênio
              </label>
              <v-select
                style="padding-top: 0 !important"
                :items="tipoConvenioList"
                v-model="tipoConvenio"
              />
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="#3E682A" text @click="$emit('close')"> Cancelar </v-btn>
      <v-btn
        color="#3E682A"
        :loading="loading"
        text
        @click="cadastrar"
        :disabled="!descricao || !codigo || !tipoConvenio"
      >
        Salvar
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import ConvenioService from "@/services/convenio_service.js";
import Mixin from "@/mixins/vuex_snackbar_mixin.js";

export default {
  mixins: [Mixin],
  data: () => ({
    formCadastro: "",
    descricao: "",
    tipoConvenio: "",
    codigo: "",
    tipoConvenioList: [],
    loading: false,
    services: {
      convenioService: ConvenioService.build(),
    },
    rules: {
      required: (value) => !!value || "Campo obrigatório.",
    },
  }),
  async mounted() {
    this.formCadastro = document.forms[0];
    await this.setTipoConvenio();
  },
  methods: {
    cadastrar() {
      this.loading = true;
      this.services.convenioService
        .register(this.getFormData())
        .then(({ status }) => {
          this.loading = false;
          this.mostrarFeedback(status);
          if (status === 200) {
            this.resetForm();
            this.$emit("response");
          }
        })
        .catch((error) => {
          this.loading = false;
          console.error(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async setTipoConvenio() {
      await this.services.convenioService
        .getTipoConvenio()
        .then(async (response) => {
          const { data } = await response.json();
          this.tipoConvenioList = data.map((item) => {
            return {
              text: item.descricao,
              value: item.id,
            };
          });
        })
        .catch((error) => {
          console.error(error);
        });
    },
    mostrarFeedback(status) {
      this.$_ACTIONS_showSnackbarMessage({
        message:
          status === 200
            ? "Convênio cadastrado com sucesso!"
            : status === 400
            ? "Preencha os dados Corretamente"
            : this.$global.messages.internalServerError,
        color: status === 200 ? "sucess" : "error",
      });
    },
    resetForm() {
      this.descricao = "";
    },
    getFormData() {
      const formData = new FormData();
      formData.append("descricao", this.descricao);
      formData.append("id_tipo_convenio", this.tipoConvenio);
      formData.append("codigo", this.codigo);
      return formData;
    },
  },
};
</script>
