<template>
  <v-card>
    <v-toolbar style="background-color: #3e682a; color: #fff">
      <v-btn icon dark @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>
        <span class="headline">Cadastrar medico</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items> </v-toolbar-items>
    </v-toolbar>
    <div class="p-5">
      <form class="p-lg-3 row">
        <div class="col-lg-4 form-group clearfix mb-3">
          <label for="nome">Nome</label>
          <v-text-field
            v-model="medicoNome"
            placeholder="Seu nome completo"
            style="padding-top: 0 !important"
          ></v-text-field>
        </div>

        <div class="col-lg-4 form-group clearfix mb-3">
          <label for="nome">E-mail</label>
          <v-text-field
            v-model="email"
            :rules="[rules.required, rules.email]"
            placeholder="E-mail"
            style="padding-top: 0 !important"
          ></v-text-field>
        </div>

        <div class="col-lg-4 form-group clearfix mb-3">
          <label for="nome">Senha</label>
          <v-text-field
            v-model="senha"
            :rules="[rules.required]"
            placeholder="Senha"
            :type="'password'"
            style="padding-top: 0 !important"
          ></v-text-field>
        </div>

        <div class="col-lg-3 form-group clearfix mb-3">
          <label for="nome">CPF</label>
          <v-text-field
            v-mask="'###.###.###-##'"
            v-model="cpf"
            :rules="[rules.required]"
            placeholder="CPF"
            style="padding-top: 0 !important"
          ></v-text-field>
        </div>

        <div class="col-lg-3 form-group clearfix mb-3">
          <label for="nome">RG</label>
          <v-text-field
            v-model="rg"
            :rules="[rules.required]"
            placeholder="RG"
            style="padding-top: 0 !important"
          ></v-text-field>
        </div>

        <div class="col-lg-3 form-group clearfix mb-3">
          <label for="nome">CRMV</label>
          <v-text-field
            v-model="documento"
            :rules="[rules.required]"
            placeholder="CRMV"
            style="padding-top: 0 !important"
          ></v-text-field>
        </div>

        <div class="col-lg-2 form-group clearfix mb-3">
          <label for="nome">Gênero</label>
          <v-radio-group :rules="[rules.required]" v-model="genero">
            <v-radio label="Masculino" value="M" />
            <v-radio label="Feminino" value="F" />
          </v-radio-group>
        </div>

        <div class="col-lg-4 form-group clearfix mb-3">
          <label for="nome">Profissão</label>
          <v-text-field
            v-model="profissao"
            :rules="[rules.required]"
            placeholder="Profissão"
            style="padding-top: 0 !important"
          ></v-text-field>
        </div>

        <div class="col-lg-4 form-group clearfix mb-3">
          <label for="nome">Estado civil</label>
          <v-text-field
            v-model="estado_civil"
            :rules="[rules.required]"
            placeholder="Estado civil"
            style="padding-top: 0 !important"
          ></v-text-field>
        </div>

        <div class="col-lg-4 form-group clearfix mb-3">
          <label for="nome">Nascimento</label>
          <v-text-field
            v-model="data_nascimento"
            :rules="[rules.required]"
            v-mask="'####-##-##'"
            style="padding-top: 0 !important"
            placeholder="Data Nascimento"
            clear-icon="clear"
            :type="'date'"
            :outlined="false"
          />
        </div>

        <div class="col-lg-4 form-group clearfix mb-3">
          <label for="nome">Telefone Celular</label>
          <v-text-field
            v-model="telefone_1"
            v-mask="'(##)#####-####'"
            :rules="[rules.required]"
            placeholder="Telefone"
            style="padding-top: 0 !important"
          ></v-text-field>
        </div>

        <div class="col-lg-4 form-group clearfix mb-3">
          <label for="nome">Telefone Fixo</label>
          <v-text-field
            v-mask="'(##)####-####'"
            v-model="telefone_2"
            placeholder="Telefone"
            style="padding-top: 0 !important"
          ></v-text-field>
        </div>

        <div class="col-lg-4 form-group clearfix mb-3">
          <label for="nome">CEP</label>
          <v-text-field
            v-mask="'#####-###'"
            v-model="cep"
            @blur="searchCep"
            :rules="[rules.required]"
            placeholder="CEP"
            style="padding-top: 0 !important"
          ></v-text-field>
        </div>

        <div class="col-lg-4 form-group clearfix mb-3">
          <label for="nome">Logradouro</label>
          <v-text-field
            v-model="logradouro"
            :rules="[rules.required]"
            placeholder="Logradouro"
            style="padding-top: 0 !important"
          ></v-text-field>
        </div>

        <div class="col-lg-4 form-group clearfix mb-3">
          <label for="nome">Número</label>
          <v-text-field
            v-model="numero"
            :rules="[rules.required]"
            placeholder="Número"
            style="padding-top: 0 !important"
          ></v-text-field>
        </div>

        <div class="col-lg-4 form-group clearfix mb-3">
          <label for="nome">Complemento</label>
          <v-text-field
            v-model="complemento"
            placeholder="Complemento"
            style="padding-top: 0 !important"
          ></v-text-field>
        </div>

        <div class="col-lg-3 form-group clearfix mb-3">
          <label for="nome">Referência</label>
          <v-text-field
            v-model="referencias"
            placeholder="Referência"
            style="padding-top: 0 !important"
          ></v-text-field>
        </div>

        <div class="col-lg-3 form-group clearfix mb-3">
          <label for="nome">Bairro</label>
          <v-text-field
            v-model="bairro"
            :rules="[rules.required]"
            placeholder="Bairro"
            style="padding-top: 0 !important"
          ></v-text-field>
        </div>

        <div class="col-lg-3 form-group clearfix mb-3">
          <label for="nome">Cidade</label>
          <v-text-field
            v-model="cidade"
            :rules="[rules.required]"
            placeholder="Cidade"
            style="padding-top: 0 !important"
          ></v-text-field>
        </div>

        <div class="col-lg-3 form-group clearfix">
          <label for="nome">Estado</label>
          <v-text-field
            v-model="estado"
            :rules="[rules.required]"
            placeholder="Estado"
            style="padding-top: 0 !important"
          ></v-text-field>
        </div>

        <div class="col-lg-4 form-group clearfix mt-n3">
          <label for="">Unidades</label>
          <v-combobox
            v-model="unidadesSelecionadas"
            :items="unidades"
            item-text="text"
            item-value="value"
            label="Selecione a Unidade"
            multiple
            outlined
          ></v-combobox>
        </div>

        <div
          class="col-lg-2 form-group clearfix"
          style="padding-top: 0 !important"
        >
          <label>Status</label>
          <v-select
            outlined
            :items="statusSelect"
            item-text="descricao"
            item-value="id"
            v-model="ativo"
          />
        </div>

        <div class="col-md-12 text-right">
          <button
            @click.prevent="cadastrarmedico"
            class="btn btn-app-primary btn-rounded font-weight-bold"
          >
            <span v-if="!loading"> Cadastrar </span>
            <v-progress-circular
              v-else
              indeterminate
              color="white"
            ></v-progress-circular>
          </button>
        </div>
      </form>
    </div>
  </v-card>
</template>

<script>
import CEPMixin from "@/mixins/cep_mixin.js";
import Mixin from "@/mixins/vuex_mixin.js";
import MedicoService from "../../services/medico_service";
import endpoints from "../../router/endpoints";
import UnidadeService from "../../services/unidade_service";
// import PictureInput from "vue-picture-input";

export default {
  mixins: [CEPMixin, Mixin],
  components: {
    // PictureInput,
  },
  data: () => ({
    descricao: "",
    formCadastro: "",
    permissoes: [],
    medicoNome: "",
    image: "",
    email: "",
    rg: "",
    documento: "",
    genero: "",
    data_nascimento: "",
    profissao: "",
    estado_civil: "",
    cep: "",
    numero: "",
    complemento: "",
    referencias: "",
    cpf: "",
    telefone_1: "",
    telefone_2: "",
    bairro: "",
    cidade: "",
    estado: "",
    logradouro: "",
    senha: "",
    loading: false,
    unidades: [],
    unidadesSelecionadas: [],
    statusSelect: [
      { descricao: "Ativo", id: 1 },
      { descricao: "Inativo", id: 2 },
    ],
    ativo: 1,
    services: {
      medicoService: MedicoService.build(),
      unidadeService: UnidadeService.build(),
    },
    rules: {
      required: (value) => !!value || "Campo obrigatório.",
      email: (value) => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "Email inválido.";
      },
      min: (v) => v.length >= 8 || "Minimo de 8 caracteres",
    },
  }),
  computed: {
    endpoints() {
      const e = Object.keys(endpoints).map((k) => ({
        value: endpoints[k],
        text: k,
        divider: false,
      }));
      console.log({ e });
      return e;
    },
  },
  async mounted() {
    this.formCadastro = document.forms[0];
    await this.setUnidades();
  },
  methods: {
    searchCep() {
      this.$buscarCep(this, this.cep);
    },
    async cadastrarmedico() {
      this.loading = true;
      const formData = new FormData();
      formData.append("ativo", this.ativo);
      formData.append("foto", this.image);
      formData.append("nome", this.medicoNome);
      formData.append("email", this.email);
      formData.append("rg", this.rg);
      formData.append("documento", this.documento);
      formData.append("genero", this.genero);
      formData.append("data_nascimento", this.data_nascimento);
      formData.append("profissao", this.profissao);
      formData.append("estado_civil", this.estado_civil);
      formData.append("cep", this.cep);
      formData.append("numero", this.numero);
      formData.append("complemento", this.complemento);
      formData.append("referencias", this.referencias);
      formData.append("cpf", this.cpf);
      formData.append("telefone_1", this.telefone_1);
      formData.append("telefone_2", this.telefone_2);
      formData.append("bairro", this.bairro);
      formData.append("cidade", this.cidade);
      formData.append("estado", this.estado);
      formData.append("logradouro", this.logradouro);
      formData.append("senha", this.senha);
      formData.append(
        "unidade",
        this.unidadesSelecionadas.map((u) => u.value).toString()
      );
      await this.services.medicoService
        .register(formData)
        .then(async (response) => {
          const resp = await response.json();
          if (response.status == 200) {
            this.loading = false;
            this.formCadastro.reset();
            this.image = "";
            this.mostrarFeedback(resp.message, "sucess");
            this.$emit("response");
          } else if (response.status == 503) {
            this.loading = false;
            this.formCadastro.reset();
            this.image = "";
            this.mostrarFeedback(resp.message, "sucess");
            this.$emit("response");
          } else {
            const erro = resp.errors
              ? resp.errors[Object.keys(resp.errors)[0]]
              : resp.message;
            this.mostrarFeedback(erro, "error");
          }
        })
        .catch((error) => {
          this.loading = false;
          this.$_ACTIONS_showSnackbarMessage({
            message: error,
            color: "error",
          });
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    trocarImagem() {
      if (this.$refs.pictureInput) {
        this.avatarmedico = this.$refs.pictureInput.file;
        this.image = this.$refs.pictureInput.image;
      } else {
        console.log("API File Reader não suportada: use o <form>");
      }
    },
    async setListaRacas() {
      await this.services.medicoService.getRacas().then((response) => {
        console.log(response);
        this.listaRacas = response.data.racas.map((r) => ({
          value: r.id,
          text: r.nome,
        }));
      });
    },
    mostrarFeedback(message, color) {
      this.$_ACTIONS_showSnackbarMessage({
        message,
        color,
      });
      // this.$router.push("/medicos");
    },
    async setUnidades() {
      await this.services.unidadeService
        .getUnidadeList()
        .then((response) => {
          console.log(response);
          this.unidades = response.map((u) => ({
            value: u.pessoaJuridicaId,
            text: u.nomeFantasia,
          }));
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
