<template>
  <v-card>
    <v-card-title>
      <span class="headline">Cadastrar tipo de procedimento</span>
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-row>
          <v-col cols="12">
            <v-text-field v-model="descricao" label="Nome"></v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="#3E682A" text @click="$emit('close')"> Cancelar </v-btn>
      <v-btn
        color="#3E682A"
        :loading="loading"
        text
        @click="cadastrar"
        :disabled="!descricao"
      >
        Salvar
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import TipoProcedimentoService from "@/services/tipo_procedimento_service.js";
import Mixin from "@/mixins/vuex_snackbar_mixin.js";

export default {
  mixins: [Mixin],
  data: () => ({
    formCadastro: "",
    descricao: "",
    loading: false,
    services: {
      tipoProcedimentoService: TipoProcedimentoService.build(),
    },
    rules: {
      required: (value) => !!value || "Campo obrigatório.",
    },
  }),
  mounted() {
    this.formCadastro = document.forms[0];
  },
  methods: {
    cadastrar() {
      this.loading = true;
      this.services.tipoProcedimentoService
        .register(this.getFormData())
        .then(({ status }) => {
          this.loading = false;
          this.mostrarFeedback(status);
          if (status === 200) {
            this.resetForm();
            this.$emit("response");
          }
        })
        .catch((error) => {
          this.loading = false;
          console.error(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    mostrarFeedback(status) {
      this.$_ACTIONS_showSnackbarMessage({
        message:
          status === 200
            ? "Tipo Procedimento cadastrado com sucesso!"
            : status === 400
            ? "Preencha os dados Corretamente"
            : this.$global.messages.internalServerError,
        color: status === 200 ? "sucess" : "error",
      });
    },
    resetForm() {
      this.descricao = "";
    },
    getFormData() {
      const formData = new FormData();
      formData.append("descricao", this.descricao);
      return formData;
    },
  },
};
</script>