<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12" v-if="itens.length > 0">
        <v-data-table
          :headers="tipoTable"
          :items="itens"
          :items-per-page="5"
          class="elevation-1"
        >
          <template v-slot:item.observacao="{ item }">
            <v-btn
              icon
              elevation="0"
              color="#02004f"
              @click="viewObs(item.observacao)"
            >
              <v-icon>mdi-eye</v-icon>
            </v-btn>
          </template>
        </v-data-table>
        <v-dialog v-model="dialogObs" scrollable max-width="300px">
          <v-card>
            <v-toolbar style="color: #02004f;">
              <v-toolbar-title>
                <span class="headline">Observação</span>
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-items> </v-toolbar-items>
              <v-btn fab dark small color="red" @click="dialogObs = false">
                <v-icon dark>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-text class="py-5">
              <p>{{ text }}</p>
            </v-card-text>
          </v-card></v-dialog
        >
      </div>
      <div class="col-12" v-else>
        <h3>Sem registros de {{ tipo }}</h3>
      </div>
    </div>
  </div>
</template>

<script>
import Mixin from "@/mixins/vuex_mixin.js";

export default {
  mixins: [Mixin],
  props: {
    itens: {
      type: Object,
      required: true,
    },
    tipoTable: {
      type: Object,
      required: true,
    },
    tipo: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    header: [{ descricao: "Descricao", value: "descricao" }],
    text: "",
    dialogObs: false,
  }),
  mounted() {},
  watch: {},
  methods: {
    viewObs(observacao) {
      this.text = observacao ? observacao : "Sem observação";
      this.dialogObs = true;
    },
  },
};
</script>
<style scoped></style>
