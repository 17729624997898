<template>
  <div>
    <v-card min-height="100vh">
      <v-toolbar style="background-color: #1daf80; color: #fff">
        <v-btn icon dark @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>
          <span class="headline">Receita</span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-card-text>
        <v-container>
          <v-select
            v-model="convenioSelecionado"
            :items="convenios"
            label="Convênio"
            class="mb-2 ml-1 col-7"
          >
          </v-select>
          <vue-editor v-model="content"></vue-editor>
          <br />
          <div class="d-flex flex-column" v-if="receita.length > 0">
            <u v-for="(item, i) in receita" :key="i">
              <li class="d-flex flex-column resp">
                <h4>{{ item.titulo }}</h4>
                <div class="d-flex align-items-center justify-center">
                  <v-textarea
                    class="col-10"
                    rows="2"
                    v-model="item.receitaTexto"
                  ></v-textarea>
                  <v-btn @click="remover_item(i)" class="ml-3" small icon
                    ><v-hover v-slot="{ hover }"
                      ><v-icon medium :color="hover ? '#F44336' : 'gray'">
                        fas fa-times</v-icon
                      ></v-hover
                    ></v-btn
                  >
                </div>
              </li>
            </u>
          </div>
        </v-container>
        <div class="row mt-5">
          <div class="col-md-12 text-right d-flex justify-lg-space-between">
            <button
              class="btn btn-cancelar btn-padding font-weight-bold text-gray"
              @click="$emit('close')"
            >
              Cancelar
            </button>
            <button
              class=" "
              :class="
                convenioSelecionado != 'Público' &&
                convenioSelecionado != 'Particular'
                  ? 'btn-style-gray'
                  : 'btn-style-green'
              "
              @click="gerarPdf()"
              :disabled="
                convenioSelecionado != 'Público' &&
                  convenioSelecionado != 'Particular'
              "
            >
              <span> Gerar PDF </span>
            </button>
          </div>
        </div>
      </v-card-text>
    </v-card>
    <!-- EXAMES PDF -->
    <div id="examesPDF" ref="examesPDF" v-show="false">
      <div id="header" class="header-receita d-flex">
        <img
          src="@/assets/images/anclivepa_publico.jpeg"
          style="margin: auto"
          alt="Logo anclivepa público"
          v-if="convenioSelecionado == 'Público'"
        />
        <img
          src="@/assets/images/logo_anclivepa.png"
          style="margin: auto"
          alt="Logo anclivepa particular"
          v-if="convenioSelecionado == 'Particular'"
        />
      </div>
      <div id="main">
        <div class="carbon zeroEspaco color-black">
          <div class="body">
            <div class="d-flex justify-content-between align-items-center">
              <p>{{ unidade }}</p>
              <div class="text-right">
                <p>
                  {{ informacoes_unidade.logradouro }},
                  {{ informacoes_unidade.numero }} -
                  {{ informacoes_unidade.cidade }}
                </p>
                <p>CEP: {{ informacoes_unidade.cep }}</p>
                <p>Tel.: {{ informacoes_unidade.telefone }}</p>
                <p>CNPJ: {{ informacoes_unidade.cnpj }}</p>
              </div>
            </div>
            <hr />
            <div class="d-flex justify-content-between align-items-stretch gap">
              <div class="border p-1">
                <b>Identificação do Emitente</b><br />
                <br /><b>Nome: </b> <span>{{ usuarioNome }}</span> <br /><b
                  >CRMV: </b
                >{{ crmv }} <span></span> <br /><b>Endereço: </b>
                <span>
                  {{ informacoes_unidade.logradouro }},
                  {{ informacoes_unidade.numero }} </span
                ><br />
                <span>
                  {{ informacoes_unidade.cidade }} -
                  {{ informacoes_unidade.cep }}
                </span>
              </div>
              <div class="border p-1">
                <b>Animal</b><br />
                <br />
                <b>Nome: </b> <span>{{ pet.nome_pet }}</span> <br />
                <b>Espécie: </b> <span>{{ pet.nome_especie }}</span> <br />
                <b>Raça: </b> <span>{{ pet.nome_raca }}</span> <br />
                <b>Sexo: </b>
                <span>{{ pet.sexo == "M" ? "Macho" : "Fêmea" }}</span> <br />
                <b>Peso: </b>
                <span>{{ pet.peso != "0" ? pet.peso : "Não Cadastrado" }}</span>
              </div>
              <div class="border p-1">
                <b>Tutor</b><br />
                <br />
                <b>Nome: </b> <span>{{ tutor.nome }}</span> <br />
                <b>CPF: </b> <span>{{ tutor.cpf }}</span> <br />
                <b>Endereço: </b>
                <span> {{ tutor.logradouro }}, {{ tutor.numero }} </span><br />
                <span> {{ tutor.cidade }} - {{ tutor.cep }} </span>
              </div>
            </div>
            <div id="intensReceitaTeste" class="ml-1" style="width: 94%;"></div>
          </div>
          <div>
            <br /><br />
            <hr />
            <div
              class="
                d-flex
                justify-content-between
                align-items-stretch
                gap
              "
            >
              <div>
                <p>
                  {{ informacoes_unidade.cidade }}, {{ gerarData().dia
                  }}<span class="space">_</span>de<span class="space">_</span
                  >{{ gerarData().mes }}<span class="space">_</span
                  >{{ gerarData().ano }}.
                </p>
                <p>
                  <b>{{ usuarioNome }}</b>
                </p>
                <p>CRMV: {{ crmv }}</p>
                <br />
                <p class="m-auto text-center">_________________________</p>
                <p class="m-auto text-center">Assinatura</p>
              </div>
              <div>
                <p><b>Identificação do Comprador</b></p>
                <p>Nome:</p>
                <p>RG:</p>
                <p>Endereço:</p>
                <p>Cidade e UF:</p>
                <p>Telefone:</p>
              </div>
              <div>
                <p><b>Identificação do Fornecedor</b></p>
                <p>Data:</p>
                <br /><br />
                <p class="m-auto text-center">_________________________</p>
                <p class="m-auto text-center">Assinatura</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MedicamentoService from "@/services/medicamento_service.js";
import VuexSnackbarMixin from "@/mixins/vuex_snackbar_mixin.js";
import { VueEditor } from "vue2-editor";

import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import PetsService from "@/services/pets_service.js";
import PacienteService from "@/services/paciente_service.js";

export default {
  mixins: [VuexSnackbarMixin],
  components: {
    VueEditor,
  },
  props: {
    idTutor: Number,
    idPet: String,
  },
  beforeMount() {
    // this.loadMedicamentos();
  },
  data: () => ({
    receita: [],
    receitaTexto: "",
    med: {},
    isLoading: false,
    medicamentos: [],
    medicamento: "",
    qntd: "",
    dosagem: "",
    titulo: "",
    convenios: ["Público", "Particular"],
    convenioSelecionado: "",
    editReceitaItem: [],
    content: "",
    petService: PetsService.build(),
    pacienteService: "",
    tutor: "",
    pet: "",
    unidade: "",
    usuarioNome: "",
    crmv: "",
    informacoes_unidade: "",
    receitas: "",
  }),
  mounted() {
    console.log(this.idTutor);
    console.log(this.idPet);
    this.getTutor();
    this.getPet();
    this.usuarioNome = JSON.parse(
      sessionStorage.getItem("vuex")
    ).usuario.dados.nome;
    this.unidade = JSON.parse(sessionStorage.getItem("vuex")).unidade.nome;
    this.unidade = this.unidade.toUpperCase();
    this.crmv = JSON.parse(sessionStorage.getItem("vuex")).perfil.documento;
    this.informacoes_unidade = JSON.parse(
      sessionStorage.getItem("vuex")
    ).unidade;
  },
  watch: {
    med() {
      this.receita_fill();
    },
    medicamento(e) {
      this.medicamento = e;
    },
    qntd(e) {
      this.qntd = e;
    },
    dosagem(e) {
      this.dosagem = e;
    },
    // editReceitaItem() {
    //   console.log(this.editReceitaItem);
    // },
  },
  methods: {
    remover_item(i) {
      this.receita.splice(i, 1);
    },
    receita_fill() {
      this.dosagem = this.med.posologia;
      this.qntd = this.med.quantidade;
      this.medicamento = this.med.nome;
    },
    loadMedicamentos() {
      const medicamentoService = new MedicamentoService();
      this.isLoading = true;
      medicamentoService.getList({
        onSucess: (status) => (body) => {
          if (status === 200) {
            this.medicamentos = body.data.medicamentos;
          }
        },
        onError: (error) => {
          console.log(error);
        },
        onEnd: () => {
          this.isLoading = false;
        },
      });
    },
    add() {
      if (this.receita.length < 5) {
        this.receita.push({
          titulo: this.titulo,
          receitaTexto: this.receitaTexto,
        });
        this.titulo = "";
        this.receitaTexto = "";
      } else {
        this.$_ACTIONS_showSnackbarMessage({
          message: "Quantidade Excedida. Crie outra outra receita.",
          color: "error",
        });
      }
    },

    async getPet() {
      await this.petService
        .getPetById(this.idPet)
        .then(async (response) => {
          if (response.status !== 200) {
            this.$_ACTIONS_showSnackbarMessage({
              message: response.message,
              color: response.status === 200 ? "sucess" : "error",
            });
          }
          console.log(response);
          this.pet = response;
        })
        .finally(() => {});
    },
    async getTutor() {
      this.pacienteService = new PacienteService();
      const onSucess = (status) => (body) => {
        if (status === 200) {
          this.tutor = body.data;
          console.log(this.tutor);
        } else {
          console.log(body);
        }
      };
      const onError = (error) => {
        console.log(error);
      };
      const onEnd = () => {};
      await this.pacienteService.get(
        { onSucess, onError, onEnd },
        this.idTutor
      );
    },
    gerarData() {
      const meses = [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novovembro",
        "Dezembro",
      ];
      let data = new Date();
      let dataFormatada = {
        dia: data.getDate(),
        mes: meses[data.getMonth()],
        ano: data.getFullYear(),
      };
      return dataFormatada;
    },
    gerarPdf() {
      //   this.$emit("gerarPDF", {
      //     doc: "receita",
      //     receita: this.content,
      //     convenio: this.convenioSelecionado
      //       .normalize("NFD")
      //       .replace(/[\u0300-\u036f]/g, "")
      //       .toLowerCase(),
      //   });
      //   this.receita = [];
      //   this.titulo = "";
      //   this.medicamento = "";
      //   this.qntd = "";
      //   this.dosagem = "";
      // },
      // gerarPDF(info) {
      console.log("this.convenioSelecionado");
      console.log(this.convenioSelecionado);

      // this.htmlToPdf = info.doc;
      document.getElementById("intensReceitaTeste").innerHTML = this.content;
      this.receitas = document.getElementById("intensReceitaTeste").children;
      this.receitas.forEach((item) => {
        // item.innerText = item.innerText.replaceAll("_", ".");
        console.log(item.innerText);
        item.style.cssText =
          "margin: 3px; font-size: 4px; width: 97%; font-family: Times, Times New Roman, serif;";
      });
      // this.receita = info.receita;

      console.log(this.htmlToPdf);
      console.log(this.$refs.examesPDF.innerHTML);
      window.html2canvas = html2canvas;
      var doc = new jsPDF("p", "mm", "a4");
      doc.html(this.$refs.examesPDF.innerHTML, {
        callback: function(pdf) {
          pdf.save("Receita" + ".pdf");
        },
        x: 5,
        y: 5,
      });
    },
  },
};
</script>
<style scoped>
.btn-style-green {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background-color: #1daf80 !important;
  height: 30px !important;
  font-size: 12px;
  padding: 10px !important;
  border-radius: 50px;
  margin: 20px 10px 20px 0px;
}
.btn-style-gray {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background-color: #979797 !important;
  height: 30px !important;
  font-size: 12px;
  padding: 10px !important;
  border-radius: 50px;
  margin: 20px 10px 20px 0px;
}
#examesPDF {
  text-align: center;
}

p {
  letter-spacing: 0.3px;
}

strong {
  letter-spacing: 0.5px !important;
}

#header,
#footer,
#main {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  margin-left: auto;
  width: 12.5rem;
  margin-right: auto;
}
#header img {
  width: 1rem;
}
.header-receita {
  margin: 0 !important;
  padding: 0 !important;
}
#main {
  display: flex;
  justify-content: center;
  align-content: center;
  font-size: 4px;
  height: 15.8rem;
  max-height: 17rem;
  /* width: 13vw; */
  padding-left: 0.2rem;
  padding-right: 0.2rem;
  /* background-color: tomato; */
}
#footer {
  /* background-color: rebeccapurple; */
  margin-top: 0.1rem;
  margin-bottom: 0;
  margin-right: 0.1rem;
  height: 1.2rem;
}
#footer img {
  max-width: 12.5rem;
  max-height: 1.2rem;
}

.carbonada {
  font-size: 3px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  /* background-color: rgb(233, 255, 255); */
  margin-left: 6rem;
  padding-top: 0px;
}

.carbon {
  width: 100vw !important;
  font-size: 3px;
}

.carbon .body {
  min-height: 30vh !important;
}
.carbon .rodape {
  min-height: 70vh !important;
  position: static !important;
  bottom: 0 !important;
}

.carbon p,
.carbon hr {
  margin: 0 !important;
  padding: 0 !important;
}
.gap {
  gap: 1px;
}
.texto {
  padding: 1px;
  font-size: 5px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}
.space {
  color: white;
}
.zeroEspaco {
  padding: 0 !important;
  margin: 0 !important;
}
.marg-2 {
  margin-left: 0.7rem !important;
  margin-right: 0.7rem !important;
}
.indent {
  text-indent: 5px;
}
.termo {
  font-size: 3.5px;
  color: black;
  margin: 0 !important;
  padding: 0 !important;
  width: 100vw;
}
.color-black {
  color: black;
}
.rodape {
  position: fixed;
  bottom: 0;
}
.termo .small {
  line-height: 4px !important;
  letter-spacing: 0.2px !important;
  font-size: 3.2px;
}
.listaIntensReceita {
  display: flex;
  flex-direction: column;
  gap: 0 !important;
  color: brown !important;
}
</style>
