<template>
  <div class="p-5 col-12 mb-6 shadow bg-cinza rounded">
    <div class="d-flex flex-colunm col-12">
      <v-select
        v-model="tipo"
        :items="tipos_inputs"
        item-text="descricao"
        item-value="id"
        label="Tipo de pergunta"
        class="mr-2 col-4"
        :loading="loading_tipo_pergunsta"
      ></v-select>
      <v-text-field
        v-if="tipo"
        type="number"
        v-model="ordem"
        label="Ordem"
        class="col-1"
      ></v-text-field>
      <v-switch
        v-if="tipo"
        v-model="ativa"
        :label="ativa ? 'Ativa' : 'Inativa'"
        class="ml-2 mt-5"
      ></v-switch>
    </div>
    <div v-if="tipo" class="d-flex col-12 ml-2">
      <v-textarea
        v-if="tipo"
        rows="3"
        label="Pergunta"
        v-model="texto_pergunta"
        class="col-7 mr-4"
        outlined
      ></v-textarea>
      <v-switch v-model="obrigatoria" label="É obrigatória?"></v-switch>
    </div>
    <div class="d-flex flex-colunm col-12 mr-2">
      <v-text-field
        v-if="tipo === 3 || tipo === 4"
        type="number"
        v-model="opcoes"
        label="Qtd. de opções"
        class="col-2"
      ></v-text-field>
      <div class="d-flex" v-if="loading_options">
        <v-progress-circular
          :size="50"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </div>
      <div v-else class="d-flex">
        <div
          class="d-flex flex-wrap"
          v-if="tipo === 3 && opts_radio.length > 1"
        >
          <v-radio-group
            v-model="radioGroup"
            row
            v-for="(n, i) in opts_radio"
            :key="i"
          >
            <v-radio :value="i"></v-radio>
            <v-text-field v-model="opts_radio[i].descricao"></v-text-field>
          </v-radio-group>
        </div>
        <div
          class="d-flex  flex-wrap "
          v-if="tipo === 4 && opts_check.length > 1"
        >
          <div v-for="(opt_check, i) in opts_check" :key="i">
            <div class="d-flex">
              <v-checkbox></v-checkbox>
              <v-text-field v-model="opt_check.descricao"></v-text-field>
            </div>
          </div>
        </div>
      </div>
    </div>
    <v-btn color="success" @click="addPergunta"> Adicionar pergunta </v-btn>
  </div>
</template>
<script>
import Mixin from "@/mixins/vuex_snackbar_mixin.js";

export default {
  mixins: [Mixin],
  beforeMount() {
    this.carregar_tipos_pergunta();
  },
  data: () => ({
    loading_tipo_pergunsta: false,
    loading_options: false,
    obrigatoria: false,
    tipo: null,
    texto_pergunta: "",
    tipos_inputs: [],
    opcoes: 0,
    opts_check: [],
    opts_radio: [],
    radioGroup: 1,
    ordem: 0,
    ativa: true,
  }),
  watch: {
    tipo(tipo) {
      if (tipo === 4 || tipo === 3) {
        this.opcoes = 2;
      }
    },
    opcoes(value) {
      if (value < 0) {
        this.opcoes = 2;
      } else {
        this.opts_check = [];
        this.opts_radio = [];
        this.loading_options = true;
        if (this.tipo === 3) {
          let i = 0;
          while (i < this.opcoes) {
            this.opts_radio.push({ descricao: "" });
            i++;
          }
        } else {
          let i = 0;
          while (i < this.opcoes) {
            this.opts_check.push({ descricao: "" });
            i++;
          }
        }
        this.loading_options = false;
      }
    },
  },
  methods: {
    addPergunta() {
      if (this.tipo == null) {
        this.$_ACTIONS_showSnackbarMessage({
          message: "Selecione um tipo de pergunta",
          color: "warning",
        });
        return;
      }
      if (this.texto_pergunta == null || this.texto_pergunta == "") {
        this.$_ACTIONS_showSnackbarMessage({
          message: "Digite uma pergunta",
          color: "warning",
        });
        return;
      }
      if (this.tipo === 3 && this.opts_radio.length < 2) {
        this.$_ACTIONS_showSnackbarMessage({
          message: "Adicione mais uma opção de resposta",
          color: "warning",
        });
        return;
      }
      let pergunta = null;
      if (this.tipo === 3 || this.tipo === 4) {
        pergunta = {
          texto_pergunta: this.texto_pergunta,
          id_tipo_pergunta: this.tipo,
          obrigatorio: this.obrigatoria,
          opcoes: this.tipo === 3 ? this.opts_radio : this.opts_check,
          ordem: this.ordem,
          ativo: this.ativa,
        };
      } else {
        pergunta = {
          texto_pergunta: this.texto_pergunta,
          id_tipo_pergunta: this.tipo,
          obrigatorio: this.obrigatoria,
          ordem: this.ordem,
          ativo: this.ativa ? 1 : 0,
        };
      }

      this.zerar_campos();
      this.$emit("addPergunta", pergunta);
    },
    zerar_campos() {
      this.texto_pergunta = "";
      this.tipo = null;
      this.obrigatoria = false;
      this.opts_radio = [];
      this.opts_check = [];
      this.ordem = 0;
      this.ativa = true;
    },
    carregar_tipos_pergunta() {
      this.loading_tipo_pergunsta = true;
      this.tipos_inputs = [
        { id: 1, descricao: "Resposta curta" },
        { id: 2, descricao: "Parágrafo" },
        { id: 3, descricao: "Múltipla escolha" },
        { id: 4, descricao: "Checkbox" },
        // { id: 5, descricao: "Resposta numérica inteiro" },
        // { id: 6, descricao: "Sim ou não" },
        // { id: 7, descricao: "Resposta numérica decimal" },
      ];
      this.loading_tipo_pergunsta = false;
    },
  },
};
</script>
<style scoped>
.v-progress-circular {
  margin: 1rem;
}
</style>
