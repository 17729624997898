<template>
  <div v-if="!loadingInicial">
    <div class="p-lg-3">
      <div class="row justify-content-between">
        <h3 class="mt-0 mb-2 font-weight-bold text-left">Agendar Consulta</h3>
      </div>
    </div>

    <form class="p-lg-3 row">
      <div class="col-lg-6 form-group clearfix mb-0">
        <label class="mb-0">Selcione o Pet</label>
        <v-select
          style="padding-top: 0 !important"
          :items="pets"
          v-model="petId"
        />
        <!-- <v-autocomplete
          class="mb-0 mt-0"
          v-model="petId"
          :items="petsSearch"
          :loading="loadingSearch"
          color="#3E682A"
          hide-no-data
          hide-details
          :rules="[rules.required]"
          :search-input.sync="search"
          label="Buscar por nome"
          item-text="nome"
          item-value="id"
        >
          <template v-slot:item="data">
            <v-list-item-content>
              <v-list-item-title v-html="data.item.nome"></v-list-item-title>
              <v-list-item-subtitle
                v-html="data.item.raca"
              ></v-list-item-subtitle>
            </v-list-item-content>
          </template>
        </v-autocomplete> -->
      </div>

      <div class="col-lg-6 form-group clearfix mb-3">
        <label>Unidade</label>
        <v-select
          style="padding-top: 0 !important"
          :items="unidades"
          v-model="unidadeId"
        />
      </div>

      <div class="col-lg-6 form-group clearfix mb-0">
        <label for="nome">Data Consulta</label>
        <v-text-field
          v-model="dataConsulta"
          placeholder="Data Consulta"
          :rules="[rules.required]"
          clear-icon="clear"
          style="margin: 0 5px; padding-top: 5px !important"
          :type="'date'"
          :outlined="false"
        />
        <span class="text-muted">ex: "Dia/Mês/Ano"</span>
      </div>

      <div class="col-lg-6 form-group clearfix mb-0">
        <label> Horários Disponíveis </label>
        <v-select
          style="padding-top: 0 !important"
          :items="horarios"
          v-model="horarioId"
        />
      </div>

      <div class="col-lg-6 form-group clearfix mb-0">
        <label> Procedimento </label>
        <v-select
          style="padding-top: 0 !important"
          :items="procedimentosList"
          v-model="procedimentoId"
        />
      </div>

      <v-row class="p-lg-3">
        <div class="mx-auto text-right">
          <button
            v-if="isConveniado"
            :disabled="!agendarIsAllowed"
            @click.prevent="agendarConsulta"
            class="btn btn-app-primary btn-rounded font-weight-bold"
          >
            <span v-if="!loading"> Agendar Consulta </span>
            <v-progress-circular
              v-else
              indeterminate
              color="white"
            ></v-progress-circular>
          </button>
          <v-dialog
            v-else
            persistent
            fullscreen
            v-model="dialogPagamento"
            max-width="500px"
          >
            <template v-slot:activator="{ attrs }">
              <v-btn
                color="#3E682A"
                dark
                class="mb-2"
                v-bind="attrs"
                @click="showModalPagamento"
              >
                Efetuar Pagamento
              </v-btn>
            </template>
            <modal-pagamento
              @close="closeDialogCadastrar"
              @response="responsePagamentoEfetuado"
            />
          </v-dialog>
        </div>
      </v-row>
    </form>
  </div>
  <div v-else>
    <v-progress-circular
      width="3"
      size="80"
      indeterminate
      color="green"
    ></v-progress-circular>
  </div>
</template>

<script>
import Mixin from "@/mixins/vuex_snackbar_mixin.js";
import VuexUsuarioMixin from "@/mixins/vuex_usuario_mixin.js";
import ConvenioService from "@/services/convenio_service.js";
import PetsService from "../services/pets_service.js";
import ModalPagamento from "@/components/agendar_consulta/modal_pagamento";
import AgendaService from "../services/agenda_service.js";
import UnidadeService from "../services/unidade_service.js";
import AgendamentoService from "../services/agendamento_service.js";

export default {
  mixins: [Mixin, VuexUsuarioMixin],
  components: {
    ModalPagamento,
  },
  data: () => ({
    rules: {
      required: (value) => !!value || "Campo obrigatório.",
    },
    loadingSearch: false,
    loading: false,
    loadingInicial: false,
    horarios: [],
    horarioId: 0,
    procedimentoId: 0,
    procedimentosList: [],
    pets: [],
    unidades: [],
    unidadeId: "",
    dataConsulta: "",
    dialogPagamento: false,
    isConveniado: true,
    petsSearch: [],
    search: "",
    petId: 0,
    petService: PetsService.build(),
    agendaService: AgendaService.build(),
    unidadeService: UnidadeService.build(),
    convenioService: ConvenioService.build(),
    agendamentoService: AgendamentoService.build(),
  }),
  watch: {
    async search(nome) {
      if (!nome) {
        this.petsSearch = [];
      }
      if (
        !nome ||
        !(nome = nome.trim()) ||
        nome.length < 1 ||
        this.loadingSearch
      ) {
        return;
      }
      this.petsSearch = [];
      this.petsSearch = this.pets.filter((pet) => {
        return pet.nome.toLowerCase().includes(nome.toLowerCase());
      });
    },
    unidadeId(unidadeId) {
      if (unidadeId && this.dataConsulta != "") {
        this.atualizarDataConsulta(this.dataConsulta);
      }
    },
    dataConsulta(data) {
      if (this.unidadeId && this.dataConsulta != "") {
        this.atualizarDataConsulta(data);
      }
    },
  },
  mounted() {
    this.loadingInicial = true;
    this.iniciar().finally(() => {
      this.loadingInicial = false;
    });
  },
  computed: {
    agendarIsAllowed() {
      return (
        this.unidadeId &&
        this.dataConsulta &&
        this.horarioId &&
        this.petId &&
        this.procedimentoId
      );
    },
  },
  methods: {
    async iniciar() {
      this.setPets()
      Promise.all([
        this.setUnidades(),
        this.setProcedimentos(),
      ]);
    },
    setPets() {
      this.petService.getPetsByCliente().then(async (response) => {
        if (response.status !== 200) return;
        const { data } = await response.json();
        const { pets } = data;
        this.pets = pets.map((pet) => {
          return {
            text: pet.nome_pet,
            value: pet.id_pet,
          };
        });
      });
    },
    async setUnidades() {
      await this.unidadeService.getUnidadeList().then(async (unidades) => {
        this.unidades = unidades.map((unidade) => {
          return {
            text: unidade.nomeFantasia,
            value: unidade.unidadeId,
          };
        });
      });
    },

    async setProcedimentos() {
      await this.convenioService
        .getProcedimentos()
        .then(async (response) => {
          const { data } = await response.json();
          this.procedimentosList = data.procedimentos.map((item) => {
            return {
              text: item.descricao,
              value: item.id,
            };
          });
        })
        .catch((error) => {
          console.error(error);
        });
    },
    showModalPagamento() {
      if (
        !this.isConveniado &&
        this.petId &&
        this.dia &&
        this.mes &&
        this.ano
      ) {
        this.dialogPagamento = true;
      }
    },
    async agendarConsulta() {
      this.loading = true;
      const data = {
        id_paciente: this.$_GETTERS_usuario.dados.pacienteId,
        id_procedimento: this.procedimentoId,
        id_cliente: this.$_GETTERS_usuario.dados.clienteId,
        id_agenda: this.horarioId,
        id_pet: this.petId,
      };
      await this.agendamentoService
        .agendarConsulta(data)
        .then(async (response) => {
          console.log(response);
          if (response.status !== 200) {
            this.$_ACTIONS_showSnackbarMessage({
              message: "Erro ao agendar consulta",
              color: "error",
            });
            return;
          } else {
            this.$_ACTIONS_showSnackbarMessage({
              message: "Consulta agendada com sucesso",
              color: "sucess",
            });
            this.resetForm();
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    resetForm() {
      this.unidadeId = 0;
      this.dataConsulta = "";
      this.horarioId = 0;
      this.procedimentoId = 0;
      this.petId = 0;
      this.search = "";
    },
    efetuarPagamento() {},
    closeDialogCadastrar() {
      this.dialogPagamento = false;
    },
    responsePagamentoEfetuado() {},
    async atualizarDataConsulta(data) {
      await this.agendaService
        .getAgendamentoByDia(data, this.unidadeId)
        .then(async (response) => {
          if (response.status !== 200) {
            this.$_ACTIONS_showSnackbarMessage({
              message: "Não foi possível carregar os horários",
              color: "error",
            });
            return;
          }
          const { data } = await response.json();
          this.horarios = data.map((horario) => {
            return {
              text: horario.hora_inicio + " - " + horario.hora_final,
              value: horario.agenda_id,
            };
          });
        });
    },
  },
};
</script>
