import HTTPService from '@/services/http_service.js'

export default class AdendoService extends HTTPService {
    resource = 'adendo'

    criarAdendo (responseFunctions, req) {
        this.request(responseFunctions, 'POST', this.resource, req)
    }

    getList (responseFunctions, id) {
        this.request(responseFunctions, 'GET', `${this.resource}/${id}`, {})
    }
} 
