import HTTPService from "@/services/http_service.js";

export default class FotosService extends HTTPService {
  resource = "arquivo";
  register(responseFunctions, arquivo) {
    this.fileRequest(responseFunctions, "POST", this.resource, arquivo);
  }
  registerExame(responseFunctions, arquivo) {
    this.fileRequest(
      responseFunctions,
      "POST",
      this.resource + "-exame",
      arquivo
    );
  }
  buscar_arquivos(
    responseFunctions,
    idPaciente,
    idPet,
    page,
    per_page,
    filter,
    order,
    desc
  ) {
    let queryparams = {
      paciente: idPaciente,
      pet: idPet,
      page: page,
      per_page: per_page,
    };
    if (filter === "") {
      if (order) {
        let queryparams = {
          paciente: idPaciente,
          pet: idPet,
          page: page,
          per_page: per_page,
          sort: desc ? "descricao" : "-descricao",
        };
        this.request(
          responseFunctions,
          "GET",
          this.resource,
          null,
          queryparams
        );
      } else {
        this.request(
          responseFunctions,
          "GET",
          this.resource,
          null,
          queryparams
        );
      }
    } else {
      let queryparams = {
        paciente: idPaciente,
        pet: idPet,
        page: page,
        per_page: per_page,
        descricao: filter,
      };
      if (order) {
        let queryparams = {
          paciente: idPaciente,
          pet: idPet,
          page: page,
          per_page: per_page,
          descricao: filter,
          sort: desc ? "descricao" : "-descricao",
        };
        this.request(
          responseFunctions,
          "GET",
          this.resource,
          null,
          queryparams
        );
      } else {
        this.request(
          responseFunctions,
          "GET",
          this.resource,
          null,
          queryparams
        );
      }
    }
  }
  buscar_arquivos_exames(
    responseFunctions,
    idPaciente,
    idPet,
    page,
    per_page,
    filter,
    order,
    desc
  ) {
    let queryparams = {
      paciente: idPaciente,
      pet: idPet,
      page: page,
      per_page: per_page,
    };
    if (filter === "") {
      if (order) {
        let queryparams = {
          paciente: idPaciente,
          pet: idPet,
          page: page,
          per_page: per_page,
          sort: desc ? "descricao" : "-descricao",
        };
        this.request(
          responseFunctions,
          "GET",
          this.resource + "-exame",
          null,
          queryparams
        );
      } else {
        this.request(
          responseFunctions,
          "GET",
          this.resource + "-exame",
          null,
          queryparams
        );
      }
    } else {
      let queryparams = {
        paciente: idPaciente,
        pet: idPet,
        page: page,
        per_page: per_page,
        descricao: filter,
      };
      if (order) {
        let queryparams = {
          paciente: idPaciente,
          pet: idPet,
          page: page,
          per_page: per_page,
          descricao: filter,
          sort: desc ? "descricao" : "-descricao",
        };
        this.request(
          responseFunctions,
          "GET",
          this.resource + "-exame",
          null,
          queryparams
        );
      } else {
        this.request(
          responseFunctions,
          "GET",
          this.resource + "-exame",
          null,
          queryparams
        );
      }
    }
  }

  ver_arquivo(responseFunctions, idArquivo) {
    this.request(
      responseFunctions,
      "GET",
      this.resource + "/" + idArquivo,
      null
    );
  }

  ver_arquivo_exame(responseFunctions, idArquivo) {
    this.request(
      responseFunctions,
      "GET",
      this.resource + "-exame" + "/" + idArquivo,
      null
    );
  }

  deletar_arquivo(responseFunctions, idArquivo) {
    this.request(
      responseFunctions,
      "POST",
      this.resource + "/" + idArquivo + "/deletar",
      { arquivo: idArquivo }
    );
  }

  deletar_arquivo_exame(responseFunctions, idArquivo) {
    this.request(
      responseFunctions,
      "POST",
      this.resource + "-exame" + "/" + idArquivo + "/deletar",
      { arquivo: idArquivo }
    );
  }
}
