<template>
  <v-card>
    <v-card-title>
      <span class="headline">Cadastro de perfil</span>
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-row>
          <v-col cols="12">
            <v-text-field v-model="descricao" label="Descrição"></v-text-field>
          </v-col>
<!--          <v-col cols="12">-->
<!--            <v-select-->
<!--                v-model="permissoes"-->
<!--                :items="endpoints"-->
<!--                label="Funcionalidades"-->
<!--                multiple-->
<!--                chips-->
<!--                persistent-hint-->
<!--            />-->
<!--          </v-col>-->
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="#3E682A" text @click="$emit('close')">
        Cancelar
      </v-btn>
      <v-btn color="#3E682A" :loading="loading" text @click="cadastrar" :disabled="!descricao">
          Salvar
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import Mixin from "@/mixins/vuex_mixin.js";
import PerfilService from "../../services/perfil_service";
import endpoints from "../../router/endpoints";

export default {
  mixins:[Mixin],
  data: () => ({
    descricao: '',
    permissoes: [],
    loading: false,
    services: {
      perfilService: PerfilService.build()
    },
  }),
  computed: {
    endpoints() {
      const e = Object.keys(endpoints).map(k => ({
        value: endpoints[k],
        text: k,
        divider: false
      }))
      console.log({e});
      return e;
    }
  },
  methods: {
    cadastrar() {
      const perfil = {
        descricao: this.descricao,
        permissoes: [
            endpoints["UsuarioController::POST->forgetedPassword"],
            endpoints["UsuarioController::GET->getUsuarioByUsuarioID/$1"],
            endpoints["UsuarioController::POST->changePassword/$1"],
            endpoints["UsuarioController::POST->update/$1"]
        ]
      }
      this.loading = true
      this.services.perfilService.cadastrarPerfil(perfil)
        .then(async ({status}) => {
          this.$_ACTIONS_showSnackbarMessage({
            message: status === 200 ? 'O perfil foi criado com sucesso.' : this.$global.messages.internalServerError,
            color: status === 200 ? 'sucess' : 'error'
          })
          status === 200 && this.$emit('response', perfil)
        })
        .catch(e => console.error({e}))
        .finally(() => this.loading = false)
    },
  },
}
</script>
