<template>
  <v-card>
    <v-card-title>
      <span class="headline">Editar convênio</span>
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-row>
          <div class="col-lg-12 form-group clearfix mb-3">
            <v-text-field v-model="descricao" label="Descrição"></v-text-field>
          </div>

          <div class="col-lg-12 form-group clearfix mb-3">
            <v-text-field v-model="codigo" label="Código"></v-text-field>
          </div>

          <div class="col-lg-12 form-group clearfix mb-3">
            <label> Tipo de Convênio </label>
            <v-select
              style="padding-top: 0 !important"
              :items="tipoConvenioList"
              v-model="tipoConvenio"
            />
          </div>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-data-table
              :items-per-page="100"
              :headers="headers"
              :items="procedimentosList"
              item-key="endpointId"
              class="elevation-1 width-lg"
              hide-default-footer
            >
              <template v-slot:item.ativo="{ item }">
                <v-chip
                  :color="item.ativo ? 'green' : 'red'"
                  @click="tooglePermission(item)"
                  dark
                >
                  {{ item.ativo ? "Sim" : "Não" }}
                </v-chip>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn text @click="$emit('close')"> Cancelar </v-btn>
      <v-btn
        class="btn-app-primary"
        style="color: #fff !important"
        :loading="loading"
        text
        @click="edit"
        :disabled="!allowEdition"
      >
        Salvar
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import ConvenioService from "@/services/convenio_service.js";
import Mixin from "@/mixins/vuex_snackbar_mixin.js";
import DadosCadastraisMixin from "@/mixins/dados_cadastrais_mixin.js";

export default {
  mixins: [Mixin, DadosCadastraisMixin],

  data: () => ({
    singleSelect: false,
    loading: false,
    formCadastro: "",
    descricao: "",
    tipoConvenio: "",
    codigo: "",
    procedimentos: [],
    procedimentosList: [],
    tipoConvenioList: [],
    convenioId: 0,
    convenioService: ConvenioService.build(),
    rules: {
      required: (value) => !!value || "Campo obrigatório.",
    },
    headers: [
      { text: "Descrição", align: "start", sortable: true, value: "descricao" },
      { text: "Permitido", align: "start", sortable: true, value: "ativo" },
    ],
  }),
  props: {
    convenio: {
      type: Object,
      required: true,
    },
  },
  beforeCreate() {},
  async mounted() {
    this.loading = true;
    await Promise.all([
      this.setProcedimentosConvenio(),
      this.setProcedimentos(),
      this.setTipoConvenio(),
    ]).finally(() => {
      this.loading = false;
    });
    this.renderLoading = true;
    this.formCadastro = document.forms[0];
    this.convenioId = this.convenio.id;
    this.descricao = this.convenio.descricao;
    this.codigo = this.convenio.codigo;
    this.renderLoading = false;
  },
  methods: {
    async edit() {
      this.loading = true;
      try {
        const convenioRequest = {
          descricao: this.descricao,
          id_tipo_convenio: this.tipoConvenio,
          codigo: this.codigo,
          procedimentos: this.getProcedimentosAtivos(),
          unidade_id: this.$_GETTER_Unidade.id,
        };
        const response = await this.convenioService.editarConvenio(
          this.convenioId,
          convenioRequest
        );
        this.$_ACTIONS_showSnackbarMessage({
          message:
            response.status === 200
              ? "O convênio foi atualizado com sucesso."
              : this.$global.messages.internalServerError,
          color: response.status === 200 ? "sucess" : "error",
        });
        response.status === 200 && this.$emit("response", this.convenio);
      } catch (e) {
        console.error({ e });
      }
      this.loading = false;
    },
    getProcedimentosAtivos() {
      return this.procedimentosList
        .filter((item) => item.ativo)
        .map((item) => item.id);
    },
    async setTipoConvenio() {
      await this.convenioService
        .getTipoConvenio()
        .then(async (response) => {
          const { data } = await response.json();
          this.tipoConvenio = data.find(
            (tipoConvenio) => tipoConvenio.descricao === this.convenio.tipo
          ).id;
          this.tipoConvenioList = data.map((item) => {
            return {
              text: item.descricao,
              value: item.id,
            };
          });
        })
        .catch((error) => {
          console.error(error);
        });
    },
    async setProcedimentos() {
      await this.convenioService
        .getProcedimentos()
        .then(async (response) => {
          const { data } = await response.json();
          console.log(data);
          this.procedimentosList = data.procedimentos.map((item) => {
            console.log(
              this.procedimentosConvenio.filter(
                (procedimento) => procedimento.id == item.id
              ).length > 0
            );
            return {
              descricao: item.descricao,
              id: item.id,
              ativo:
                this.procedimentosConvenio.filter(
                  (procedimento) => procedimento.id == item.id
                ).length > 0,
            };
          });
        })
        .catch((error) => {
          console.error(error);
        });
    },
    async setProcedimentosConvenio() {
      await this.convenioService
        .getProcedimentosPorUnidadeConvenio(
          this.$_GETTER_Unidade.id,
          this.convenio.id
        )
        .then(async (response) => {
          const { data } = await response.json();
          this.procedimentosConvenio = data.map((item) => {
            return {
              descricao: item.procedimento_descricao,
              id: item.procedimento_id,
            };
          });
        })
        .catch((error) => {
          console.error(error);
        });
    },
    tooglePermission(procedimento) {
      const procedimentoToEdit = this.procedimentosList.find(
        ({ id }) => procedimento.id === id
      );
      procedimentoToEdit.ativo = !procedimentoToEdit.ativo;
    },
  },
  computed: {
    allowEdition() {
      return true;
    },
  },
};
</script>
