<template>
  <v-card>
    <span v-if="renderLoading">
      <v-card-title>
        <span class="headline">Edição de perfil</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-progress-circular size="50" indeterminate color="primary" />
        </v-container>
      </v-card-text>
    </span>

    <div v-else>
      <v-toolbar style="background-color: #1daf80; color: #fff">
        <v-btn icon dark @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>
          <span class="headline" v-if="visualizar">Visualizar Pet</span>
          <span class="headline" v-else>Editar Pet</span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items> </v-toolbar-items>
      </v-toolbar>
      <div class="p-5">
        <form class="p-lg-3 row">
          <!-- <div class="col-lg-12">
            <div class="mx-auto my-4">
              <h3>Adicionar foto do pet</h3>
              <picture-input
                buttonClass="btn btn-app-primary btn-round font-weight-bold"
                removeButtonClass="btn btn-danger btn-round font-weight-bold"
                ref="pictureInput"
                :prefill="image"
                @change="trocarImagem"
                width="400"
                height="400"
                margin="16"
                accept="image/jpeg,image/png,image/bmp"
                :prefillOptions="{
                  fileType: 'png',
                }"
                size="10"
                :removable="true"
                :customStrings="{
                  upload:
                    '<p> Seu dispositivo não oferece suporte para upload de arquivos. </p> ',
                  drag: '<h3>Arraste uma imagem ou <br> clique aqui para selecionar um arquivo.</h3>',
                  tap: 'Toque aqui para selecionar uma imagem <br> de sua galeria.',
                  change: 'Trocar imagem',
                  remove: 'Remover imagem',
                  select: 'Selecione uma imagem',
                  selected: '<p>Foto selecionada com sucesso!</p>',
                  fileSize: 'O tamanho do arquivo excede o limite',
                  fileType: 'Este tipo de arquivo não é compatível.',
                }"
              >
              </picture-input>
            </div>
          </div> -->
          <div class="col-lg-4 form-group clearfix mb-3">
            <label for="nome">Nome</label>
            <v-text-field
              :disabled="visualizar"
              v-model="petNome"
              placeholder="Seu nome completo"
              style="padding-top: 0 !important"
            ></v-text-field>
          </div>

          <div class="col-lg-4 form-group clearfix mb-3">
            <label for="nome">RGA Pedigree</label>
            <v-text-field
              :disabled="visualizar"
              v-model="rgaPedigree"
              placeholder="RGA Pedigree"
              style="padding-top: 0 !important"
            ></v-text-field>
          </div>

          <div class="col-lg-4 form-group clearfix mb-3">
            <label>Espécie</label>
            <v-select
              style="padding-top: 0 !important"
              :disabled="visualizar"
              :items="especies"
              v-model="especieId"
            />
          </div>

          <div class="col-lg-3 form-group clearfix mb-3">
            <label>Raça</label>
            <v-select
              style="padding-top: 0 !important"
              v-if="!visualizar"
              :items="racas"
              v-model="idRaca"
            />
            <v-text-field
              v-else
              style="padding-top: 0 !important"
              disabled
              :value="nomeRaca"
            >
            </v-text-field>
          </div>

          <div class="col-lg-3 form-group clearfix mb-3">
            <label for="nome">Cor</label>
            <v-autocomplete
              v-model="cor"
              v-if="!visualizar"
              :rules="[rules.required]"
              :items="cores"
              label="Cor"
              style="padding-top: 0 !important"
            ></v-autocomplete>
            <v-text-field
              disabled
              :value="cor"
              style="padding-top: 0 !important"
              v-else
            ></v-text-field>
          </div>

          <div class="col-lg-3 form-group clearfix mb-3">
            <label for="nome">Número do Chip</label>
            <v-text-field
              v-model="numeroChip"
              :disabled="visualizar"
              placeholder="Número do Chip"
              style="padding-top: 0 !important"
            ></v-text-field>
          </div>

          <div class="col-lg-3 form-group clearfix mb-3">
            <label for="nome">Tutor Do Pet</label>
            <v-text-field
              disabled
              v-model="clienteNome"
              :rules="[rules.required]"
              placeholder="Tutor Do Pet"
              style="padding-top: 0 !important"
            ></v-text-field>
          </div>

          <div class="col-lg-3 form-group clearfix mb-3">
            <label for="nome">Data Nascimento</label>
            <v-text-field
              v-model="dataNascimento"
              placeholder="Data Nascimento"
              :disabled="visualizar"
              :rules="[rules.required]"
              clear-icon="clear"
              style="margin: 0 5px; padding-top: 5px !important"
              :type="'date'"
              :outlined="false"
            />
            <span class="text-muted">ex: "Dia/Mês/Ano"</span>
          </div>

          <div class="col-lg-3 form-group clearfix mb-3">
            <label for="nome">Idade</label>
            <v-text-field
              v-model="idade"
              clear-icon="clear"
              style="margin: 0 5px; padding-top: 5px !important"
              :outlined="false"
              :disabled="visualizar"
            />
          </div>

          <div class="col-lg-3 form-group clearfix mb-3">
            <label for="nome">Sexo</label>
            <v-radio-group
              :rules="[rules.required]"
              v-model="sexo"
              :disabled="visualizar"
            >
              <v-radio label="Masculino" value="M" />
              <v-radio label="Feminino" value="F" />
            </v-radio-group>
          </div>

          <div class="col-lg-3 form-group clearfix mb-3">
            <label for="nome">Castrado</label>
            <v-radio-group
              :rules="[rules.required]"
              v-model="castrado"
              :disabled="visualizar"
            >
              <v-radio label="Sim" value="true" />
              <v-radio label="Não" value="false" />
            </v-radio-group>
          </div>

          <div class="col-lg-3 form-group clearfix mb-3">
            <label for="nome">Porte</label>
            <v-radio-group
              :rules="[rules.required]"
              v-model="porte"
              :disabled="visualizar"
            >
              <v-radio label="Grande" value="GRANDE" />
              <v-radio label="Médio" value="MÉDIO" />
              <v-radio label="Pequeno" value="PEQUENO" />
            </v-radio-group>
          </div>

          <div class="col-lg-3 form-group clearfix mb-3">
            <label for="nome">Peso (Kg)</label>
            <v-text-field
              v-model="peso"
              clear-icon="clear"
              style="margin: 0 5px; padding-top: 5px !important"
              :outlined="false"
              :disabled="visualizar"
              placeholder="Peso"
            />
            <span>Kg</span>
          </div>

          <div class="col-lg-6 form-group clearfix mb-3">
            <label for="nome">Observações</label>
            <v-textarea
              maxlength="200"
              :disabled="visualizar"
              v-model="observacao"
              placeholder="Observações"
              outlined
              style="padding-top: 0 !important"
            ></v-textarea>
          </div>

          <div class="col-md-12 text-right">
            <button
              @click.prevent="salvarEdicao"
              v-if="!visualizar"
              class="btn btn-app-primary btn-rounded font-weight-bold"
            >
              <span v-if="!loading"> Salvar Edição </span>
              <v-progress-circular
                v-else
                indeterminate
                color="white"
              ></v-progress-circular>
            </button>
          </div>
        </form>
      </div>
    </div>
  </v-card>
</template>

<script>
import Mixin from "@/mixins/vuex_mixin.js";
import PetsService from "../../services/pets_service";
import EspecieService from "../../services/especie_service.js";

export default {
  mixins: [Mixin],
  data: () => ({
    singleSelect: false,
    loading: false,
    formCadastro: "",
    clienteNome: "",
    image: "",
    especies: [],
    especieId: 0,
    petNome: "",
    listaRacas: [],
    racas: [],
    observacao: "",
    porte: "",
    peso: null,
    cor: "",
    cores: [
      "BEGE",
      "PRETA",
      "BRANCA",
      "ABRICOT",
      "BRANCO E PRETO",
      "BRANCO E BEGE",
      "BRANCO E MARROM",
      "BRANCO E CINZA",
      "BRANCO E VERMELHO",
      "AZUL",
      "BLACK AND TAN",
      "VERMELHO",
      "CINZA",
      "PRETO,BRANCO E MARROM",
      "BOSTON",
      "ARLEQUIM",
      "TIGRADO",
      "AZUL RUÃO",
      "CARACTERISTICA",
      "TRICOLOR",
      "INDEFINIDA",
      "AMARELO",
      "PINTADO",
      "PELO DURO (ARAME)",
      "DOURADO",
      "MARROM",
      "FULVO",
      "VERMELHO E BRANCO",
      "PRETO E VERMELHO",
      "BRONZE",
      "SAL E PIMENTA",
      "CARVAO",
      "MARTA E BRANCO",
      "MANTO NEGRO",
      "PRETO E AMARELO",
      "PRETO E MARROM",
      "CHAMPANHE",
      "BRANCO",
      "PRETO",
      "PRETA E CINZA",
      "CARAMELO",
      "BRANCO E AMARELO",
      "PRETO E BEGE",
    ],
    numeroChip: "",
    dataNascimento: "",
    idade: "",
    sexo: "",
    castrado: 0,
    doado: 0,
    idCliente: 0,
    rgaPedigree: "",
    idPetStatus: 2,
    idRaca: 0,
    nomeRaca: "",
    renderLoading: false,
    itemsDataTable: [],
    rules: {
      required: (value) => !value & this.visualizar && "Campo obrigatório.",
    },
    petService: PetsService.build(),
    especieService: EspecieService.build(),
  }),
  props: {
    petId: {
      type: Number,
      required: true,
    },
    visualizar: Boolean,
  },
  watch: {
    dataNascimento() {
      this.idade = this.calcIdade;
    },
    async especieId() {
      await this.setListaRacas();
      console.log(this.racas);
    },
  },
  mounted() {
    this.renderLoading = true;
    this.formCadastro = document.forms[0];
    this.setEspecies();
    this.setPet(this.petId);
    this.setListaRacas();
    //this.idRaca = 9;
    this.renderLoading = false;
  },
  methods: {
    setPet(petId) {
      this.petService
        .getPetById(petId)
        .then((pet) => {
          this.mapPet(pet);
        })
        .then(() => {
          this.petService
            .getPetAge(this.dataNascimento.split("-").join(""))
            .then((resp) => (this.idade = resp.data[0].idade));
        });
    },
    mapPet(pet) {
      this.petNome = pet.nome_pet ?? "";
      this.clienteNome = pet.nome;
      this.rgaPedigree = pet.rga_pedigree;
      this.cor = pet.cor;
      this.observacao = pet.observacoes ?? "";
      this.porte = pet.porte ?? "";
      this.peso = pet.peso;
      this.sexo = pet.sexo;
      this.especieId = pet.id_especie;
      this.numeroChip = pet.numero_chip;
      this.idRaca = parseInt(pet.id_raca);
      this.nomeRaca = pet.nome_raca;
      this.castrado = pet.castrado === "1" ? "true" : "false";
      this.doado = pet.doado === "1" ? "true" : "false";
      this.dataNascimento = pet.data_nascimento;
      this.idCliente = pet.id_cliente;
    },
    tooglePermission(permissao) {
      const permissaoToEdit = this.pet.permissoes.find(
        ({ permissaoId }) => permissao.permissaoId === permissaoId
      );
      permissaoToEdit.ativo = !permissaoToEdit.ativo;
    },
    async setListaRacas() {
      await this.petService
        .getRacas()
        .then((response) => {
          this.listaRacas = response.data.racas;
          return this.listaRacas;
        })
        .then((resp) => {
          this.racas = resp
            .filter((raca) => raca.id_especie == this.especieId)
            .map((raca) => {
              return { value: raca.id, text: raca.nome };
            });
        });

      // let newListRacas = this.listaRacas.filter((r) => {
      //   if (r.id_especie == this.especieId) {
      //     return {
      //       value: r.id,
      //       text: r.nome,
      //     };
      //   }
      // });

      // this.listaRacas.map(({ id, nome }) => {
      //   this.racas.push({
      //     value: id,
      //     text: nome,
      //   });
      // });
    },
    async salvarEdicao() {
      this.loading = true;
      try {
        this.loading = true;
        const response = await this.petService.editarPet(
          this.petId,
          this.getFormData()
        );
        this.showFeedback(response.status);
        response.status === 200 && this.$emit("response", this.pet);
      } catch (e) {
        console.error({ e });
      }
      this.loading = false;
    },
    showFeedback(status) {
      this.$_ACTIONS_showSnackbarMessage({
        message:
          status === 200
            ? "O pet foi atualizado com sucesso."
            : this.$global.messages.internalServerError,
        color: status === 200 ? "sucess" : "error",
      });
    },
    async setEspecies() {
      await this.especieService
        .getEspeciesPaginatedList({
          page: null,
          per_page: null,
          sort: null,
          desc: null,
          search: null,
        })
        .then(async (response) => {
          if (response.status !== 200) return;
          const { data } = await response.json();
          const { especies } = data;
          this.especies = especies.map(({ id, nome }) => {
            return {
              value: id,
              text: nome,
            };
          });
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getFormData() {
      const formData = new FormData();
      formData.append("foto", this.image);
      formData.append("cor", this.cor);
      formData.append("nome", this.petNome);
      formData.append("numero_chip", this.numeroChip);
      formData.append("data_nascimento", this.dataNascimento);
      formData.append("sexo", this.sexo);
      formData.append("castrado", this.castrado);
      formData.append("doado", this.doado);
      formData.append("rga_pedigree", this.rgaPedigree);
      formData.append("id_cliente", this.idCliente);
      formData.append("porte", this.porte);
      formData.append("peso", parseFloat(this.peso));
      formData.append("observacoes", this.observacao);
      formData.append("id_pet_status", this.idPetStatus);
      formData.append("id_raca", this.idRaca);
      return formData;
    },
    trocarImagem() {
      if (this.$refs.pictureInput) {
        this.avatarPet = this.$refs.pictureInput.file;
        this.image = this.$refs.pictureInput.image;
      } else {
        console.log("API File Reader não suportada: use o <form>");
      }
    },
  },
  computed: {
    allowEdition() {
      return true;
    },
    calcIdade() {
      const dataAtual = new Date();
      const dataNascimentoPet = new Date(this.dataNascimento);
      // const dataNascimentoPet = new Date("9 1 2020");

      let anos = dataAtual.getFullYear() - dataNascimentoPet.getFullYear();
      let meses =
        anos * 12 + (dataAtual.getMonth() - dataNascimentoPet.getMonth());

      if (meses < 12) {
        return `${meses} meses`;
      } else {
        return `${anos} anos e ${meses % 12} meses`;
      }
    },
    // racas() {
    //   let newListRacas = this.listaRacas.filter((r) => {
    //     if (r.id == this.especieId) {
    //       return {
    //         value: r.id,
    //         text: r.nome,
    //       };
    //     }
    //   });
    //   return newListRacas.map(({ id, nome }) => {
    //     return {
    //       value: id,
    //       text: nome,
    //     };
    //   });
    // },
  },
};
</script>
