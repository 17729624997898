<template>
  <v-card>
    <v-toolbar style="background-color: #02004f; color: #fff">
      <v-btn icon dark @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>
        <span class="headline">Fechamento de Caixa</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items> </v-toolbar-items>
    </v-toolbar>
    <div class="px-8 py-6" v-if="!loadingDetalhe">
      <v-tabs v-model="tab">
        <v-tab href="#tab-resumo">
          Resumo
        </v-tab>
        <v-tab href="#tab-recebimentos" :disabled="checkLength('recebimentos')">
          Recebimentos
        </v-tab>
        <v-tab href="#tab-entradas" :disabled="checkLength('entradas')">
          Entradas
        </v-tab>
        <v-tab href="#tab-sangrias" :disabled="checkLength('sangrias')">
          Sangria
        </v-tab>
        <v-tab href="#tab-estornos" :disabled="checkLength('estornos')">
          Estornos
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item key="resumo" value="tab-resumo">
          <v-card>
            <div>
              <div class="px-3 row mt-3 md-0 justify-content-between">
                <div class="col-3 text-start">
                  <p class="text-capitalize md-0">
                    <strong>Usuario:</strong><br />
                    {{ getNomeUsuario() }}
                  </p>
                </div>
                <div class="col-3 text-start">
                  <p class="text-capitalize md-0">
                    <strong>Unidade:</strong><br />
                    {{ getUnidade() }}
                  </p>
                </div>
                <div class="col-3 text-start">
                  <p class="md-0">
                    <strong>Data Abertura:</strong><br />
                    {{ formatarData(caixa.data_abertura) }}
                  </p>
                </div>
                <div class="col-3 text-start">
                  <p class="md-0">
                    <strong>Hora Abertura:</strong><br />
                    {{ caixa.hora_abertura }}
                  </p>
                </div>
              </div>
              <div class="px-3 row mt-0 justify-content-between">
                <div
                  class="col-12 d-flex flex-wrap justify-content-center gap-custom"
                >
                  <v-sheet
                    v-for="itemResumo in resumoTotal"
                    :key="itemResumo.id"
                    color="gray"
                    elevation="3"
                    class="card-valor-style"
                  >
                    <div class="title-card">
                      {{ itemResumo.descricao }}
                    </div>
                    <div class="valor-card">
                      {{ itemResumo.valor }}
                    </div>
                  </v-sheet>
                </div>
              </div>
              <!-- <div class="px-3 row mt-0 justify-content-between">
                <div
                  class="col-md-4 text-left"
                  style="text-transform: capitalize;"
                >
                  <v-list two-line>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title
                          ><strong>Saldo Abertura</strong></v-list-item-title
                        >
                        <v-list-item-subtitle>
                          {{
                            formatarSaldo(this.caixaResumo.saldo_abertura)
                          }}</v-list-item-subtitle
                        >
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title
                          ><strong>Total Cobrado</strong></v-list-item-title
                        >
                        <v-list-item-subtitle>
                          {{
                            formatarSaldo(this.caixaResumo.total_cobrado)
                          }}</v-list-item-subtitle
                        >
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </div>
                <div
                  class="col-md-4 text-left"
                  style="text-transform: capitalize;"
                >
                  <v-list two-line>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title
                          ><strong>Sangrias</strong></v-list-item-title
                        >
                        <v-list-item-subtitle>
                          {{
                            formatarSaldo(this.caixaResumo.sangrias)
                          }}</v-list-item-subtitle
                        >
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title
                          ><strong>Total Recebido</strong></v-list-item-title
                        >
                        <v-list-item-subtitle>
                          {{
                            formatarSaldo(this.caixaResumo.total_recebido)
                          }}</v-list-item-subtitle
                        >
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </div>
                <div
                  class="col-md-4 text-left"
                  style="text-transform: capitalize;"
                >
                  <v-list two-line>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title
                          ><strong>Entradas</strong></v-list-item-title
                        >
                        <v-list-item-subtitle>
                          {{
                            formatarSaldo(this.caixaResumo.entradas)
                          }}</v-list-item-subtitle
                        >
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title
                          ><strong>Total Troco</strong></v-list-item-title
                        >
                        <v-list-item-subtitle>
                          {{
                            formatarSaldo(this.caixaResumo.total_troco)
                          }}</v-list-item-subtitle
                        >
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </div>
              </div>
              <div class="px-3 my-0 row justify-content-center">
                <div class="col-md-4 text-start">
                  <v-list two-line>
                    <v-list-item>
                      <v-list-item-content>
                        <v-list-item-title
                          ><strong>Saldo</strong></v-list-item-title
                        >
                        <v-list-item-subtitle>
                          {{
                            formatarSaldo(this.caixaResumo.saldo)
                          }}</v-list-item-subtitle
                        >
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </div>
              </div> -->
            </div>
            <div class="row justify-content-center mt-10">
              <div class="col-lg-4 form-group clearfix mb-3">
                <v-text-field
                  v-model="valorCaixa"
                  v-money="moneyConfig"
                  @keydown="verificarTecla($event)"
                  type="text"
                  step="0.01"
                  min="0"
                  label="Valor Fechamento Informado"
                  style="padding-top: 0 !important"
                ></v-text-field>
              </div>
              <div class="col-4  text-right ">
                <v-btn dark color="#02004f" @click.prevent="fecharCaixa">
                  <span v-if="!loading"> Fechar Caixa </span>
                  <v-progress-circular
                    v-else
                    indeterminate
                    color="white"
                  ></v-progress-circular>
                </v-btn>
              </div>
            </div>
          </v-card>
        </v-tab-item>
        <v-tab-item key="recebimentos" value="tab-recebimentos">
          <v-card flat>
            <v-card-text>
              <div
                class="col-12 d-flex flex-wrap justify-content-center gap-custom"
                v-if="!checkLength('recebimentos')"
              >
                <v-sheet
                  v-for="itemResumo in resumoRecebimento"
                  :key="itemResumo.id"
                  color="gray"
                  elevation="3"
                  class="card-valor-style"
                >
                  <div class="title-card">
                    {{ itemResumo.descricao }}
                  </div>
                  <div class="valor-card">
                    {{ itemResumo.valor }}
                  </div>
                </v-sheet>
              </div>
              <TableFechamento
                :itens="caixaDetalhe.recebimentos"
                :tipoTable="tipoTable('recebimento')"
                :tipo="'recebimento'"
              />
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item key="Entradas" value="tab-entradas">
          <v-card flat>
            <v-card-text>
              <TableFechamento
                :itens="caixaDetalhe.entradas"
                :tipoTable="tipoTable('entrada')"
                :tipo="'entrada'"
            /></v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item key="Sangrias" value="tab-sangrias">
          <v-card flat>
            <v-card-text>
              <TableFechamento
                :itens="caixaDetalhe.sangrias"
                :tipoTable="tipoTable('sangria')"
                :tipo="'sangria'"
            /></v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item key="Estornos" value="tab-estornos">
          <v-card flat>
            <v-card-text>
              <div
                class="col-12 d-flex flex-wrap justify-content-center gap-custom"
                v-if="!checkLength('estornos')"
              >
                <v-sheet
                  v-for="itemResumo in resumoEstorno"
                  :key="itemResumo.id"
                  color="gray"
                  elevation="3"
                  class="card-valor-style"
                >
                  <div class="title-card">
                    {{ itemResumo.descricao }}
                  </div>
                  <div class="valor-card">
                    {{ itemResumo.valor }}
                  </div>
                </v-sheet>
              </div>
              <TableFechamento
                :itens="caixaDetalhe.estornos"
                :tipoTable="tipoTable('estorno')"
                :tipo="'estorno'"
            /></v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </div>
    <div class="d-flex justify-content-center p-4" v-else>
      <v-progress-circular indeterminate color="#02004f"></v-progress-circular>
    </div>
  </v-card>
</template>

<script>
import Mixin from "@/mixins/vuex_mixin.js";
import CaixaService from "../../services/caixa_service";
import TableFechamento from "@/components/caixa/table-fechamento-caixa.vue";
import { VMoney } from "v-money";

export default {
  mixins: [Mixin],
  props: {
    caixa: {
      type: Object,
      required: true,
    },
  },
  components: { TableFechamento },
  directives: { money: VMoney },
  data: () => ({
    moneyConfig: {
      decimal: ",",
      thousands: ".",
      prefix: "R$ ",
      suffix: "",
      precision: 2,
      masked: true,
    },
    tab: null,
    valorCaixa: 0.0,
    caixaAtual: 5000,
    caixaResumo: "",
    caixaDetalhe: {},
    resumoTotal: [],
    resumoRecebimento: [],
    resumoEstorno: [],
    loading: false,
    loadingDetalhe: true,
    services: {
      caixaService: CaixaService.build(),
    },
    unidadeId: ~~JSON.parse(sessionStorage.vuex).unidade.id,
    unidade: ~~JSON.parse(sessionStorage.vuex).unidade.razap_social,
    usuarioId: ~~JSON.parse(sessionStorage.vuex).usuario.id,
    usuario: ~~JSON.parse(sessionStorage.vuex).usuario.dados.nome,
  }),
  mounted() {
    this.detalheCaixa();
  },
  watch: {},
  methods: {
    getNomeUsuario() {
      return JSON.parse(
        sessionStorage.getItem("vuex")
      ).usuario.dados.nome.toLowerCase();
    },
    getUnidade() {
      return JSON.parse(
        sessionStorage.getItem("vuex")
      ).unidade.nome.toLowerCase();
    },
    verificarTecla(event) {
      if (
        ((event.keyCode >= 65 && event.keyCode <= 90) ||
          (event.keyCode >= 97 && event.keyCode <= 122)) &&
        ![96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 110, 188, 190].includes(
          event.keyCode
        )
      ) {
        event.preventDefault();
      }
    },
    formatarData(data) {
      if (data) {
        return data
          .split("-")
          .reverse()
          .join("/");
      } else {
        return "-";
      }
    },
    formatarSaldo(saldo) {
      if (saldo) {
        saldo = saldo.replaceAll(",", "");
        return new Intl.NumberFormat("pt-BR", {
          style: "currency",
          currency: "BRL",
        }).format(saldo);
      } else {
        return "-";
      }
    },
    detalheCaixa() {
      this.loadingDetalhe = true;
      this.services.caixaService
        .getCaixaResumo(
          this.caixa.id_usuario,
          this.caixa.id_unidade,
          this.caixa.id
        )
        .then(async (resp) => {
          const response = await resp.json();
          if (resp.status == 200) {
            this.caixaResumo = response.data.resumo;
            this.valorCaixa = parseFloat(
              this.caixaResumo.saldo.replaceAll(",", "")
            ).toFixed(2);
            this.formatarCaixaDetalhe(response.data.detalhe);
            this.formatarResumo();
          } else if (resp.status == 206) {
            this.checkCaixa();
            console.error(response);
          } else {
            console.error(response);
          }
          this.loadingDetalhe = false;
        })
        .catch((error) => {
          this.loadingDetalhe = false;
          console.error(error);
        })
        .finally(() => {
          this.loadingDetalhe = false;
        });
    },
    checkCaixa() {
      this.services.caixaService
        .checkCaixaByUser(this.caixa.id_usuario, this.caixa.id_unidade)
        .then(async (resp) => {
          const response = await resp.json();
          if (resp.status == 200) {
            this.caixaResumo = {
              saldo_abertura: response.data.saldo_abertura,
              total_cobrado: null,
              sangrias: null,
              total_recebido: null,
              entradas: null,
              total_troco: null,
              saldo: response.data.saldo_abertura,
            };
            this.formatarCaixaDetalhe({});
            this.valorCaixa = parseFloat(
              this.caixaResumo.saldo.replaceAll(",", "")
            ).toFixed(2);
          } else if (resp.status == 206) {
            this.$emit("close");
          }
        })
        .catch((error) => {
          this.loadingDetalhe = false;
          console.error(error);
        })
        .finally(() => {
          this.loadingDetalhe = false;
        });
    },
    fecharCaixa() {
      this.loading = true;
      let body = {
        unidade: this.caixa.id_unidade,
        caixa: this.caixa.id,
        saldo_informado: this.formatValor(this.valorCaixa),
      };
      this.services.caixaService
        .fecharCaixaByUser(body)
        .then(async (resp) => {
          const response = await resp.json();
          this.loading = false;
          this.mostrarFeedback(resp.status, response);
          if (resp.status == 200) {
            this.$emit("response", this.caixa);
            this.$emit("close");
          }
        })
        .catch((error) => {
          this.loading = false;
          console.error(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    mostrarFeedback(status, response) {
      this.$_ACTIONS_showSnackbarMessage({
        message:
          status == 200
            ? "Fechamento realizado com sucesso!"
            : response.message,
        color: status == 200 ? "sucess" : "error",
      });
    },
    resetForm() {
      this.valorCaixa = "";
    },
    getFormData() {
      return {
        valorCaixa: this.valorCaixa,
      };
    },
    formatarCaixaDetalhe(detalhes) {
      const detalhesKey = ["recebimentos", "entradas", "sangrias", "estornos"];
      for (let indexKeys = 0; indexKeys < detalhesKey.length; indexKeys++) {
        let itens = [];
        const elementKey = detalhesKey[indexKeys];
        if (detalhes[elementKey]) {
          for (
            let indexDetalhes = 0;
            indexDetalhes < detalhes[elementKey].length;
            indexDetalhes++
          ) {
            const elementDetalhes = detalhes[elementKey][indexDetalhes];
            let newItem = {
              ...elementDetalhes,
              valor_formatado: this.formatarSaldo(elementDetalhes.valor),
              data_movimento_formatado: this.formatarData(
                elementDetalhes.data_movimento
              ),
            };
            itens.push(newItem);
          }
        }
        this.caixaDetalhe[elementKey] = itens;
      }
    },
    formatarResumo() {
      const itensResumo = [
        { cat: "total", id: "saldo_abertura", descricao: "Saldo Abertura" },
        { cat: "total", id: "entradas", descricao: "Total Entradas" },
        { cat: "total", id: "sangrias", descricao: "Total Sangrias" },
        { cat: "total", id: "recebimentos", descricao: " Total Recebimentos" },
        { cat: "total", id: "estornos_total", descricao: "Total Estornos" },
        { cat: "total", id: "saldo", descricao: "Saldo Dinheiro" },
        {
          cat: "recebimento",
          id: "recebimentos_dinheiro",
          descricao: "Dinheiro",
        },
        {
          cat: "recebimento",
          id: "recebimento_credito_cliente",
          descricao: "Crédito Cliente",
        },
        {
          cat: "recebimento",
          id: "recebimento_credito",
          descricao: "Cartão Crédito",
        },
        {
          cat: "recebimento",
          id: "recebimento_debito",
          descricao: "Cartão Débito",
        },
        {
          cat: "recebimento",
          id: "recebimento_pix",
          descricao: "Pix",
        },
        // {
        //   cat: "recebimento",
        //   id: "recebimento_filantropia",
        //   descricao: "Filantropía",
        // },
        // {
        //   cat: "recebimento",
        //   id: "recebimento_antecipado",
        //   descricao: "Antecipado",
        // },
        // {
        //   cat: "recebimento",
        //   id: "recebimento_boleto",
        //   descricao: "Boleto",
        // },
        {
          cat: "estorno",
          id: "estornos_dinheiro",
          descricao: "Dinheiro",
        },
        {
          cat: "estorno",
          id: "estornos_credito_cliente",
          descricao: "Crédito Cliente",
        },
        {
          cat: "estorno",
          id: "estornos_cartao_credito",
          descricao: "Cartão Crédito",
        },
        {
          cat: "estorno",
          id: "estornos_cartao_debito",
          descricao: "Cartão Débito",
        },
        { cat: "estorno", id: "estornos_pix", descricao: "Pix" },
      ];
      for (let index = 0; index < itensResumo.length; index++) {
        const elementResumo = itensResumo[index];
        let item = {
          id: elementResumo.id,
          descricao: elementResumo.descricao,
          valor: this.formatarSaldo(this.caixaResumo[elementResumo.id]),
        };
        if (elementResumo.cat == "total") {
          this.resumoTotal.push(item);
        } else if (elementResumo.cat == "recebimento") {
          this.resumoRecebimento.push(item);
        } else {
          this.resumoEstorno.push(item);
        }
      }
    },
    tipoTable(tipo) {
      let header = [];
      switch (tipo) {
        case "recebimento":
          header = [
            {
              text: "Forma Pagamento",
              value: "forma_pagamento",
              sortable: false,
            },
            { text: "Maquineta", value: "maquineta", sortable: false },
            { text: "Cod. Aut.", value: "codauth", sortable: false },
            { text: "Valor", value: "valor_formatado", sortable: false },
            {
              text: "Data",
              value: "data_movimento_formatado",
              sortable: false,
            },
            { text: "Hora", value: "hora_movimento", sortable: false },
          ];
          break;
        case "entrada":
          header = [
            { text: "Valor", value: "valor_formatado", sortable: false },
            {
              text: "Data",
              value: "data_movimento_formatado",
              sortable: false,
            },
            { text: "Hora", value: "hora_movimento", sortable: false },
            { text: "", value: "observacao", sortable: false },
          ];
          break;
        case "sangria":
          header = [
            { text: "Valor", value: "valor_formatado", sortable: false },
            {
              text: "Data",
              value: "data_movimento_formatado",
              sortable: false,
            },
            { text: "Hora", value: "hora_movimento", sortable: false },
            { text: "", value: "observacao", sortable: false },
          ];
          break;
        case "estorno":
          header = [
            { text: "Descricao", value: "descricao", sortable: false },
            { text: "Valor", value: "valor_formatado", sortable: false },
            {
              text: "Data",
              value: "data_movimento_formatado",
              sortable: false,
            },
            { text: "Hora", value: "hora_movimento", sortable: false },
          ];
          break;
        default:
          header = [
            { text: "Valor", value: "valor_formatado", sortable: false },
            {
              text: "Data",
              value: "data_movimento_formatado",
              sortable: false,
            },
            { text: "Hora", value: "hora_movimento", sortable: false },
          ];
          break;
      }
      return header;
    },
    checkLength(tipo) {
      if (this.caixaDetalhe[tipo] && this.caixaDetalhe[tipo].length > 0) {
        return false;
      } else {
        return true;
      }
    },
    formatValor(valor) {
      if (typeof valor == "string") {
        const newValor = parseFloat(
          valor
            .replaceAll("R$", "")
            .replaceAll(".", "")
            .replaceAll(",", ".")
        ).toFixed(2);
        return newValor;
      } else {
        return valor;
      }
      // return parseFloat(valor).toFixed(2)
    },
  },
};
</script>
<style scoped>
.text-capitalize {
  text-transform: capitalize !important;
}
.gap-custom {
  gap: 10px;
}
.card-valor-style {
  height: 70px;
  width: 200px;
  /* margin-bottom: 10px; */
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  padding: 10px;
}
.title-card {
  font-size: 0.85rem;
  font-weight: bold;
  text-align: start;
}
.valor-card {
  font-size: 0.85rem;
}
</style>
