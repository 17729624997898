<template>
  <v-dialog
    v-model="dialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        small
        depressed
        color="warning"
        class="mb-3"
        v-bind="attrs"
        v-on="on"
      >
        Cadastrar Pet
      </v-btn>
    </template>

    <v-card>
      <v-toolbar dark style="background-color: #1daf80; color: #fff">
        <v-btn icon dark @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Cadastrar Pet</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items> </v-toolbar-items>
      </v-toolbar>

      <ConsultaPet />
    </v-card>
  </v-dialog>
</template>

<script>
import ConsultaPet from "@/views/pets";

export default {
  components: {
    ConsultaPet,
  },
  data() {
    return {
      dialog: false,
    };
  },
};
</script>
