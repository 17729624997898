<template>
  <div>
    <v-card>
      <v-toolbar class="bg-verde" dark>
        <v-btn icon dark @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>
          <span class="headline" v-if="visualizar">Visualizar unidade</span>
          <span class="headline" v-else>Editar unidade</span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items> </v-toolbar-items>
      </v-toolbar>

      <v-card-text>
        <v-container>
          <v-form class="p-lg-3 row" ref="form">
            <div class="col-lg-4 form-group clearfix mb-3">
              <label for="nome">Nome fantasia</label>
              <v-text-field
                :error-messages="error.nome_fantasia"
                v-model="unidade.nomeFantasia"
                :disabled="visualizar"
                :rules="[
                  validationService.required('Campo obrigatório.'),
                  validationService.isNotEmpty(),
                ]"
                :type="'text'"
                placeholder="Nome fantasia"
                style="padding-top: 0 !important"
              >
              </v-text-field>
            </div>

            <div class="col-lg-4 form-group clearfix mb-3">
              <label for="nome">Razão social</label>
              <v-text-field
                :error-messages="error.razaoSocial"
                v-model="unidade.razaoSocial"
                :disabled="visualizar"
                :rules="[
                  validationService.required(
                    'Campo Razão Social é obrigatório.'
                  ),
                  validationService.isNotEmpty(),
                ]"
                :type="'text'"
                placeholder="Razão Social"
                style="padding-top: 0 !important"
              >
              </v-text-field>
            </div>

            <div class="col-lg-4 form-group clearfix mb-3">
              <label>Email</label>
              <v-text-field
                :error-messages="error.email"
                v-model="unidade.email"
                :disabled="visualizar"
                :rules="[rules.email]"
                :type="'email'"
                placeholder="Email"
                style="padding-top: 0 !important"
              ></v-text-field>
            </div>

            <div class="col-lg-3 form-group clearfix mb-3">
              <label for="Telefone01">Telefone Celular:</label>
              <v-text-field
                v-mask="'(##)#####-####'"
                :error-messages="error.telefone1"
                v-model="unidade.telefone1"
                :disabled="visualizar"
                :type="'text'"
                :rules="[
                  validationService.required('Campo Telefone é obrigatório.'),
                  validationService.isNotEmpty(),
                ]"
                placeholder="(00) 00000-0000"
                style="padding-top: 0 !important"
              >
              </v-text-field>
            </div>

            <div class="col-lg-3 form-group clearfix mb-3">
              <label for="Telefone01">Telefone Fixo:</label>
              <v-text-field
                v-mask="'(##)####-####'"
                :error-messages="error.telefone2"
                v-model="unidade.telefone2"
                :disabled="visualizar"
                :type="'text'"
                placeholder="(00) 00000-0000"
                style="padding-top: 0 !important"
              >
              </v-text-field>
            </div>

            <div class="col-lg-3 form-group clearfix mb-3">
              <label for="CNPJ">CNPJ</label>
              <v-text-field
                v-mask="'##.###.###/####-##'"
                :error-messages="error.cnpj"
                v-model="unidade.cnpj"
                :disabled="visualizar"
                :type="'text'"
                :rules="[
                  validationService.required('Campo CNPJ é obrigatório.'),
                  validationService.isNotEmpty(),
                  validationService.isCNPJ(),
                ]"
                placeholder="00.000.000/0000-00"
                style="padding-top: 0 !important"
              >
              </v-text-field>
              <span class="text-muted">ex: "123.456.7890-12"</span>
            </div>
            <div class="col-lg-3 form-group clearfix mb-3">
              <label for="CEP">CEP</label>
              <v-text-field
                v-mask="'#####-###'"
                :error-messages="error.cep"
                v-model="unidade.cep"
                :disabled="visualizar"
                :type="'text'"
                :rules="[
                  validationService.required('Campo CEP é obrigatório.'),
                ]"
                placeholder="00000-000"
                style="padding-top: 0 !important"
              >
              </v-text-field>
              <span class="text-muted">ex: "xxxxx-xxx"</span>
            </div>

            <div class="col-lg-3 form-group clearfix mb-3">
              <label for="Rua">Logradouro</label>
              <v-text-field
                :error-messages="error.logradouro"
                v-model="unidade.logradouro"
                :disabled="visualizar"
                :rules="[
                  validationService.required('Campo Logradouro é obrigatório.'),
                ]"
                :type="'text'"
                style="padding-top: 0 !important"
              >
              </v-text-field>
            </div>

            <div class="col-lg-3 form-group clearfix mb-3">
              <label for="Bairro">Bairro</label>
              <v-text-field
                :error-messages="error.bairro"
                v-model="unidade.bairro"
                :disabled="visualizar"
                :rules="[
                  validationService.required('Campo Bairro é obrigatório.'),
                ]"
                :type="'text'"
                style="padding-top: 0 !important"
              >
              </v-text-field>
            </div>

            <div class="col-lg-3 form-group clearfix mb-3">
              <label for="Numero">Número</label>
              <v-text-field
                :error-messages="error.numero"
                v-model="unidade.numero"
                :disabled="visualizar"
                :type="'text'"
                :rules="[
                  validationService.required('Campo Número é obrigatório.'),
                ]"
                style="padding-top: 0 !important"
              >
              </v-text-field>
            </div>

            <div class="col-lg-3 form-group clearfix mb-3">
              <label for="Complemento">Complemento</label>
              <v-text-field
                :error-messages="error.complemento"
                v-model="unidade.complemento"
                :disabled="visualizar"
                :type="'text'"
                style="padding-top: 0 !important"
              >
              </v-text-field>
            </div>

            <div class="col-lg-3 form-group clearfix mb-3">
              <label for="Referencia">Referência</label>
              <v-text-field
                :error-messages="error.referencias"
                v-model="unidade.referencias"
                :disabled="visualizar"
                :type="'text'"
                style="padding-top: 0 !important"
              >
              </v-text-field>
            </div>

            <div class="col-lg-3 form-group clearfix mb-3">
              <label for="Cidade">Cidade</label>
              <v-text-field
                :error-messages="error.cidade"
                v-model="unidade.cidade"
                :disabled="visualizar"
                :rules="[
                  validationService.required('Campo Cidade é obrigatório.'),
                ]"
                :type="'text'"
                style="padding-top: 0 !important"
              ></v-text-field>
            </div>

            <div class="col-lg-3 form-group clearfix mb-3">
              <label for="Estado">Estado</label>
              <v-text-field
                :error-messages="error.estado"
                v-model="unidade.estado"
                :disabled="visualizar"
                :rules="[
                  validationService.required('Campo Estado é obrigatório.'),
                ]"
                :type="'text'"
                style="padding-top: 0 !important"
              >
              </v-text-field>
            </div>

            <div class="col-lg-3 form-group clearfix mb-3">
              <label>Servidor Chamefacil</label>

              <v-select
                :items="servidoresChamefacil"
                v-model="unidade.chamefacil"
                :disabled="visualizar"
                item-text="host"
                item-value="id"
                style="padding-top: 0 !important"
                :rules="[rules.required]"
                :error-messages="error.servidorChamefacil"
              ></v-select>
            </div>

            <div class="col-12 form-group">
              <button
                v-if="!visualizar"
                class="
                  btn btn-app-primary btn-flex btn-rounded
                  font-weight-bold
                  justify-content-end
                "
                @click.prevent="atualizarUnidade()"
              >
                <span v-if="!loading"> Salvar </span>
                <v-progress-circular
                  v-else
                  indeterminate
                  color="white"
                ></v-progress-circular>
              </button>
            </div>
          </v-form>
          <v-snackbar
            v-if="messageSnackbar"
            v-model="messageSnackbar"
            :color="typeMessageSnackbar"
            :timeout="-1"
          >
            {{ messageSnackbar }}
            <template v-slot:action="{ attrs }">
              <v-btn
                text
                :color="'white'"
                v-bind="attrs"
                @click="fecharSnackbar"
              >
                Ok
              </v-btn>
            </template>
          </v-snackbar>
        </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import ValidationService from "@/services/validation_service.js";
import CEPMixin from "@/mixins/cep_mixin.js";
import { mapActions } from "vuex";
import UnidadeService from "../../services/unidade_service";
import ServidoresChamefacilService from "../../services/servidores_chamefacil_service";

export default {
  mixins: [CEPMixin],
  props: {
    unidade: Object,
    unidadeId: Number,
    visualizar: Boolean,
  },
  async beforeMount() {
    this.validationService = ValidationService;
    await this.setServidoresChamfacil();
    console.log(this.unidade);
  },
  data() {
    return {
      loading: false,
      error: {
        nome_fantasia: "",
        razao_social: "",
        email: "",
        cnpj: "",
        telefone_1: "",
        telefone_2: "",
        cep: "",
        estado: "",
        cidade: "",
        bairro: "",
        logradouro: "",
        numero: "",
        complemento: "",
        referencias: "",
        validationService: {},
        servidorChamefacil: "",
      },
      rules: {
        required: (value) => !!value || "Campo obrigatório.",
        min: (v) => v.length >= 8 || "Minimo de 8 caracteres",
      },
      services: {
        unidadeService: UnidadeService.build(),
        servidoresChamefacilService: ServidoresChamefacilService.build(),
      },
      servidoresChamefacil: [],
    };
  },
  mounted() {
    this.formCadastro = document.forms[0];
    this.CEP_MIXIN_cep = this.unidade.cep;
  },
  watch: {
    unidade() {
      this.CEP_MIXIN_logradouro = this.unidade.logradouro;
      this.CEP_MIXIN_bairro = this.unidade.bairro;
      this.CEP_MIXIN_cidade = this.unidade.cidade;
      this.CEP_MIXIN_estado = this.unidade.estado;
      this.CEP_MIXIN_cep = this.unidade.cep;
    },
  },
  methods: {
    ...mapActions({
      $_ACTIONS_showSnackbarMessage: "showSnackbarMessage",
    }),
    async setServidoresChamfacil() {
      await this.services.servidoresChamefacilService
        .getServidoresChamefacil({
          per_page: 50,
        })
        .then((resp) => resp.json())
        .then((resp) => {
          this.servidoresChamefacil = resp.data.dados.filter(
            (serv) => serv.status == 1
          );
        });
    },
    fecharSnackbar() {
      this.messageSnackbar = "";
      this.zerarErros();
    },
    zerarFormulario() {
      Array.from(this.formCadastro.elements).forEach((element) => {
        element.value = "";
      });
    },
    zerarErros() {
      Object.keys(this.error).forEach((error) => {
        this.error[error] = "";
      });
    },

    clean() {
      this.addFoto = true;
    },
    onChange() {
      const file = document.querySelector("input[type=file]").files[0];
      const reader = new FileReader();
      reader.onloadend = (e) => {
        this.preview = e.target.result;
      };
      if (file) {
        reader.readAsDataURL(file);
      }
    },

    atualizarUnidade() {
      if (!this.$refs.form.validate()) {
        return;
      }
      this.loading = true;
      const unidade = {
        nome_fantasia: this.unidade.nomeFantasia,
        descricao: this.unidade.nomeFantasia,
        razao_social: this.unidade.razaoSocial,
        email: this.unidade.email,
        cnpj: this.unidade.cnpj,
        telefone_1: this.unidade.telefone1,
        telefone_2: this.unidade.telefone2,
        cep: this.unidade.cep,
        estado: this.unidade.estado,
        cidade: this.unidade.cidade,
        bairro: this.unidade.bairro,
        logradouro: this.unidade.logradouro,
        numero: this.unidade.numero,
        complemento: this.unidade.complemento,
        referencias: this.unidade.referencias,
        chamefacil: this.unidade.chamefacil,
      };

      this.services.unidadeService
        .atualizarUnidade(
          this.unidade.unidadeId,
          this.unidade.pessoaJuridicaId,
          unidade
        )
        // .atualizarUnidade(this.unidade.pessoaJuridicaId, unidade)
        .then(async (response) => {
          const body = await response.json();
          const message =
            body.message || this.$global.messages.internalServerError;
          if (response.status !== 200) {
            this.$_ACTIONS_showSnackbarMessage({ message, color: "error" });
            const errors = body.errors;
            Object.keys(body.errors).forEach((error) => {
              if (errors[error]) {
                this.error[error] = errors[error];
              }
            });
            this.loading = false;
            return;
          }
          this.$_ACTIONS_showSnackbarMessage({
            message: body.message || this.$global.messages.internalServerError,
            color: "sucess",
          });
          this.$emit("close");
        })
        .catch((e) => {
          console.log({ e });
          this.$_ACTIONS_showSnackbarMessage({
            message: this.$global.messages.internalServerError,
            color: "error",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
.form-group label {
  text-align: left !important;
  display: block;
}

.form-group .label-genero {
  margin-right: 1rem;
}
</style>
