<template>
  <v-card>
    <v-card-title>
      <span class="headline">Editar tipo de estorno</span>
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-row>
          <v-col cols="12">
            <v-text-field v-model="descricao" label="Nome"></v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn text @click="$emit('close')"> Cancelar </v-btn>
      <v-btn
        class="btn-app-primary"
        style="color: #fff !important"
        :loading="loading"
        text
        @click="edit"
        :disabled="!allowEdition"
      >
        Salvar
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import TipoEstornoService from "@/services/tipo_estorno_service.js";
import Mixin from "@/mixins/vuex_snackbar_mixin.js";

export default {
  mixins: [Mixin],

  data: () => ({
    singleSelect: false,
    loading: false,
    formCadastro: "",
    descricao: "",
    tipoProcedimentoId: 0,
    tipoEstornoService: TipoEstornoService.build(),
    rules: {
      required: (value) => !!value || "Campo obrigatório.",
    },
  }),
  props: {
    tipoEstorno: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    this.renderLoading = true;
    this.formCadastro = document.forms[0];
    this.tipoEstornoId = this.tipoEstorno.id;
    this.descricao = this.tipoEstorno.descricao;
    this.renderLoading = false;
  },
  methods: {
    async edit() {
      this.loading = true;
      try {
        this.loading = true;
        const formData = new FormData();
        formData.append("descricao", this.descricao);
        const response = await this.tipoEstornoService.editarTipoEstorno(
          this.tipoEstornoId,
          formData
        );
        this.$_ACTIONS_showSnackbarMessage({
          message:
            response.status === 200
              ? "O Tipo de Procedimento foi atualizado com sucesso."
              : this.$global.messages.internalServerError,
          color: response.status === 200 ? "sucess" : "error",
        });
        response.status === 200 && this.$emit("response", this.tipoEstorno);
      } catch (e) {
        console.error({ e });
      }
      this.loading = false;
    },
  },
  computed: {
    allowEdition() {
      return true;
    },
  },
};
</script>
