<template>
  <v-card>
    <v-toolbar class="bg-verde mb-5">
      <h3 class="text-white">
        Editar Servidor Chamefacil
      </h3>
    </v-toolbar>
    <v-card-text>
      <div class="pt-3 ">
        <v-text-field
          outlined
          label="Host URL"
          placeholder="Host URL"
          v-model="hostUrl"
        ></v-text-field>
        <v-text-field
          outlined
          label="API URL"
          placeholder="API URL"
          v-model="apiUrl"
        ></v-text-field>
        <v-select
          outlined
          :items="statusList"
          item-text="descricao"
          item-value="id"
          v-model="statusSelected"
        ></v-select>
      </div>
    </v-card-text>
    <v-card-actions class="pb-5 px-8">
      <v-spacer></v-spacer>
      <v-btn text @click="$emit('close')"> Cancelar </v-btn>
      <v-btn dark class="bg-verde" :loading="loading" @click="editar">
        Salvar
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import VuexSnackbarMixin from "@/mixins/vuex_snackbar_mixin.js";
import ServidoresChamefacilService from "@/services/servidores_chamefacil_service.js";

export default {
  mixins: [VuexSnackbarMixin],
  props: {
    servidorEdicao: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      hostUrl: "",
      apiUrl: "",
      statusSelected: {},
      statusList: [
        { id: 1, descricao: "Ativo" },
        { id: 2, descricao: "Inativo" },
        { id: 3, descricao: "Suspenso" },
      ],
      services: {
        servidoresChamefacilService: ServidoresChamefacilService.build(),
      },
    };
  },
  mounted() {
    this.setServidorEdicao();
  },
  methods: {
    setServidorEdicao() {
      this.apiUrl = this.servidorEdicao.api;
      this.hostUrl = this.servidorEdicao.host;
      this.statusSelected = this.statusList.find(
        (status) => status.descricao == this.servidorEdicao.status
      ).id;
    },

    editar() {
      this.loading = true;

      if (!this.hostUrl || !this.apiUrl) {
        this.$_ACTIONS_showSnackbarMessage({
          message: "Preencha os campos corretamente",
          color: "error",
        });

        this.loading = false;
        return;
      }

      const responseFunctions = {
        onSucess: (status) => () => {
          if (status == 200) {
            this.$_ACTIONS_showSnackbarMessage({
              message: "Servidor editado com sucesso",
              color: "sucess",
            });
            this.$emit("response");
          } else {
            this.$_ACTIONS_showSnackbarMessage({
              message: "Falha ao editar servidor",
              color: "error",
            });
          }
        },
        onError: () => {
          this.$_ACTIONS_showSnackbarMessage({
            message: this.$global.messages.internalServerError,
            color: "error",
          });
        },
        onEnd: () => {
          this.loading = false;
        },
      };

      const servidor = {
        id: this.servidorEdicao.id,
        host: this.hostUrl,
        api: this.apiUrl,
        status: this.statusSelected,
      };

      console.log(servidor);

      this.services.servidoresChamefacilService.editServidorChamefacil(
        responseFunctions,
        servidor
      );
    },
  },
};
</script>

<style></style>
