import HTTPService from "@/services/http_service.js";
import { global } from "@/config/constants";

export default class SetorService extends HTTPService {
  resource = "setor";

  static build() {
    return new SetorService();
  }

  getSetorPaginatedList({ page, per_page, search, order, desc }) {
    return this.get(this.resource, {
      page,
      per_page,
      search,
      order,
      desc,
    });
  }

  async register(setor) {
    const headers = new Headers();

    const token = JSON.parse(sessionStorage.getItem("vuex")).token;
    headers.append("Authorization", "Bearer " + token);

    const requestOptions = {
      method: "POST",
      body: setor,
      headers: headers,
    };
    return await fetch(
      `${global.api.baseURL}/${this.resource}`,
      requestOptions
    ).then(async (response) => {
      return response;
    });
  }

  async editar(setorId, setor) {
    const headers = new Headers();
    const token = JSON.parse(sessionStorage.getItem("vuex")).token;
    headers.append("Authorization", "Bearer " + token);

    const requestOptions = {
      method: "POST",
      body: setor,
      headers: headers,
    };
    return await fetch(
      `${global.api.baseURL}/${this.resource}/${setorId}`,
      requestOptions
    ).then(async (response) => {
      return response;
    });
  }

  async deletar(setorId) {
    const headers = new Headers();
    const token = JSON.parse(sessionStorage.getItem("vuex")).token;
    headers.append("Authorization", "Bearer " + token);

    const requestOptions = {
      method: "POST",
      headers: headers,
    };
    return fetch(
      `${global.api.baseURL}/${this.resource}/${setorId}/deletar`,
      requestOptions
    ).then(async (response) => {
      return response;
    });
  }
}
