<template>
  <div>
    <v-card>
      <v-card-title class="headline" style="word-break: normal"
        >Editar Especialidade</v-card-title
      >
      <!-- CAMPO TIPO DE PROCEDIMENTO -->
      <v-select
        class="col-10 mx-auto"
        v-model="especialidadeId"
        :items="especialidadeList"
        item-text="descricao"
        item-value="id"
        solo
      >
      </v-select>
      <v-card-actions class="justify-end">
        <v-btn text @click="$emit('close')">Cancelar</v-btn>
        <v-btn
          :loading="loading"
          text
          @click="atualizarHorario"
          class="btn-app-primary"
          style="color: #fff !important"
          >Salvar</v-btn
        >
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import AgendaService from "@/services/agenda_service.js";
import VuexSnackbarMixin from "@/mixins/vuex_snackbar_mixin.js";
import TipoProcedimentoMixin from "@/mixins/tipo_procedimento_mixin.js";
import EspecialidadeService from "@/services/especialidade_service.js";

export default {
  mixins: [VuexSnackbarMixin, TipoProcedimentoMixin],
  props: {
    horario: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    this.setEspecialidades();
  },
  watch: {
    horario(val) {
      this.especialidadeId = val.especialidadeId;
    },
  },
  data: () => ({
    especialidadeService: EspecialidadeService.build(),
    loading: false,
    especialidadeId: 0,
    especialidadeList: [],
  }),
  methods: {
    atualizarHorario() {
      const agendaService = new AgendaService();
      this.loading = true;
      agendaService.atualizarEspecialidadeDeAgenda(
        {
          onSucess: (status) => (body) => {
            this.$_ACTIONS_showSnackbarMessage({
              message:
                body.message || this.$global.messages.internalServerError,
              color: status === 200 ? "sucess" : "error",
            });
            this.$emit("response");
          },
          onError: (error) => {
            console.error(error);
            this.$_ACTIONS_showSnackbarMessage({
              message: this.$global.messages.internalServerError,
              color: "error",
            });
          },
          onEnd: () => {
            this.loading = false;
          },
        },
        this.especialidadeId,
        this.horario.id
      );
    },
    async setEspecialidades() {
      await this.especialidadeService
        .getEspecialidadeList()
        .then(async (response) => {
          if (response.status !== 200) return;
          const { data } = await response.json();
          const { especialidades } = data;
          this.especialidadeList = especialidades.map(({ id, descricao }) => {
            return { id: parseInt(id), descricao };
          });
        })
        .catch((e) => {
          console.error(e);
        });
    },
  },
};
</script>