import HTTPService from "@/services/http_service.js";
import { global } from "@/config/constants";

export default class ProcedimentoEspecialidadeService extends HTTPService {
  resource = "especialidade-procedimento";

  static build() {
    return new ProcedimentoEspecialidadeService();
  }

  getProcedimentoEspecialidadePaginatedList({
    page,
    per_page,
    order,
    desc,
    search,
    filter,
  }) {
    const pagination = { page, per_page, order, desc, search };
    return this.get(this.resource, { ...pagination, ...filter });
  }

  async cadastroProcedimentoEspecialidade(procedimentoEspecialidade) {
    const headers = new Headers();
    const token = JSON.parse(sessionStorage.getItem("vuex")).token;
    headers.append("Authorization", "Bearer " + token);

    const requestOptions = {
      method: "POST",
      body: procedimentoEspecialidade,
      headers: headers,
    };
    return await fetch(
      `${global.api.baseURL}/${this.resource}`,
      requestOptions
    ).then(async (response) => {
      return response;
    });
  }

  async editar(procedimentoEspecialidadeId, procedimentoEspecialidade) {
    const headers = new Headers();
    const token = JSON.parse(sessionStorage.getItem("vuex")).token;
    headers.append("Authorization", "Bearer " + token);

    const requestOptions = {
      method: "POST",
      body: procedimentoEspecialidade,
      headers: headers,
    };
    return await fetch(
      `${global.api.baseURL}/${this.resource}/${procedimentoEspecialidadeId}`,
      requestOptions
    ).then(async (response) => {
      return response;
    });
  }
}
