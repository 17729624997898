<template>
  <div>
    <div class="p-lg-3">
      <div class="row" justify-content-between>
        <h3 class="mt-0 mb-2 font-weight-bold text-left">
          Relatório - Produtos e Serviços Quantitativos
        </h3>
      </div>
    </div>

    <v-row align="center" class="my-3">
      <v-col class="d-flex">
        <v-select
          :items="unidades"
          label="Unidade"
          item-value="id"
          v-model="unidadeSelected"
        ></v-select>
      </v-col>
      <v-col class="d-flex">
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          :return-value.sync="datas"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="computedFormatDate"
              label="Data inicial - Data final"
              prepend-icon="mdi-calendar"
              clearable
              v-bind="attrs"
              v-on="on"
              @click:clear="datas = null"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="datas"
            no-title
            range
            scrollable
            color="green darken-4"
          >
            <v-spacer></v-spacer>
            <v-btn text @click="menu = false"> Cancelar </v-btn>
            <v-btn dark color="success" @click="$refs.menu.save(datas)">
              OK
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
      <v-col class="d-flex">
        <v-select
          :items="convenios"
          item-text="descricao"
          item-value="id"
          label="Convênio"
          v-model="convenioSelected"
        ></v-select>
      </v-col>
      <v-col class="d-flex">
        <v-select
          :items="tipoList"
          item-text="descricao"
          item-value="id"
          label="Tipo"
          v-model="tipoSelected"
        ></v-select>
      </v-col>
      <v-col class="overflow-select">
        <v-select
          v-model="filtroPesquisaSelected"
          :items="filtroPesquisa"
          label="Procedimentos"
          item-text="nome"
          item-value="id"
          return-object
          multiple
        >
          <template v-slot:selection="{ item, index }">
            <v-chip v-if="index === 0">
              <span>{{ limitarCaractetes(item.nome) }}</span>
            </v-chip>
            <span v-if="index === 1" class="grey--text text-caption">
              (+{{ filtroPesquisaSelected.length - 1 }} procedimentos)
            </span>
          </template>
          <template v-slot:prepend-item>
            <v-list-item ripple @mousedown.prevent @click="toggle">
              <v-list-item-action>
                <v-icon :color="likesAllProcedimento ? 'indigo darken-4' : ''">
                  {{ icon }}
                </v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Selecionar Todos</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider class="mt-2"></v-divider>
          </template>
          <!-- ... Restante do seu código ... -->
        </v-select>
      </v-col>
      <v-col class="d-flex">
        <v-speed-dial
          v-model="fab"
          :direction="direction"
          :open-on-hover="hover"
        >
          <template v-slot:activator>
            <v-btn
              v-model="fab"
              style="
                color: #fff;
                background-color: #1daf80;
                border-radius: 50px;
              "
              color="blue darken-2"
              dark
              class="btnWid"
            >
              <v-icon v-if="fab"> mdi-close </v-icon>
              <span v-else> Exportar </span>
            </v-btn>
          </template>
          <v-btn dark color="green" class="btnWid" @click="pdfRelatorio">
            PDF
          </v-btn>
          <v-btn dark color="indigo" class="btnWid" @click="excelRelatorio">
            EXCEL
          </v-btn>
        </v-speed-dial>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import UnidadeService from "../services/unidade_service";
import ProcedimentoService from "../services/procedimento_service";
import FormaPagamentoService from "@/services/forma_pagamento_service";
import Mixin from "@/mixins/vuex_mixin.js";

export default {
  components: {},
  mixins: [Mixin],
  data: () => ({
    datas: null,
    menu: false,
    filtroPesquisa: [],
    filtroPesquisaSelected: [],
    loadingFiltroPesquisa: false,
    loadingTable: false,
    options: {
      page: 1,
      pageCount: 0,
      itemsPerPage: 15,
      ordenacao: false,
      desc: false,
    },
    unidades: [],
    unidadeSelected: "",
    convenios: [
      { descricao: "Todos", id: 0 },
      { descricao: "Público", id: 1 },
      { descricao: "Particular", id: 2 },
      { descricao: "Plano M100", id: 3 },
      { descricao: "Plano M50", id: 4 },
    ],
    convenioSelected: "",
    tipoList: [
      { descricao: "Todos", id: 0 },
      { descricao: "Triagem", id: 1 },
      { descricao: "Consulta", id: 2 },
      { descricao: "Cirurgia", id: 3 },
      { descricao: "Exame", id: 4 },
      { descricao: "Medicação", id: 5 },
      { descricao: "Internacao", id: 6 },
      { descricao: "Compromisso", id: 7 },
      { descricao: "Anestesia", id: 10 },
    ],
    tipoSelected: "",
    comandas: [],
    procedimentoId: "",
    formasPagamento: [],
    services: {
      unidadeService: UnidadeService.build(),
      procedimentoService: ProcedimentoService.build(),
      formaPagamentoService: FormaPagamentoService.build(),
    },
    direction: "bottom",
    fab: false,
    hover: false,
    left: false,
  }),
  watch: {
    async tipoSelected() {
      //this.filtroPesquisa = [{ id: 0, nome: "Todos" }];

      // Verifique se o tipo selecionado não é "Todos" (id 11)
      this.likesAllProcedimento = false;
      this.toggle();
      if (this.tipoSelected !== 0 && this.unidadeSelected) {
        this.loadingFiltroPesquisa = true;

        // Chame o serviço para obter os procedimentos correspondentes
        this.filtroPesquisa = [];
        this.filtroPesquisa = this.filtroPesquisa.concat(
          await this.services.procedimentoService
            .getProcedimentosPorUnidadeEprocedimento(
              this.unidadeSelected,
              this.tipoSelected ?? 0
            )
            .then((resp) => {
              console.log(resp);
              return resp.map((item) => {
                return {
                  id: item.procedimento_id,
                  nome: item.procedimento_descricao,
                };
              });
            })
        );

        this.loadingFiltroPesquisa = false;
      }
      if (this.tipoSelected == 0 && this.unidadeSelected) {
        this.loadingFiltroPesquisa = true;

        // Chame o serviço para obter os procedimentos correspondentes
        this.filtroPesquisa = [];
        this.filtroPesquisa = this.filtroPesquisa.concat(
          await this.services.procedimentoService
            .getProcedimentosPorUnidadeRelatorio(this.unidadeSelected)
            .then((resp) => {
              console.log(resp);
              return resp.map((item) => {
                return {
                  id: item.procedimento_id,
                  nome: item.procedimento_descricao,
                };
              });
            })
        );

        this.loadingFiltroPesquisa = false;
      }
    },
  },
  beforeMount() {
    this.setUnidades();
  },
  computed: {
    computedFormatDate() {
      const datasFormatadas =
        this.datas &&
        this.datas.map((item) =>
          item
            .split("-")
            .reverse()
            .join("/")
        );
      return datasFormatadas;
    },
    likesAllProcedimento() {
      return this.filtroPesquisaSelected.length === this.filtroPesquisa.length;
    },
    likesSomeProcedimento() {
      return (
        this.filtroPesquisaSelected.length > 0 && !this.likesAllProcedimento
      );
    },
    icon() {
      if (this.likesAllProcedimento) return "mdi-close-box";
      if (this.likesSomeProcedimento) return "mdi-minus-box";
      return "mdi-checkbox-blank-outline";
    },
  },
  methods: {
    setUnidades() {
      this.services.unidadeService
        .getUnidadeByUsuarioLogado()
        .then((response) => {
          return response.json();
        })
        .then((response) => {
          this.unidades = response.data.map((unidade) => {
            return { text: unidade.unidade, id: unidade.id_unidade };
          });
        });
    },
    toggle() {
      this.$nextTick(() => {
        if (this.likesAllProcedimento) {
          this.filtroPesquisaSelected = [];
        } else {
          this.filtroPesquisaSelected = [...this.filtroPesquisa];
        }
      });
    },
    pdfRelatorio() {
      this.loadingTable = true;

      const dataInicial = this.datas ? this.datas[0].split("-").join("") : "";
      const dataFinal = this.datas ? this.datas[1].split("-").join("") : "";
      const unidade = this.unidadeSelected ? this.unidadeSelected : "";
      const convenio = this.convenioSelected;
      const tipoProcedimento = this.tipoSelected;

      const filtroPesquisaIds = this.filtroPesquisaSelected.map(
        (item) => item.id
      );
      //const filtroPesquida = this.filtroPesquisaSelected;

      if (
        !dataInicial ||
        !dataFinal ||
        !unidade ||
        (convenio !== 0 && !convenio) ||
        (tipoProcedimento !== 0 && !tipoProcedimento)
      ) {
        this.$_ACTIONS_showSnackbarMessage({
          message: "Preencha os campos corretamente",
          color: "error",
        });
        this.loadingTable = false;
        return;
      }

      this.services.procedimentoService
        .pdfRelatorioProcedimentosQuantitativo(
          dataInicial,
          dataFinal,
          unidade,
          convenio,
          tipoProcedimento,
          filtroPesquisaIds
        )
        .then((resp) => {
          if (resp.status) {
            this.$_ACTIONS_showSnackbarMessage({
              message: "Falha ao carregar",
              color: "error",
            });
            return;
          }
          this.$_ACTIONS_showSnackbarMessage({
            message: "Dados encontrados com sucesso! Gerando PDF...",
            color: "sucess",
          });
          let link = document.createElement("a");
          // link.target = "_blank";
          link.download = `${dataInicial}-${dataFinal}.pdf`;
          let url = window.URL.createObjectURL(resp);
          link.href = url;
          link.click();
        })
        .catch(() => {
          this.$_ACTIONS_showSnackbarMessage({
            message: "Falha ao carregar",
            color: "error",
          });
        })
        .finally(() => {
          this.loadingTable = false;
        });
    },
    excelRelatorio() {
      this.loadingTable = true;

      const dataInicial = this.datas ? this.datas[0].split("-").join("") : "";
      const dataFinal = this.datas ? this.datas[1].split("-").join("") : "";
      const unidade = this.unidadeSelected ? this.unidadeSelected : "";
      const convenio = this.convenioSelected;
      const tipoProcedimento = this.tipoSelected;

      const filtroPesquisaIds = this.filtroPesquisaSelected.map(
        (item) => item.id
      );

      if (
        !dataInicial ||
        !dataFinal ||
        !unidade ||
        (convenio !== 0 && !convenio) ||
        (tipoProcedimento !== 0 && !tipoProcedimento)
      ) {
        this.$_ACTIONS_showSnackbarMessage({
          message: "Preencha os campos corretamente",
          color: "error",
        });
        this.loadingTable = false;
        return;
      }

      this.services.procedimentoService
        .excelRelatorioProcedimentosQuantitativo(
          dataInicial,
          dataFinal,
          unidade,
          convenio,
          tipoProcedimento,
          filtroPesquisaIds
        )
        .then((resp) => {
          if (resp.status) {
            this.$_ACTIONS_showSnackbarMessage({
              message: "Falha ao carregar",
              color: "error",
            });
            return;
          }
          this.$_ACTIONS_showSnackbarMessage({
            message:
              "Dados encontrados com sucesso! Gerando arquivo para Excel...",
            color: "sucess",
          });
          let link = document.createElement("a");
          // link.target = "_blank";
          link.download = `${dataInicial}-${dataFinal}.xlsx`;
          let url = window.URL.createObjectURL(resp);
          link.href = url;
          link.click();
        })
        .catch(() => {
          this.$_ACTIONS_showSnackbarMessage({
            message: "Falha ao carregar",
            color: "error",
          });
        })
        .finally(() => {
          this.loadingTable = false;
        });
    },
    limitarCaractetes(string) {
      if (string.length > 10) {
        return string.substring(0, 10) + "...";
      } else {
        return string;
      }
    },
  },
};
</script>
<style scoped>
.btnWid {
  width: 7rem;
}
.overflow-select {
  max-height: 200px;
  overflow-y: auto;
}
</style>
