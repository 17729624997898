<template>
    <v-card>
    <v-toolbar style="background-color: #1daf80; color: #fff">
      <v-btn icon dark @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>
        <span class="headline">Nova Senha</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items> </v-toolbar-items>
    </v-toolbar>
    <v-container>      
    <div class="container">
      <div class="row justify-center">
        <div class="col-lg-10 text-start">
          <h4>Usuário: {{ infosUsuario.nomeUsuario }}</h4>
        </div>
      </div>
      <div class="row justify-center">
        <div class="col-lg-10 form-group clearfix mb-2">
          <label for="nome">Nova Senha</label>
          <v-text-field
          style="padding-top: 0 !important"
          v-model="novaSenha"
            :append-icon="showSenha ? 'mdi-eye' : 'mdi-eye-off'"
            :rules="[rules.required, rules.min]"
            :type="showSenha ? 'text' : 'password'"
            placeholder="Nova Senha"
            @click:append="showSenha = !showSenha"
          ></v-text-field>
        </div>       
      </div>
      <div class="row justify-center">
        <div class="col-lg-10 form-group clearfix mb-2">
          <label for="nome">Confirmar Senha</label>          
          <v-text-field
          style="padding-top: 0 !important"
            v-model="confirmarSenha"
            :append-icon="showConfSenha ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showConfSenha ? 'text' : 'password'"
            placeholder="Confirmar Senha"
            @click:append="showConfSenha = !showConfSenha"
          ></v-text-field>
        </div>
      </div>
      <div class="row justify-center">
      <div class="col-md-10 text-right">
          <button
          @click.prevent="cadastrarNovaSenha"
          class="btn btn-app-primary font-weight-bold"
          :disabled="!formValid"
          >
          <span v-if="!loading"> Alterar Senha </span>
          <v-progress-circular
          v-else
          indeterminate
          color="white"
          ></v-progress-circular>
        </button>
      </div>
      </div>
      </div>
    </v-container>
    </v-card>
  </template>

<script>
import UsuarioService from "../../services/usuario_service.js";
import Mixin from "@/mixins/vuex_mixin.js";



export default {
  mixins: [Mixin],
  props: {
    infosUsuario: {
      type: Object
    },
  },
  data: () => ({
    novaSenha: '',
    confirmarSenha: '',
    loading: false,
    formValid: false,
    showSenha:false,
    showConfSenha:false,
    services: {
      usuarioService: new UsuarioService,
    },
    rules: {
      required: value => !!value || 'Requisito.',
      min: v => v.length >= 6 || 'Mínimo 6 caracteres.',
    },
  }),
  watch: {
    novaSenha() {
      this.validarForm();      
    },
    confirmarSenha() {
      this.validarForm();
    },
    },
  methods: {
    validarForm(){
      if((this.novaSenha == this.confirmarSenha) && this.novaSenha != '' && this.novaSenha.length >= 6){
        this.formValid = true
      }else{
        this.formValid = false 
      }
    },
    cadastrarNovaSenha() {
      this.loading = true;
      this.services.usuarioService.novaSenha({
      onSucess: status => body => {
          this.$_ACTIONS_showSnackbarMessage({
              message: body.message || this.$global.messages.internalServerError,
              color: status === 200 ? 'sucess' : 'error'
          })
          if (status === 200) {
            this.$emit('close')
          }
      },
      onError: (error) => {
          console.error(error)
          this.$_ACTIONS_showSnackbarMessage({
              message: this.$global.messages.internalServerError,
              color: 'error'
          })
      },
      onEnd: () => {
          this.loading = false
      },
      }, this.getForm())  
    },
    getForm(){
      return {
        id_usuario: this.infosUsuario.idUsuario ,
        senha: this.novaSenha
      }
    }
  }
}
</script>