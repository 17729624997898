<template>
  <!-- <v-simple-table class="mt-5">
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-left">Senha</th>
          <th class="text-left">Tutor</th>
          <th class="text-left">Pet</th>
          <th class="text-left">Id Pet</th>
          <th class="text-left">Horario de Chegada</th>
          <th class="text-left">Urgencia</th>
          <th>Observações</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="paciente in pacientes"
          :key="paciente.nome"
          class="text-left"
        >
          <td>{{ paciente.Senha }}</td>
          <td>{{ paciente.NomeTutor || paciente.UsuarioSenha }}</td>
          <td>{{ paciente.NomePet }}</td>
          <td>{{ paciente.PetCodigo }}</td>
          <td>{{ paciente.DataCriacao.slice(11) }}</td>
          <td :class="urgenciaAtendimento(paciente.ClassificacaoAtendimento)">
            {{ paciente.ClassificacaoAtendimento }}
          </td>
          <td>
            <v-dialog v-model="dialogObservacoes" width="500">
              <template v-slot:activator="{ on, attrs }">
                <v-btn small depressed color="error" v-bind="attrs" v-on="on"
                  >Observações
                </v-btn>
              </template>
              <v-card>
                <v-card-title class="text-h5 bg-danger text-white lighten-2">
                  Observações
                </v-card-title>

                <h5 class="text-justify pt-5 px-5">
                  {{ paciente.ObservacaoExtra }}
                </h5>

                <v-divider></v-divider>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="primary"
                    text
                    @click="dialogObservacoes = false"
                  >
                    Ok
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </td>
          <td>
            <v-btn
              small
              depressed
              color="success"
              @click="chamarSenha(paciente.IdChamadaInterna)"
            >
              Chamar
            </v-btn>
          </td>
        </tr>
      </tbody>
    </template>
  </v-simple-table> -->
  <div>
    <v-data-table
      :headers="headers"
      :items="pacientes"
      :expanded.sync="expanded"
      item-key="IdChamadaInterna"
      show-expand
      class="elevation-1"
    >
      <template v-slot:item.DataCriacao="{ item }">
        <span>{{
          item.DataCriacao &&
            item.DataCriacao.split(" ")[0]
              .split("-")
              .reverse()
              .join("/")
        }}</span>
      </template>
      <template v-slot:item.HoraCriacao="{ item }">
        <span>{{ item.DataCriacao && item.DataCriacao.split(" ")[1] }}</span>
      </template>
      <template v-slot:item.tutor="{ item }">
        <span>{{ item.NomeTutor || item.UsuarioSenha }}</span>
      </template>
      <template v-slot:item.urgencia="{ item }">
        <span :class="urgenciaAtendimento(item.ClassificacaoAtendimento)">
          {{ item.ClassificacaoAtendimento }}
        </span>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td
          class="font-weight-bold text-left py-3 bg-white"
          :colspan="headers.length"
        >
          Observações:
          <b class="red--text font-weight-regular">{{
            item.ObservacaoExtra
          }}</b>
        </td>
      </template>
      <template v-slot:item.chamar="{ item }">
        <div class="d-flex justify-content-between">
          <v-btn
            small
            depressed
            color="success"
            @click="chamarSenha(item.IdChamadaInterna)"
          >
            Chamar
          </v-btn>
          <v-icon
            class="mr-2"
            color="primary"
            title="Prontuários"
            @click="listProntuarios(item)"
            >fas fa-file-medical-alt
          </v-icon>
        </div>
      </template>
    </v-data-table>
    <v-dialog
      v-model="dialogProntuario"
      v-if="dialogProntuario"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <prontuarios
        :consultas="prontuarios"
        :pacienteInfo="pacienteInfo"
        @close="dialogProntuario = false"
      />
    </v-dialog>
  </div>
</template>

<script>
import { global } from "@/config/constants";
import axios from "axios";

import ProcedimentoService from "@/services/procedimento_service";
import AgendamentoService from "@/services/agendamento_service";
import PetsService from "@/services/pets_service";
import Prontuarios from "@/components/prontuarios/prontuarios.vue";
import Mixin from "@/mixins/vuex_mixin.js";
import AuthService from "@/services/auth_service";
import ChamefacilMixin from "@/mixins/chamefacil.js";

export default {
  mixins: [Mixin, ChamefacilMixin],
  components: {
    Prontuarios,
  },
  data() {
    return {
      tokenChamefacil: "",
      pacientes: [],
      dialogAtender: false,
      dialogObservacoes: false,
      pacienteEmAtendimento: {},
      procedimentos: [],
      procedimentosService: ProcedimentoService.build(),
      agendamentoService: AgendamentoService.build(),
      procedimento: {},
      procedimentoLoading: false,
      listarFila: true,
      petService: PetsService.build(),
      petName: false,
      headers: [
        {
          text: "Senha",
          align: "start",
          sortable: false,
          value: "Senha",
        },
        { text: "Tutor", value: "tutor" },
        { text: "Pet", value: "NomePet" },
        { text: "Id Pet", value: "PetCodigo" },
        { text: "Data de criação", value: "DataCriacao" },
        { text: "Horário de criação", value: "HoraCriacao" },
        { text: "Urgencia", value: "urgencia" },
        { text: "", value: "chamar" },
      ],
      dialogProntuario: false,
      prontuarios: [],
      pacienteInfo: {},
      services: {
        authService: AuthService.build(),
      },
    };
  },
  async beforeMount() {
    this.tokenChamefacil = localStorage.getItem("tokenChamefacil") || "";

    await this.getservidorChameFacil();

    if (!this.tokenChamefacil) {
      this.getChamefacilToken();
    } else {
      this.listarFilaChamefacil();
    }

    var fila = setInterval(() => {
      if (this.listarFila == false) {
        clearInterval(fila);
      } else {
        this.listarFilaChamefacil();
      }
    }, 5000);
  },
  destroyed() {
    this.listarFila = false;
  },
  methods: {
    listarFilaChamefacil() {
      const headers = { token: this.tokenChamefacil };
      axios
        .get(
          global.api.baseUrlChamefacil +
            "/atendimento/listarsenhasinternaspendentesV2",
          { headers }
        )
        .then(async (resp) => {
          this.pacientes = await resp.data.Senha;
        });
    },
    chamarSenha(idChamada) {
      this.$emit("chamarSenha", idChamada);
    },
    urgenciaAtendimento(urgencia) {
      return urgencia.toLowerCase() == "urgente"
        ? "red--text font-weight-bold"
        : "";
    },
    async listProntuarios(item) {
      await this.petService
        .getConsultasbyUnidade(
          item.PetCodigo,
          JSON.parse(sessionStorage.vuex).unidade.id
        )
        .then(async (response) => {
          const data = await response.json();
          if (response.status !== 200) {
            this.$_ACTIONS_showSnackbarMessage({
              message: data.message,
              color: response.status === 200 ? "sucess" : "error",
            });
          }
          this.pacienteInfo = {
            nome: item.NomeTutor,
            nome_pet: item.NomePet,
            id_pet: item.PetCodigo,
            id_cliente: item.TutorCodigo,
          };
          this.prontuarios = data.data.consultas;
          this.dialogProntuario = true;
        });
    },
    async getservidorChameFacil() {
      const unidadeId = JSON.parse(sessionStorage.getItem("vuex")).unidade.id;

      await this.services.authService
        .servidorChameFacil(unidadeId)
        .then(async (response) => {
          const resp = await response.json();

          return {
            status: response.status,
            resp,
          };
        })
        .then(({ status, resp }) => {
          if (status == 200) {
            this.setServidorChamefacil(resp.data.host, resp.data.api);
          }
        });
    },
    getChamefacilToken() {
      const unidadeId = JSON.parse(sessionStorage.getItem("vuex")).unidade.id;

      this.services.authService
        .tokenChamefacil(unidadeId)
        .then(async (response) => {
          const resp = await response.json();
          return {
            status: response.status,
            resp,
          };
        })
        .then(({ status, resp }) => {
          if (status == 200) {
            console.log(resp);
            localStorage.setItem("tokenChamefacil", resp.data.Token);
            this.tokenChamefacil = resp.data.Token;
          }
        })
        .then(() => {
          this.listarFilaChamefacil();
        });
    },
  },
};
</script>

<style scoped>
.btn-style-green {
  color: #fff;
  background-color: #1daf80 !important;
  height: 30px !important;
  font-size: 12px;
  padding: 10px !important;
  border-radius: 50px;
  margin: 20px;
}
</style>
