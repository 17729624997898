<template>
  <v-card>
    <v-toolbar class="bg-verde mb-5">
      <h3 class="text-white">
        Cadastro de Servidor Chamefacil
      </h3>
    </v-toolbar>
    <v-card-text>
      <div class="pt-3 ">
        <v-text-field
          outlined
          label="Host URL"
          placeholder="Host URL"
          v-model="hostUrl"
        ></v-text-field>
        <v-text-field
          outlined
          label="API URL"
          placeholder="API URL"
          v-model="apiUrl"
        ></v-text-field>
      </div>
    </v-card-text>
    <v-card-actions class="pb-5 px-8">
      <v-spacer></v-spacer>
      <v-btn text @click="$emit('close')"> Cancelar </v-btn>
      <v-btn dark class="bg-verde" :loading="loading" @click="cadastrar">
        Salvar
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import VuexSnackbarMixin from "@/mixins/vuex_snackbar_mixin.js";
import ServidoresChamefacilService from "@/services/servidores_chamefacil_service.js";

export default {
  mixins: [VuexSnackbarMixin],
  data() {
    return {
      loading: false,
      rules: {
        required: (value) => !!value || "Campo obrigatório.",
      },
      hostUrl: "",
      apiUrl: "",
      services: {
        servidoresChamefacilService: ServidoresChamefacilService.build(),
      },
    };
  },
  methods: {
    cadastrar() {
      this.loading = true;

      if (!this.hostUrl || !this.apiUrl) {
        this.$_ACTIONS_showSnackbarMessage({
          message: "Preencha os campos corretamente",
          color: "error",
        });

        this.loading = false;
        return;
      }

      const responseFunctions = {
        onSucess: (status) => () => {
          if (status == 200) {
            this.$_ACTIONS_showSnackbarMessage({
              message: "Servidor cadastrado com sucesso",
              color: "sucess",
            });
            this.$emit("response");
          } else {
            this.$_ACTIONS_showSnackbarMessage({
              message: "Falha ao cadastrar servidor",
              color: "error",
            });
          }
        },
        onError: () => {
          this.$_ACTIONS_showSnackbarMessage({
            message: this.$global.messages.internalServerError,
            color: "error",
          });
        },
        onEnd: () => {
          this.loading = false;
        },
      };

      const servidor = { host: this.hostUrl, api: this.apiUrl, status: 1 };

      this.services.servidoresChamefacilService.registerServidorChamefacil(
        responseFunctions,
        servidor
      );
    },
  },
};
</script>

<style></style>
