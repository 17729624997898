<template>
  <div>
    <div class="p-lg-3">
      <div class="row justify-content-between">
        <h3 class="mt-0 mb-2 font-weight-bold text-left">Internação</h3>
      </div>
    </div>
    <v-row class="mt-2 px-2" align="center">
      <v-select
        :items="unidades"
        label="Unidade"
        item-value="id"
        v-model="unidadeSelected"
        outlined
        class="col-3"
      ></v-select>
      <v-select
        :items="filtrosInternacao"
        label="Filtrar"
        item-value="id"
        v-model="filtroInternacao"
        outlined
        class="col-3 ml-2"
      ></v-select>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Nome do pet"
        class="col-3 ml-2"
        outlined
      ></v-text-field>
      <v-btn
        class="bg-verde mt-n6 ml-4"
        dark
        rounded
        @click="listarInternados(unidadeSelected)"
        >Buscar</v-btn
      >
    </v-row>
    <div>
      <!-- <v-data-table
        :headers="headersInternacao"
        :items="internacaoList"
        class="elevation-1 mt-5"
        :loading="loadingInternados"
        :options.sync="options"
        :server-items-length="totalList"
      > -->
      <v-data-table
        :headers="headersInternacao"
        :items="internacaoList"
        class="elevation-1 mt-5"
        :loading="loadingInternados"
      >
        <template v-slot:item.nome_cliente="{ item }">
          {{
            item.nome_cliente
              .split(" ")
              .slice(0, 4)
              .join(" ")
          }}
        </template>
        <template v-slot:item.data_entrada_internacao="{ item }">
          {{
            item.data_entrada_internacao &&
              item.data_entrada_internacao
                .split(" ")[0]
                .split("-")
                .reverse()
                .join("/") +
                " - " +
                item.data_entrada_internacao.split(" ")[1]
          }}
        </template>
        <template v-slot:item.data_saida_internacao="{ item }">
          {{
            item.data_saida_internacao &&
              item.data_saida_internacao
                .split(" ")[0]
                .split("-")
                .reverse()
                .join("/") +
                " - " +
                item.data_saida_internacao.split(" ")[1]
          }}
        </template>
        <template v-slot:item.actions="{ item }">
          <div class="d-flex items-center">
            <v-icon size="21" class="mr-2" @click="showComandasPet(item)">
              mdi mdi-magnify
            </v-icon>
            <v-icon
              size="21"
              class="py-4 mr-2"
              title="REQUISIÇÕES"
              @click="openDialogRequisicao(item)"
              >mdi-clipboard-arrow-up-outline</v-icon
            >

            <v-icon
              size="21"
              v-if="[2, 3].includes(perfilUsuario)"
              class="mr-2"
              color="#1daf80"
              title="ATENDER"
              @click="atender(item)"
              >mdi-clipboard-text-play</v-icon
            >

            <v-icon
              size="17"
              color="primary"
              class="mr-2"
              title="PRONTUÁRIOS"
              @click="listProntuarios(item)"
              >fas fa-file-medical-alt
            </v-icon>

            <v-icon
              size="17"
              class="mr-1"
              title="Termo de Responsabilidade"
              @click="gerarTermos(item)"
              >fas fa-file-signature</v-icon
            >

            <v-icon
              size="21"
              class="mr-2"
              title="Upload de exames"
              @click="listUpload(item)"
              >mdi-file-upload-outline
            </v-icon>

            <v-icon
              small
              class="mr-2"
              title="Receita Novas"
              @click="gerarReceitasNovas(item)"
              >fas fa-capsules
            </v-icon>

            <v-btn
              x-small
              :dark="!loadingInternados"
              rounded
              color="warning"
              class="p-2 mt-4 w-50"
              :disabled="loadingInternados"
              @click="showDialogTipoSaida(item)"
              v-if="(item.codigo == 2) & [2, 3].includes(perfilUsuario)"
            >
              Alta Médica
            </v-btn>
            <v-btn
              x-small
              :dark="!loadingInternados"
              rounded
              color="error"
              class="p-2 mt-4 w-50"
              :disabled="loadingInternados"
              @click="iniciarAltaHospitalar(item)"
              v-if="(item.codigo == 3) & [2, 3].includes(perfilUsuario)"
            >
              Alta Hospitalar
            </v-btn>
          </div>
        </template>
      </v-data-table>
      <v-dialog
        v-model="dialogConfirmarRequisicaoSaida"
        v-if="dialogConfirmarRequisicaoSaida"
        width="400px"
      >
        <v-card class="p-1">
          <h3 class="p-3" v-if="tipoSaida == 'obito'">
            Confirma a saída deste pet por óbito?
          </h3>
          <h3 class="p-3" v-else>
            Deseja gerar alta médica para este pet?
          </h3>
          <div class="pb-3 d-flex justify-space-around w-50 m-auto">
            <v-btn
              :disabled="disableBtn"
              color="error"
              text
              rounded
              @click="dialogConfirmarRequisicaoSaida = false"
              >Não</v-btn
            >
            <v-btn
              :disabled="disableBtn"
              class="bg-verde"
              :dark="!disableBtn"
              rounded
              @click="prepareFormAltaInternacao()"
              >Sim</v-btn
            >
          </div>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="dialogRequisicao"
        v-if="dialogRequisicao"
        transition="dialog-bottom-transition"
        scrollable
        fullscreen
      >
        <v-card>
          <v-toolbar class="modal-nav-ajustes"
            ><v-btn icon dark @click="dialogRequisicao = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Requisições</v-toolbar-title>
          </v-toolbar>
          <v-divider></v-divider>
          <requisicao-modal
            v-if="dialogRequisicao"
            @close="dialogRequisicao = false"
            :petId="petId"
            :tutorId="clienteId"
          >
          </requisicao-modal>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="dialogTipoSaidaInternacao"
        v-if="dialogTipoSaidaInternacao"
        transition="dialog-bottom-transition"
        width="500px"
      >
        <v-card>
          <v-card-title>
            <div class="m-auto">
              <h3>Escolha o tipo de saída</h3>
            </div>
          </v-card-title>
          <v-card-text>
            <div class="d-flex flex-column px-4 pb-2 w-75 m-auto">
              <v-btn
                dark
                class="mb-3 bg-verde"
                @click="showdialogConfirmarSaida('alta')"
                >Alta médica</v-btn
              >
              <v-btn
                dark
                class="bg-verde"
                @click="showdialogConfirmarSaida('obito')"
                >Óbito</v-btn
              >
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="dialogFormAltaInternacao"
        v-if="dialogFormAltaInternacao"
        transition="dialog-bottom-transition"
        width="600px"
      >
        <v-card>
          <v-toolbar class="modal-nav-ajustes mb-5"
            ><v-btn icon dark @click="dialogFormAltaInternacao = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title v-if="this.internacao.codigo == 3"
              >Anamnese - Alta Hospitalar</v-toolbar-title
            >
            <v-toolbar-title v-else>Anamnese - Alta Internação</v-toolbar-title>
          </v-toolbar>
          <v-card-text>
            <div
              v-for="(input, index) in form.perguntas"
              :key="`pergunta${index}`"
            >
              <div class="col-12 d-flex justify-center">
                <form-consulta-fill
                  :pergunta="input"
                  v-if="input"
                  @resposta="respondido($event)"
                  class="col-12"
                />
              </div>
            </div>
          </v-card-text>
          <v-card-actions>
            <div class="w-100 d-flex justify-end">
              <v-btn
                :dark="!disableBtn"
                class="bg-verde mb-2"
                :disabled="disableBtn"
                @click="salvarFormAltaInternacao"
              >
                Salvar
              </v-btn>
            </div>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        max-width="500px"
        persistent
        fullscreen
        v-if="dialogAtender"
        v-model="dialogAtender"
      >
        <modal-atender
          v-if="dialogAtender"
          :petCodigo="internacao.id_pet"
          :petNome="internacao.nome_pet"
          :unidadeId="unidadeSelected"
          @close="dialogAtender = false"
        />
      </v-dialog>
      <v-dialog
        v-model="dialogAtenderMedico"
        v-if="dialogAtenderMedico"
        max-width="600px"
      >
        <v-card>
          <v-flex>
            <v-toolbar class="bg-verde" dark>
              <v-toolbar-title>Iniciar Atendimento</v-toolbar-title>
              <v-spacer />
              <v-btn icon dark @click="dialogAtenderMedico = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
          </v-flex>
          <v-card-text class="pb-5">
            <div class="p-2">
              <div class="col-lg-12">
                <div class="mx-auto">
                  <h3>
                    Escolha a requisição
                  </h3>
                  <div class="mt-8">
                    <v-autocomplete
                      v-model="procedimento"
                      :items="procedimentos"
                      :loading="procedimentoLoading"
                      background-color="white"
                      label="Procedimentos"
                      item-text="procedimento"
                      return-object
                      color="black"
                    >
                      <template v-slot:item="{ item }">
                        <v-list-item-content>
                          <v-list-item-title v-html="item.procedimento" />
                          <v-list-item-subtitle
                            class="mt-1"
                            v-html="
                              item.tipo_procedimento +
                                ' - ' +
                                item.convenio_tipo +
                                ` ${item.data_requisicao &&
                                  '( ' +
                                    item.data_requisicao
                                      .split('-')
                                      .reverse()
                                      .join('-') +
                                    ' )'}`
                            "
                          />
                        </v-list-item-content>
                      </template>
                    </v-autocomplete>
                    <v-btn @click="iniciarAtendimento"
                      >Iniciar atendimento</v-btn
                    >
                  </div>
                </div>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="dialogProntuario"
        v-if="dialogProntuario"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
      >
        <prontuarios
          :consultas="prontuarios"
          :pacienteInfo="pacienteInfo"
          @close="dialogProntuario = false"
        />
      </v-dialog>
      <v-dialog v-model="dialogTermo" hide-overlay persistent width="40%">
        <termos @close="close()" @gerarPDF="gerarPDF($event)" />
      </v-dialog>
      <v-dialog
        v-model="dialogUploadExames"
        v-if="dialogUploadExames"
        fullscreen
        hide-overlay
        persistent
        transition="dialog-bottom-transition"
      >
        <UploadExames
          :paciente="paciente"
          @close="dialogUploadExames = false"
        />
      </v-dialog>
      <v-dialog
        v-model="dialogReceita"
        v-if="dialogReceita"
        hide-overlay
        persistent
        width="60%"
      >
        <receita @close="close()" @gerarPDF="gerarPDF($event)" />
      </v-dialog>
      <v-dialog
        v-model="dialogComandas"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
      >
        <v-card>
          <v-toolbar dark style="background-color: #1daf80; color: #fff">
            <v-btn icon dark @click="dialogComandas = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Comandas </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items> </v-toolbar-items>
          </v-toolbar>

          <modal-comandas :tutor="tutor" :petId="petIdComanda" />
        </v-card>
      </v-dialog>
    </div>
    <!-- EXAMES PDF -->
    <div id="examesPDF" ref="examesPDF" v-show="false">
      <div
        id="header"
        :class="
          htmlToPdf == 'receita' || 'receita-carbonada'
            ? 'header-receita d-flex'
            : 'd-flex'
        "
      >
        <img
          src="../assets/images/anclivepa_publico.jpeg"
          style="margin: auto"
          alt="Logo anclivepa público"
          v-if="convenioPublico == true"
        />
        <img
          src="../assets/images/logo_anclivepa.png"
          style="margin: auto"
          alt="Logo anclivepa particular"
          v-if="convenioPublico == false"
        />
      </div>
      <div id="main">
        <!-- DOC TERMOS -->
        <div v-if="htmlToPdf == 'termo'" class="termo">
          <!-- CABEÇALHO -->
          <p class="text-center">
            <b v-if="tipoTermo == 'termo_um'"
              >TERMO DE RESPONSABILIDADE E RECUSA LIVRE E ESCLARECIDA PARA
              INTERNAÇÃO, EXAMES E/OU PROCEDIMENTOS CLÍNICOS, AMBULATORIAIS,
              ANESTÉSICOS, TERAPÊUTICOS E/OU CIRÚRGICOS</b
            >
            <b v-if="tipoTermo == 'termo_dois'"
              >TERMO DE CONSENTIMENTO LIVRE E ESCLARECIDO PARA INTERNAÇÃO,
              EXAMES E/OU PROCEDIMENTOS CLÍNICOS, AMBULATORIAIS, ANESTÉSICOS,
              TERAPÊUTICOS E/OU CIRÚRGICOS</b
            >
            <b v-if="tipoTermo == 'termo_tres'"
              >TERMO DE CONSENTIMENTO LIVRE E ESCLARECIDO PARA REALIZAÇÃO DE
              EUTANÁSIA</b
            >
            <b v-if="tipoTermo == 'termo_quatro'"
              >TERMO DE AUTORIZAÇÃO DE GRAVAÇÃO E/OU ENVIO DE IMAGEM E/OU
              ÁUDIO</b
            >
            <b v-if="tipoTermo == 'termo_cinco'"
              >TERMO LIVRE E ESCLARECIDO DE DOAÇÃO DE INSUMOS E PRODUTOS</b
            >
            <b v-if="tipoTermo == 'termo_seis'"
              >TERMO DE CONSENTIMENTO LIVRE E ESCLARECIDO PARA DESTINAÇÃO DE
              CORPO DE ANIMAL EM ÓBITO
            </b>
            <b v-if="tipoTermo == 'termo_sete'">DECLARAÇÃO DE ÓBITO </b>
            <b v-if="tipoTermo == 'termo_oito'"
              >TERMO DE RESPONSABILIDADE PARA ANESTESIA</b
            >
            <b v-if="tipoTermo == 'termo_nove'"
              >TERMO DE ESCLARECIMENTO E RESPONSABILIDADE PARA RETIRADA DE
              ANIMAL
              <br />
              <span>DO SERVIÇO VETERINÁRIO SEM ALTA MÉDICA</span>
            </b>
            <b v-if="tipoTermo == 'termo_dez'"
              >CONTRATO DE PRESTAÇÃO DE SERVIÇOS DE INTERNAÇÃO HOSPITALAR</b
            >
          </p>

          <!-- CORPO -->
          <div>
            <div v-if="tipoTermo == 'termo_um'">
              <p class="small text-justify">
                Eu, {{ tutor.nome }}, portador(a) do CPF {{ tutor.cpf }}, com
                Cédula de Identidade RG/RNE {{ tutor.rg }}, residente e
                domiciliado(a) em {{ tutor.logradouro }}, {{ tutor.numero }} -
                {{ tutor.cidade }}/{{ tutor.estado }}, detentor(a) do telefone
                n° {{ tutor.telefone_1 }} e do email {{ tutor.email }}, na
                condição de tutor(a) e responsável pelo animal de nome
                {{ pet.nome }}, espécie {{ pet.especie }}, sexo {{ pet.sexo }},
                declaro que NÃO autorizo a internação, tratamento clínico e/ou
                cirúrgico do Animal, cuja realização seria feita pelo
                {{ nomeHospitalConvenio }}.
                <!-- Médico(a) Veterinário(a) _____________________________, CRMV
                _________________________, no hospital gerido pela Uni-A
                Educação Ltda. -->
                <br />
                <br />
                Declaro ter sido cientificado(a) pelo(a) Médico(a)
                Veterinário(a) responsável sobre o quadro clínico do meu Animal
                e que há necessidade de realização do(s) seguinte(s) exame(s),
                procedimento(s) e/ou internação:<br />
                <span
                  >_________________________________________________________________________________</span
                ><br />
                <span
                  >_________________________________________________________________________________</span
                ><br />
                <br />
                Estou ciente de que o descumprimento da solicitação de exame(s)
                e/ou procedimento(s) clínico(s), ambulatorial(is),
                anestésico(s), terapêutico(s) e/ou cirúrgico(s) pode comprometer
                e/ou prejudicar a saúde e o quadro clínico do Animal, sua
                qualidade de vida e sua própria vida, sendo de minha total
                responsabilidade as consequências dessa decisão, não havendo o
                que possa reclamar em qualquer esfera administrativa e/ou
                jurídica contra o Médico(a) Veterinário(a) e/ou a Uni-A Educação
                Ltda.
                <br />
                <br />
                Assumo, ainda, ter sido esclarecido pelo(a) Médico(a)
                Veterinário(a) acerca dos possíveis riscos inerentes à situação
                clínica do Animal, bem como dos tratamentos propostos, estando
                o(a) referido(a) Médico(a) Veterinário(a) e a Uni-A Educação
                Ltda. isentos(as) de quaisquer responsabilidades decorrentes de
                tais riscos. <br />
                Autorizo o tratamento e processamento dos dados pessoais do
                tutor(a) responsável acima identificado, para as finalidades
                estabelecidas neste termo, e na forma prevista na legislação.
                <br />
                <br />Observações:
                ____________________________________________________________________
                <br />As informações contidas neste documento foram explicadas
                detalhadamente e em linguagem acessível e de fácil compreensão.
                Sendo assim, assino abaixo.
              </p>
            </div>
            <div v-if="tipoTermo == 'termo_dois'">
              <p class="small text-justify">
                Eu, {{ tutor.nome }}, portador(a) do CPF {{ tutor.cpf }}, com
                Cédula de Identidade RG/RNE {{ tutor.rg }}, residente e
                domiciliado(a) em {{ tutor.logradouro }}, {{ tutor.numero }} -
                {{ tutor.cidade }}/{{ tutor.estado }}, detentor(a) do telefone
                n° {{ tutor.telefone_1 }} e do email {{ tutor.email }}, na
                condição de tutor(a) e responsável pelo animal de nome
                {{ pet.nome }}, espécie {{ pet.especie }}, sexo {{ pet.sexo }},
                cuja realização será feita pelo {{ nomeHospitalConvenio }}.
                <br />
                <br />
                Declaro, ainda, ter sido esclarecido sobre o(s) exame(s) e/ou
                procedimento(s) clínico(s), ambulatorial(is), anestésico(s),
                terapêutico(s) e/ou cirúrgico(s) indicado(s), os possíveis
                riscos inerentes à situação clínica do animal, bem como do(s)
                tratamento(s) proposto(s), estando o(a) Médico(a) Veterinário(a)
                e a Uni-A Educação Ltda. isento(a) de quaisquer
                responsabilidades decorrentes de tais riscos, estando ciente de
                todas as informações. Em caso de emergência, autorizo e dou
                autonomia ao Médico(a) Veterinário(a) para adotar todos os
                procedimentos destinados a restabelecer o quadro clínico do
                animal.<br />
                <br />
                Assumo a responsabilidade total pelos procedimentos
                pós-cirúrgicos, tais como: curativos, uso de colar elisabetano
                e/ou roupas cirúrgicas, comprometendo-me a comunicar os
                profissionais da equipe caso haja qualquer complicação e/ou
                alteração no quadro do Animal. Por fim, após o paciente ter
                recebido alta médica com a consecutiva comunicação do fato, fico
                ciente de que transcorrido 1 (um) dia e não havendo a retirada
                do Animal do estabelecimento dentro do aludido prazo, será
                considerado abandono, sendo o Animal encaminhado para adoção,
                sem o prejuízo das penas legais cabíveis. Com relação aos
                procedimentos pós-cirúrgicos, reconheço não haver o que se possa
                reclamar em qualquer esfera administrativa e/ou jurídica contra
                o Médico(a) Veterinário(a) e/ou a Uni-A Educação Ltda. Atesto,
                ainda, estar ciente de que a Uni-A Educação não se
                responsabiliza por itens pessoais ou do animal deixados na
                internação, e que fui orientado a não deixar tais itens junto ao
                animal no ambiente hospitalar.
                <br />
                <br />Observações:
                ____________________________________________________________________
                <br />
                Ordem de não reanimação: ( ) Sim ( ) Não
                <br />As informações contidas neste documento foram explicadas
                detalhadamente e em linguagem acessível e de fácil compreensão.
                Sendo assim, assino abaixo.
              </p>
            </div>
            <div v-if="tipoTermo == 'termo_tres'">
              <p class="small text-justify">
                Eu, {{ tutor.nome }}, portador(a) do CPF {{ tutor.cpf }}, com
                Cédula de Identidade RG/RNE {{ tutor.rg }}, residente e
                domiciliado(a) em {{ tutor.logradouro }}, {{ tutor.numero }} -
                {{ tutor.cidade }}/{{ tutor.estado }}, detentor(a) do telefone
                n° {{ tutor.telefone_1 }} e do email {{ tutor.email }}, na
                condição de tutor(a) e responsável pelo animal de nome
                {{ pet.nome }}, espécie {{ pet.especie }}, sexo {{ pet.sexo }},
                autorizo o procedimento de eutanásia no Animal. <br />
                <br />Declaro estar ciente dos motivos que levam à realização da
                eutanásia no Animal acima identificado, reconhecendo que esta é
                a opção escolhida por mim, por livre e espontânea vontade, sem
                qualquer vício de consentimento, visando cessar definitivamente
                o sofrimento do referido Animal e, portanto, declaro o livre
                consentimento para a realização da eutanásia do Animal acima
                identificado, a ser realizado pelo
                {{ nomeHospitalConvenio }}.<br />
                <br />
                Declaro, ainda, que fui devidamente esclarecido(a) sobre o
                método a ser utilizado, assim como de que este é um procedimento
                irreversível, não havendo o que possa reclamar em qualquer
                esfera administrativa e/ou jurídica contra o Médico(a)
                Veterinário(a) e/ou a Uni-A Educação Ltda.<br />
                <br />
                <br />Observações:
                ____________________________________________________________________.
                <br />As informações contidas neste documento foram explicadas
                detalhadamente e em linguagem acessível e de fácil compreensão.
                Sendo assim, assino abaixo.
              </p>
            </div>
            <div v-if="tipoTermo == 'termo_quatro'">
              <p class="small text-justify">
                Eu, {{ tutor.nome }}, portador(a) do CPF {{ tutor.cpf }}, com
                Cédula de Identidade RG/RNE {{ tutor.rg }}, residente e
                domiciliado(a) em {{ tutor.logradouro }}, {{ tutor.numero }} -
                {{ tutor.cidade }}/{{ tutor.estado }}, detentor(a) do telefone
                n° {{ tutor.telefone_1 }} e do email {{ tutor.email }}, na
                condição de tutor(a) e responsável pelo animal de nome
                {{ pet.nome }}, espécie {{ pet.especie }}, sexo {{ pet.sexo }},
                autorizo a gravação de imagem e/ou áudio do Animal, a ser
                realizada pelo {{ nomeHospitalConvenio }}. <br />
                <br />Assumo a responsabilidade por solicitar com no mínimo 8
                (oito) horas de antecedência, que sejam gravadas imagens
                diariamente, enquanto perdurar o período de internação. <br />
                <br />
                Em caso de solicitação prévia, declaro que fui esclarecido que o
                vídeo/áudio terá no máximo 2 (dois) minutos, e será enviado
                somente uma vez ao dia, no período entre 12:00 e 18:00,
                dependendo da disponibilidade da equipe da Uni-A Educação
                Ltda<br />
                <br />
                <br />Conforme os termos acima, autorizo o envio para o
                número:_______________________. <br />
                <br />
                Autorizo o tratamento e processamento dos dados pessoais do
                tutor(a) e responsávelacima identificado, para as finalidades
                estabelecidas neste termo e na forma prevista na legislação.
                <br />
                <br />As informações contidas neste documento foram explicadas
                detalhadamente e em linguagem acessível e de fácil compreensão.
                Sendo assim, assino abaixo.
              </p>
            </div>
            <div v-if="tipoTermo == 'termo_cinco'">
              <p class="text-justify">
                Eu, {{ tutor.nome }}, portador do RG {{ tutor.rg }}, CPF
                {{ tutor.cpf }}, firmo, na data do presente documento, a doação
                dos itens listados abaixo ao {{ nomeHospitalConvenio }}
                {{ unidade }}, CNPJ {{ informacoes_unidade.cnpj }}, localizado à
                {{ informacoes_unidade.logradouro }},
                {{ informacoes_unidade.numero }},
                {{ informacoes_unidade.cidade }} –
                {{ informacoes_unidade.cidade }}/{{
                  informacoes_unidade.estado
                }}, e reitero que o faço de livre e espontânea vontade, não
                requerendo contraparte, atual ou futura, por parte do tomador da
                doação.
              </p>
              <span><b>Itens:</b></span
              ><br /><br /><br />
              <div class="d-flex justify-content-between align-items-center">
                <div>
                  <span>__________________________________________</span
                  ><br /><br />
                  <span>__________________________________________</span
                  ><br /><br />
                  <span>__________________________________________</span
                  ><br /><br />
                  <span>__________________________________________</span
                  ><br /><br />
                  <span>__________________________________________</span
                  ><br /><br />
                </div>
                <div>
                  <span>__________________________________________</span
                  ><br /><br />
                  <span>__________________________________________</span
                  ><br /><br />
                  <span>__________________________________________</span
                  ><br /><br />
                  <span>__________________________________________</span
                  ><br /><br />
                  <span>__________________________________________</span
                  ><br /><br />
                </div>
              </div>
            </div>
            <div v-if="tipoTermo == 'termo_seis'">
              <p class="small text-justify">
                Eu, {{ tutor.nome }}, portador do RG {{ tutor.rg }}, CPF
                {{ tutor.cpf }}, declaro, para os devidos fins, que nesta
                ocasião, autorizo a destinação do cadáver do animal
                {{ pet.nome }}, que teve seu óbito constatado na Associação
                Nacional de Clínicos Veterinários de Pequenos Animais São Paulo
                Anclivepa SP, às {{ data.getHours() }}:{{ data.getMinutes() }}h
                do dia {{ data.getDate() }}/{{ data.getMonth() }}/{{
                  data.getFullYear()
                }}, provocado pela provável causa mortis
                _____________________________, constando pelo(a) Médico(a)
                Veterinário(a) _____________________________, CRMV
                _________________________, e que fui informado(a) sobre a
                necessidade de dar tratamento respeitoso e destinação ambiental
                adequada ao cadáver, em respeito às normas ambientais vigentes.
                Assim sendo, autorizo:
                <br />
                <br />
                ( ) Remoção pela minha pessoa em até 24 (vinte e quatro) horas
                <br />
                ( ) Remoção pela pessoa de minha indicação em até 24 (vinte e
                quatro) horas, cujo nome e contato constam no campo de
                observações deste documento <br />
                ( ) Remoção por empresa especializada prestadora de serviços à
                Uni-A Educação <br />
                ( ) Remoção por funerária habilitada, sob minha escolha e
                autorização, cujo nome e contato constam no campo de observações
                deste documento <br />
                <br />
                Estou ciente de que que quando autorizar a remoção pela empresa
                especializada prestadora de serviços, ocorrerá a cremação
                coletiva e as cinzas do animal não serão devolvidas. Afirmo
                estar ciente da possibilidade de realização de necropsia e de
                que a Uni-A Educação Ltda. não possui este serviço no local,
                restando a mim a responsabilidade por destinar o corpo a
                instituição adequada para a execução de tal serviço. <br />
                <br />
                Autorizo que o corpo seja usado para fins educacionais ou
                científicos: ( ) Sim ( ) Não
                <br />
                <br />
                Observações:
                ___________________________________________________________.
                <br />
                As informações contidas neste documento foram explicadas
                detalhadamente e em linguagem acessível e de fácil compreensão.
                Sendo assim, assino abaixo
              </p>
            </div>
            <p v-if="tipoTermo == 'termo_sete'">
              Eu, ______________________________________, médico veterinário
              inscrito no CRMV _________________________, atesto, para os
              devidos fins, que o animal {{ pet.nome }}, do tutor responsável
              {{ tutor.nome }}, faleceu nas dependências da Uni-A Educação
              Ltda., na forma do {{ nomeHospitalConvenio }} {{ unidade }}, e com
              provável causa mortis _____________________________________.
            </p>
            <p v-if="tipoTermo == 'termo_oito'">
              Para fins de ordem legal, declaro que estou ciente dos riscos
              inerentes a qualquer prática anestésica, nada podendo reivindicar
              em qualquer momento, dando, assim, plenos poderes aos
              médicos-veterinários para fazê-lo com minha aquiescência.
              Igualmente, declaro as especificações do animal de minha
              propriedade, dato e assino o presente documento.
            </p>
            <p v-if="tipoTermo == 'termo_nove'" class="text-justify">
              Eu, {{ tutor.nome }}, portador(a) do CPF : {{ tutor.cpf }}, com
              Cédula de Identidade RG/RNE: {{ tutor.rg }}, residente e
              domiciliado(a) na {{ tutor.logradouro }}, {{ tutor.numero }} -
              {{ tutor.cidade }}/{{ tutor.estado }}, detentor(a) do telefone n°
              , {{ tutor.telefone_1 }}, {{ tutor.telefone_2 }} e do e-mail :
              {{ tutor.email }}, na condição de tutor(a) e responsável pelo
              animal de nome : {{ pet.nome }}, espécie: {{ pet.especie }}, raça
              : {{ pet.raca }}, sexo: {{ pet.sexo }}, pelagem : {{ pet.cor }}.
              Declaro sobre o quadro clínico do meu Animal e que este não se
              encontra apto para ser liberado da internação veterinária, sendo
              que não obteve alta médica e que há recomendação para manter o
              paciente em estabelecimento médico apropriado. Estou ciente que a
              retirada do Animal pode comprometer e prejudicar o seu tratamento,
              sua qualidade de vida e sua própria vida, sendo de minha total
              responsabilidade as consequências dessa decisão, não havendo o que
              possa reclamar em qualquer esfera administrativa e/ou jurídica
              contra o Médico(a) Veterinário(a) e/ou Hospital Veterinário .
              <br />
              <br />
              Assumo, ainda, ter sido esclarecido(a) e que estou ciente de que
              há riscos de agravamento da doença do paciente, inclusive morte, e
              acerca dos possíveis riscos ao Animal inerentes ao meu ato e que
              assumo inteira responsabilidade por esse ato, estando(a)
              referido(a) profissional e a Uni-A Educação Ltda. isentos(as) de
              quaisquer responsabilidades administrativas e/ou jurídicas
              decorrentes do feito.
              <br />
              <br />
              Autorizo o tratamento e processamento dos dados pessoais do acima
              identificado, para as finalidades estabelecidas neste termo, e na
              forma prevista na legislação.
              <br />
              <br />
              As informações contidas neste documento foram explicadas
              detalhadamente e em linguagem acessível e de fácil compreensão.
              Sendo assim, assino abaixo.
            </p>
          </div>
          <div
            v-if="tipoTermo == 'termo_cinco' || tipoTermo == 'termo_seis'"
            class="text-right"
          >
            <br /><span
              >{{ informacoes_unidade.cidade }},
              _______/_______/_______________</span
            ><br />
          </div>
          <div v-if="tipoTermo != 'termo_oito'" class="text-right">
            <span class="mr-1">_________________________, </span>
            <span>_______/_______/_______________</span><br />
          </div>
          <br />

          <!-- RODAPÉ -->
          <div
            v-if="(tipoTermo != 'termo_cinco') & (tipoTermo != 'termo_oito')"
          >
            <div class="m-auto text-center">
              <span>_____________________________________</span>
              <br />
              <span v-if="tipoTermo != 'termo_sete'"
                >Assinatura do(a) tutor(a) e/ou responsável</span
              >
              <span v-else
                >Assinatura e carimbo do(a) médico(a) veterinário(a)
                responsável</span
              >
              <br />
              <br />
              <span><b>Testemunhas</b></span
              ><br /><br />
            </div>
            <div class="d-flex justify-content-between align-items-center">
              <div>
                <span>Nome:</span><br />
                <span>CPF:</span><br />
                <span>Assinatura:</span><br />
              </div>
              <div>
                <span>Nome:</span><br />
                <span>CPF:</span><br />
                <span>Assinatura:</span><br />
              </div>
              <div class="w-2"></div>
            </div>
          </div>
          <div v-if="tipoTermo == 'termo_cinco'">
            <div class="m-auto text-center">
              <span>_____________________________________</span>
              <br />
              <span>Assinatura do doador</span>
              <br /><br /><br />
              <span>_____________________________________</span>
              <br />
              <span>Assinatura do receptor</span>
            </div>
          </div>
          <div v-if="tipoTermo == 'termo_oito'" class="mt-n2">
            <div class="">
              <p>
                {{ informacoes_unidade.cidade }}, {{ gerarData().dia
                }}<span class="space">_</span>de<span class="space">_</span
                >{{ gerarData().mes }}<span class="space">_</span
                >{{ gerarData().ano }}.
              </p>
            </div>
            <div>
              <span
                >Código: <b>{{ pet.petCodico }}</b></span
              ><br />
              <span
                >Espécie: <b>{{ pet.especie }}</b></span
              ><br />
              <span
                >Raça: <b>{{ pet.raca }}</b></span
              ><br />
              <span
                >Sexo: <b>{{ pet.sexo }}</b></span
              ><br />
              <span
                >Nome: <b>{{ pet.nome }}</b></span
              ><br />
              <span
                >Tutor: <b>{{ tutor.nome }}</b></span
              ><br />
              <span>
                Endereço:
                <b
                  >{{ tutor.logradouro }}, {{ tutor.numero }} -
                  {{ tutor.cidade }}/ {{ tutor.estado }}</b
                > </span
              ><br />
              <span
                >RG: <b>{{ tutor.rg }}</b></span
              ><br />
              <span
                >CPF: <b>{{ tutor.cpf }}</b></span
              >
            </div>
            <div class="text-center mt-4">
              <span>__________________________________________</span><br />
              <span>{{ tutor.nome }}</span>
            </div>
          </div>
          <div class="d-flex justify-content-center rodape">
            <span class="mr-1">{{ unidade }}</span>
            <span class="mr-1">
              {{ informacoes_unidade.logradouro }},
              {{ informacoes_unidade.numero }},
              {{ informacoes_unidade.bairro }},
              {{ informacoes_unidade.cidade }}/{{
                informacoes_unidade.estado
              }}
              - {{ informacoes_unidade.cep }}
            </span>
            <span>{{ informacoes_unidade.telefone }}</span>
          </div>
        </div>
        <!-- DOC RECEITA -->
        <div
          v-else-if="htmlToPdf == 'receita-carbonada' || htmlToPdf == 'receita'"
          class="carbon zeroEspaco color-black"
        >
          <div class="body">
            <div class="d-flex justify-content-between align-items-center">
              <p>{{ unidade }}</p>
              <div class="text-right">
                <p>
                  {{ informacoes_unidade.logradouro }},
                  {{ informacoes_unidade.numero }} -
                  {{ informacoes_unidade.cidade }}
                </p>
                <p>CEP: {{ informacoes_unidade.cep }}</p>
                <p>Tel.: {{ informacoes_unidade.telefone }}</p>
                <p>CNPJ: {{ informacoes_unidade.cnpj }}</p>
              </div>
            </div>
            <hr />
            <div
              class="d-flex justify-content-between align-items-center m-0 mt-1 p-0 px-1"
              v-if="htmlToPdf == 'receita-carbonada'"
            >
              <p>Receita de Controle Especial</p>
              <p>1ª via: Farmácia | 2ª via: Paciente</p>
            </div>
            <div class="d-flex justify-content-between align-items-stretch gap">
              <div class="border p-1">
                <b>Identificação do Emitente</b><br />
                <br /><b>Nome: </b> <span>{{ usuarioNome }}</span> <br /><b
                  >CRMV: </b
                >{{ crmv }} <span></span> <br /><b>Endereço: </b>
                <span>
                  {{ informacoes_unidade.logradouro }},
                  {{ informacoes_unidade.numero }} </span
                ><br />
                <span>
                  {{ informacoes_unidade.cidade }} -
                  {{ informacoes_unidade.cep }}
                </span>
              </div>
              <div class="border p-1">
                <b>Animal</b><br />
                <br />
                <b>Nome: </b> <span>{{ pet.nome }}</span> <br />
                <b>Idade: </b> <span>{{ pet.idade }}</span> <br />
                <b>Espécie: </b> <span>{{ pet.especie }}</span> <br />
                <b>Raça: </b> <span>{{ pet.raca }}</span> <br />
                <b>Sexo: </b> <span>{{ pet.sexo }}</span> <br />
                <b>Peso: </b> <span>{{ pet.peso }}</span>
              </div>
              <div class="border p-1">
                <b>Tutor</b><br />
                <br />
                <b>Nome: </b> <span>{{ tutor.nome }}</span> <br />
                <b>CPF: </b> <span>{{ tutor.cpf }}</span> <br />
                <b>Endereço: </b>
                <span> {{ tutor.logradouro }}, {{ tutor.numero }} </span><br />
                <span> {{ tutor.cidade }} - {{ tutor.cep }} </span>
              </div>
            </div>
            <div v-if="htmlToPdf == 'receita-carbonada'">
              <u v-for="(item, i) in receita" :key="i">
                <li class="d-flex flex-column resp">
                  <div>
                    <b>{{ i + 1 }} - {{ item.medicamento }}</b>
                    <span> ( {{ item.quantidade }} )</span>
                  </div>
                  &nbsp;&nbsp;&nbsp;&nbsp;{{ item.posologia }}
                </li>
                <br />
              </u>
            </div>
            <div
              v-else
              id="intensReceitaTeste"
              class="ml-1 break-w"
              style="width: 94%;"
            ></div>
          </div>
          <div>
            <br /><br />
            <hr />
            <div
              class="
                d-flex
                justify-content-between
                align-items-stretch
                gap
              "
            >
              <div>
                <p>
                  {{ informacoes_unidade.cidade }}, {{ gerarData().dia
                  }}<span class="space">_</span>de<span class="space">_</span
                  >{{ gerarData().mes }}<span class="space">_</span
                  >{{ gerarData().ano }}.
                </p>
                <p>
                  <b>{{ usuarioNome }}</b>
                </p>
                <p>CRMV: {{ crmv }}</p>
                <br />
                <p class="m-auto text-center">_________________________</p>
                <p class="m-auto text-center">Assinatura</p>
              </div>
              <div>
                <p><b>Identificação do Comprador</b></p>
                <p>Nome:</p>
                <p>RG:</p>
                <p>Endereço:</p>
                <p>Cidade e UF:</p>
                <p>Telefone:</p>
              </div>
              <div>
                <p><b>Identificação do Fornecedor</b></p>
                <p>Data:</p>
                <br /><br />
                <p class="m-auto text-center">_________________________</p>
                <p class="m-auto text-center">Assinatura</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UnidadeService from "../services/unidade_service";
import RequisicaoModal from "@/components/recepcao_medico/requisicaoModal/index.vue";
import InternacaoService from "../services/internacao_service";
import FormConsultaFill from "@/components/consultas/formulario_consulta_fill.vue";
import ModalAtender from "@/components/fila_atendimento/modal_atender";
import UploadExames from "@/components/prontuarios/uploadExames.vue";
import Receita from "@/components/documentos/receita.vue";
import ModalComandas from "@/components/pacientes/modal_comandas_teste.vue";
import RequisicaoExameService from "@/services/requisicao_exame_service.js";
import AgendamentoService from "@/services/agendamento_service";
import Prontuarios from "@/components/prontuarios/prontuarios.vue";
import PetsService from "@/services/pets_service";
import PacienteService from "../services/paciente_service.js";
import Mixin from "@/mixins/vuex_mixin.js";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import Termos from "@/components/documentos/termos.vue";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { Contrato } from "../components/documentos/modelos/contrato_internacao.js";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default {
  components: {
    RequisicaoModal,
    FormConsultaFill,
    ModalAtender,
    Prontuarios,
    Termos,
    UploadExames,
    Receita,
    ModalComandas,
  },
  mixins: [Mixin],
  data() {
    return {
      dialogTipoSaidaInternacao: false,
      perfilUsuario: "",
      unidades: [],
      unidadeSelected: "",
      unidade: "",
      informacoes_unidade: {},
      services: {
        unidadeService: UnidadeService.build(),
        internacaoService: InternacaoService.build(),
        requisicaoExameService: RequisicaoExameService.build(),
        agendamentoService: AgendamentoService.build(),
        petService: PetsService.build(),
        pacienteService: new PacienteService(),
      },
      options: {
        page: 1,
        itemsPerPage: 15,
      },
      totalList: 0,
      filtroParams: {
        executado: "",
        status: "",
      },
      search: "",
      internacaoList: [],
      headersInternacao: [
        {
          text: "ID Pet",
          sortable: false,
          value: "id_pet",
        },
        { text: "Pet", value: "nome_pet" },
        { text: "Cliente", value: "nome_cliente" },
        { text: "Data - Hora de entrada", value: "data_entrada_internacao" },
        { text: "Data - Hora de Alta Médica", value: "data_saida_internacao" },
        // { text: "Criado por", value: "nome_criador" },
        { text: "Status", value: "nome_status_requisicao" },
        { text: "Ações", value: "actions", align: "center" },
      ],
      loadingInternados: false,
      filtrosInternacao: [
        { id: 0, text: "Aguardando Entrada" },
        { id: 1, text: "Internado" },
        { id: 2, text: "Alta Médica" },
        { id: 3, text: "Todos" },
      ],
      filtroInternacao: "",
      dialogConfirmarRequisicaoSaida: false,
      dialogRequisicao: false,
      dialogFormAltaInternacao: false,
      dialogReceita: false,
      dialogComandas: false,
      petIdComanda: {},
      unidadeLogado: "",
      petId: "",
      clienteId: "",
      tipoSaida: "",
      formId: "",
      form: {},
      resp: [],
      disableBtn: false,
      form_resp: {
        id_formulario: 0,
        titulo: "",
        descricao: "",
        respostas: [],
      },
      internacao: {},
      dialogAtender: false,
      dialogAtenderMedico: false,
      dialogTermo: false,
      dialogUploadExames: false,
      procedimento: 0,
      procedimentos: [],
      procedimentoLoading: false,
      dialogProntuario: false,
      prontuarios: [],
      pacienteInfo: {},
      tutor: {},
      pet: {},
      htmlToPdf: "",
      convenioPublico: true,
      data: {},
      paciente: {},
    };
  },
  computed: {
    nomeHospitalConvenio() {
      return this.convenioPublico
        ? "Hospital Veterinário Público Anclivepa"
        : "Hospital Veterinário Faculdade Anclivepa";
    },
  },
  mounted() {
    this.perfilUsuario = JSON.parse(sessionStorage.getItem("vuex")).perfil.id;
    this.setUnidadeLogado();
    this.setUnidades();
    this.unidadeSelected = this.unidadeLogado.toString();
    this.listarInternados(this.unidadeSelected);
    this.unidade = JSON.parse(sessionStorage.getItem("vuex")).unidade.nome;
    this.informacoes_unidade = JSON.parse(
      sessionStorage.getItem("vuex")
    ).unidade;
    this.crmv = JSON.parse(sessionStorage.getItem("vuex")).perfil.documento;
    this.usuarioNome = JSON.parse(
      sessionStorage.getItem("vuex")
    ).usuario.dados.nome;
    this.data = new Date();
  },
  watch: {
    options: {
      handler() {
        this.listarInternados(this.unidadeSelected);
      },
      deep: true,
    },
    search(value, oldValue) {
      if (value.length > 2) {
        this.options.page = 1;
        this.listarInternados(this.unidadeSelected);
      }
      if (value.length === 0 && oldValue.length > 0) {
        this.options.page = 1;
        this.listarInternados(this.unidadeSelected);
      }
    },
    filtroInternacao(value) {
      if (value == 0) {
        this.filtroParams = {
          executado: 1,
          status: 1,
        };
      } else if (value == 1) {
        this.filtroParams = {
          executado: 2,
          status: 1,
        };
      } else if (value == 2) {
        this.filtroParams = {
          executado: "",
          status: 2,
        };
      } else {
        this.filtroParams = {
          executado: "",
          status: "",
        };
      }
    },
  },
  methods: {
    respondido(e) {
      if (this.resp.length < 1) {
        this.resp.push({ id_pergunta: e.id_pergunta, resposta: e.resposta });
      } else {
        let respEvent = this.resp
          .map((p) => p.id_pergunta)
          .indexOf(e.id_pergunta);
        if (respEvent >= 0) {
          this.resp[respEvent] = {
            id_pergunta: e.id_pergunta,
            resposta: e.resposta,
          };
        } else {
          this.resp.push({ id_pergunta: e.id_pergunta, resposta: e.resposta });
        }
      }
    },
    listarInternados(unidadeId) {
      this.loadingInternados = true;

      const params = {
        page: this.options.page,
        per_page: this.options.itemsPerPage,
        pet: this.search,
        executado: this.filtroParams.executado,
        status: this.filtroParams.status,
      };

      this.internacaoList = [];
      this.services.internacaoService
        .listarInternados(params, unidadeId)
        .then((resp) => {
          if (resp.status == 200) {
            return resp.json();
          } else if (resp.status == 206) {
            this.internacaoList = [];
          }
          this.loadingInternados = false;
        })
        .then((resp) => {
          this.internacaoList = resp.data.internacao;
          this.totalList = resp.data.internacao.length;
          // this.totalList = resp.data.pagination.num_rows;
          this.loadingInternados = false;
        });
    },
    setUnidadeLogado() {
      this.unidadeLogado = JSON.parse(
        sessionStorage.getItem("vuex")
      ).unidade.id;
    },
    setUnidades() {
      this.services.unidadeService
        .getUnidadeByUsuarioLogado()
        .then((response) => {
          return response.json();
        })
        .then((response) => {
          this.unidades = response.data.map((unidade) => {
            return { text: unidade.unidade, id: unidade.id_unidade };
          });
        })
        .then(() => {});
    },
    showDialogTipoSaida(internacao) {
      this.petId = internacao.id_pet;
      this.clienteId = internacao.cliente_id;
      this.dialogTipoSaidaInternacao = true;
    },
    requisitarSaidaInternacao() {
      this.services.internacaoService
        .requisitarSaidaInternacao(
          this.clienteId,
          this.petId,
          this.unidadeSelected
        )
        .then((resp) => {
          console.log(resp);
          this.dialogConfirmarRequisicaoSaida = false;
          this.listarInternados(this.unidadeSelected);
        });
    },
    openDialogRequisicao(item) {
      this.petId = item.id_pet;
      this.clienteId = item.cliente_id;
      this.dialogRequisicao = true;
    },
    gerarAlta(item) {
      console.log(item);
    },
    showdialogConfirmarSaida(tipoSaida) {
      this.tipoSaida = tipoSaida;
      this.dialogTipoSaidaInternacao = false;
      this.dialogConfirmarRequisicaoSaida = true;
    },
    async prepareFormAltaInternacao() {
      this.disableBtn = true;
      const tipoSaida = this.tipoSaida == "obito" ? 2 : 1;
      this.formId = await this.services.internacaoService
        .getIdFormularioAltaMedica(tipoSaida)
        .then((resp) => {
          if (resp.status == 200) {
            return resp.json();
          }
        })
        .then((resp) => resp.data[0].id)
        .catch(() => {
          this.disableBtn = false;
          this.loadingInternados = false;
          this.$_ACTIONS_showSnackbarMessage({
            message: "Nenhum formulário encontrado para Alta Médica",
            color: "error",
          });
        });

      this.loadingForm(this.formId);
    },
    loadingForm(formId) {
      this.form = {};
      this.services.internacaoService
        .getFormulario(formId)
        .then((resp) => {
          if (resp.status == 200) {
            return resp.json();
          } else {
            this.disableBtn = false;
            this.dialogConfirmarRequisicaoSaida = false;
            this.$_ACTIONS_showSnackbarMessage({
              message: this.$global.messages.internalServerError,
              color: "error",
            });
          }
        })
        .then((resp) => {
          console.log(resp);
          this.form.titulo = resp.data.titulo;
          this.form.descricao = resp.data.descricao;
          this.form.id_medico = resp.data.id_medico;
          this.form.perguntas = resp.data.perguntas
            .filter((pergunta) => pergunta.ativo)
            .sort((a, b) => {
              if (a.ordem < b.ordem) return -1;
              if (a.ordem > b.ordem) return 1;
              return 0;
            });
        })
        .then(() => {
          this.disableBtn = false;
          this.dialogConfirmarRequisicaoSaida = false;
          this.dialogFormAltaInternacao = true;
          this.loadingInternados = false;
        });
    },
    salvarFormAltaInternacao() {
      this.disableBtn = true;
      this.form_resp.titulo = "titulo";
      this.form_resp.descricao = "descricao";
      this.form_resp.id_formulario = parseInt(this.formId);
      this.form_resp.respostas = this.resp;
      this.services.internacaoService.registerAltaMedica(
        {
          onSucess: (status) => (body) => {
            if (status === 200) {
              this.form_resp = {};
              this.resp = [];
              this.$_ACTIONS_showSnackbarMessage({
                message:
                  this.internacao.codigo == 3
                    ? "Alta hospitalar salva com sucesso"
                    : "Alta médica salva com sucesso",
                color: "sucess",
              });
              this.dialogFormAltaInternacao = false;
              this.disableBtn = false;
              this.listarInternados(this.unidadeSelected);
            } else {
              this.$_ACTIONS_showSnackbarMessage({
                message:
                  body.message || this.$global.messages.internalServerError,
                color: "error",
              });
              this.disableBtn = false;
            }
          },
          onError: (message) => {
            this.$_ACTIONS_showSnackbarMessage({
              message: message || this.$global.messages.internalServerError,
              color: "error",
            });
            this.disableBtn = false;
          },
          onEnd: () => {},
        },
        this.internacao.codigo == 3 ? 3 : this.tipoSaida == "obito" ? 2 : 1,
        this.petId,
        this.clienteId,
        this.unidadeSelected,
        this.form_resp
      );
    },
    iniciarAltaHospitalar(internacao) {
      this.petId = internacao.id_pet;
      this.clienteId = internacao.cliente_id;
      this.internacao = internacao;
      this.prepareFormAltaHospitalar();
    },
    async prepareFormAltaHospitalar() {
      this.loadingInternados = true;

      this.formId = await this.services.internacaoService
        .getIdFormularioAltaMedica(3)
        .then((resp) => {
          if (resp.status == 200) {
            return resp.json();
          }
        })
        .then((resp) => resp.data[0].id)
        .catch(() => {
          this.disableBtn = false;
          this.loadingInternados = false;
          this.$_ACTIONS_showSnackbarMessage({
            message: "Nenhum formulário encontrado para Alta Hospitalar",
            color: "error",
          });
          return "";
        });

      if (this.formId) {
        this.loadingForm(this.formId);
      }
    },
    async atender(item) {
      console.log(item);
      this.internacao = item;

      if (this.perfilUsuario == 3) {
        this.dialogAtender = true;
      } else if (this.perfilUsuario == 2) {
        await this.listarProcedimentos();
        this.dialogAtenderMedico = true;
      }
    },
    async listarProcedimentos() {
      this.procedimentoLoading = true;
      const requisicoesPet = await this.services.requisicaoExameService.getRequisicoesAbertasAtendimentoMedico(
        this.internacao.id_pet,
        this.unidadeSelected
      );

      if (requisicoesPet.data) {
        this.procedimentos = requisicoesPet.data;
      }

      this.procedimentoLoading = false;
    },
    async iniciarAtendimento() {
      if (!this.procedimento?.procedimento_id) {
        this.$_ACTIONS_showSnackbarMessage({
          message: "Escolha um procedimento.",
          color: "error",
        });
        return;
      }

      const body = {
        petId: this.internacao.id_pet,
        unidadeId: this.unidadeSelected,
        medicoId: ~~JSON.parse(sessionStorage.getItem("vuex")).usuario.dados
          .medicoId,
        procedimentoId: this.procedimento.procedimento_id,
        tipoProcedimentoId: this.procedimento.tipo_procedimento_id,
        requisicaoId: this.procedimento.id,
      };
      console.log(body);
      await this.services.agendamentoService.agendarConsultaPrimeiroSlotLivre(
        body
      );
      await this.$router.replace({ name: "dashboard" });
    },
    async listProntuarios(item) {
      await this.services.petService
        .getConsultasbyUnidade(item.id_pet, this.unidadeSelected)
        .then(async (response) => {
          const data = await response.json();
          if (response.status !== 200) {
            this.$_ACTIONS_showSnackbarMessage({
              message: data.message,
              color: response.status === 200 ? "sucess" : "error",
            });
          }
          this.pacienteInfo = {
            nome: item.nome_cliente,
            nome_pet: item.nome_pet,
            id_pet: item.id_pet,
            id_cliente: item.cliente_id,
          };
          this.prontuarios = data.data.consultas;
          this.dialogProntuario = true;
        });
    },
    async showComandasPet(item) {
      this.petIdComanda = item.id_pet;
      await this.getTutor(item.cliente_id);
      this.dialogComandas = true;
    },
    async getTutor(idCliente) {
      await this.services.pacienteService
        .getPacienteByClienteId(idCliente)
        .then((resp) => {
          this.tutor = resp.data;
        });
    },
    getPet(idPet) {
      this.services.petService.getPetById(idPet).then((resp) => {
        this.pet = {
          nome: resp.nome_pet,
          especie: resp.nome_especie,
          raca: resp.nome_raca,
          sexo: resp.sexo == "M" ? "Macho" : "Fêmea",
          peso: resp.peso || "Não cadastrado",
          petCodico: resp.id_pet,
          cor: resp.cor,
        };
      });
    },
    async gerarTermos(item) {
      this.htmlToPdf = "";
      this.getTutor(item.cliente_id);
      this.getPet(item.id_pet);

      this.nome = this.tutor.nome;
      this.cpf = this.tutor.cpf;

      this.dialogTermo = true;
    },
    async listUpload(item) {
      await this.getTutor(item.cliente_id);
      this.paciente = {
        id_paciente: this.tutor.id_paciente,
        id_pet: item.id_pet,
      };
      this.dialogUploadExames = true;
      // this.forceRender();
    },
    async gerarReceitasNovas(item) {
      this.htmlToPdf = "";
      await this.getTutor(item.cliente_id);
      this.getPet(item.id_pet);

      this.nome = item.nome;
      this.htmlToPdf = "receita";
      this.dialogReceita = true;
    },
    gerarPDF(info) {
      // let content = "";
      this.convenioPublico = info.convenio == "publico" ? true : false;
      switch (info.doc) {
        case "termo":
          if (info.termo_tipo == "termo_dez") {
            this.termo_dez();
            this.close();
          } else {
            this.htmlToPdf = info.doc;
            this.tipoTermo = info.termo_tipo;
            this.procedimento = info.procedimento;
            this.$nextTick(() => {
              this.close();
              this.criarPDF(
                this.$refs.examesPDF.innerHTML,
                "Termo de Responsabilidade",
                "padrao"
              );
            });
          }
          break;
        case "receita":
          // this.htmlToPdf = info.doc;
          document.getElementById("intensReceitaTeste").innerHTML =
            info.receita;
          this.receitas = document.getElementById(
            "intensReceitaTeste"
          ).children;
          this.receitas.forEach((item) => {
            // item.innerText = item.innerText.replaceAll("_", ".");
            console.log(item.innerText);
            item.style.cssText =
              "margin: 3px; font-size: 4px; width: 97%; font-family: Times, Times New Roman, serif;";
          });
          // this.receita = info.receita;

          this.$nextTick(() => {
            this.close();
            this.criarPDF(this.$refs.examesPDF.innerHTML, "Receita", "padrao");
          });
          break;
        default:
          console.log("Nenhum tipo de documento encontrado");
          break;
      }
    },
    criarPDF(content, nome, tipo) {
      console.log(this.htmlToPdf);
      console.log(content);
      window.html2canvas = html2canvas;
      if (tipo == "padrao") {
        var doc = new jsPDF("p", "mm", "a4");
        doc.html(content, {
          callback: function(pdf) {
            pdf.save(nome + ".pdf");
          },
          x: 5,
          y: 5,
        });
      } else {
        var docm = new jsPDF("l", "mm", "a4");
        docm.html(content, {
          callback: function(pdf) {
            pdf.save(nome + ".pdf");
          },
          x: 1,
          y: 1,
        });
      }
    },
    gerarData() {
      const meses = [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novovembro",
        "Dezembro",
      ];
      let data = new Date();
      let dataFormatada = {
        dia: data.getDate(),
        mes: meses[data.getMonth()],
        ano: data.getFullYear(),
      };
      return dataFormatada;
    },
    close() {
      this.dialog = false;
      this.dialogGaleria = false;
      this.dialogFotosClassificadas = false;
      this.dialogAtestado = false;
      this.dialogEncaminhamento = false;
      this.dialogExames = false;
      this.dialogReceita = false;
      this.dialogReceitaCarbonada = false;
      this.dialogTermo = false;
      this.dialogArquivo = false;
      // this.forceRender();
    },
    termo_dez() {
      const docArquivo = Contrato(
        this.nomeHospitalConvenio,
        this.unidade,
        this.informacoes_unidade,
        this.tutor,
        this.pet,
        this.convenioPublico
      );
      pdfMake
        .createPdf(docArquivo)
        .download(
          `Contrato internação hospitalar - ${this.tutor.nome} - ${this.pet.nome}.pdf`
        );
    },
  },
};
</script>

<style scoped>
#examesPDF {
  text-align: center;
}

p {
  letter-spacing: 0.3px;
}

.break-w {
  word-wrap: break-word;
}

strong {
  letter-spacing: 0.5px !important;
}

#header,
#footer,
#main {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  margin-left: auto;
  width: 12.5rem;
  margin-right: auto;
}
#header img {
  width: 1rem;
}
.header-receita {
  margin: 0 !important;
  padding: 0 !important;
}
#main {
  display: flex;
  justify-content: center;
  align-content: center;
  font-size: 4px;
  height: 15.8rem;
  max-height: 17rem;
  /* width: 13vw; */
  padding-left: 0.2rem;
  padding-right: 0.2rem;
  /* background-color: tomato; */
}
#footer {
  /* background-color: rebeccapurple; */
  margin-top: 0.1rem;
  margin-bottom: 0;
  margin-right: 0.1rem;
  height: 1.2rem;
}
#footer img {
  max-width: 12.5rem;
  max-height: 1.2rem;
}

.carbonada {
  font-size: 3px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  /* background-color: rgb(233, 255, 255); */
  margin-left: 6rem;
  padding-top: 0px;
}

.carbon {
  width: 100vw !important;
  font-size: 3px;
}

.carbon .body {
  min-height: 30vh !important;
}
.carbon .rodape {
  min-height: 70vh !important;
  position: static !important;
  bottom: 0 !important;
}

.carbon p,
.carbon hr {
  margin: 0 !important;
  padding: 0 !important;
}
.gap {
  gap: 1px;
}
.texto {
  padding: 1px;
  font-size: 5px;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}
.space {
  color: white;
}
.zeroEspaco {
  padding: 0 !important;
  margin: 0 !important;
}
.marg-2 {
  margin-left: 0.7rem !important;
  margin-right: 0.7rem !important;
}
.indent {
  text-indent: 5px;
}
.termo {
  font-size: 3.5px;
  color: black;
  margin: 0 !important;
  padding: 0 !important;
  width: 100vw;
}
.color-black {
  color: black;
}
.rodape {
  position: fixed;
  bottom: 0;
}
.termo .small {
  line-height: 4px !important;
  letter-spacing: 0.2px !important;
  font-size: 3.2px;
}
.listaIntensReceita {
  display: flex;
  flex-direction: column;
  gap: 0 !important;
  color: brown !important;
}
</style>
