import HTTPService from "@/services/http_service.js";

export default class FilaAtentederEspecialidadeService extends HTTPService {
  resource = "fila-atender-especialidade";

  static build() {
    return new FilaAtentederEspecialidadeService();
  }

  async atenderEspecialidadeTelevet(idRequisicaoProcedimento, idMedico) {
    return await this.post(this.resource, {
      id_requisicao_procedimento: idRequisicaoProcedimento,
      id_medico: idMedico,
    });
  }
}
