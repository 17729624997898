<template>
  <div>
    <v-form class="p-lg-3 row" ref="form" v-model="valid" lazy-validation>
      <div class="col-lg-12 row">
        <!-- CAMPO DE DATA INÍCIO -->
        <div class="col-lg-3 form-group clearfix mb-3">
          <v-text-field
            label="Data Início"
            :error-messages="error.dataInicio"
            v-model="dataInicio"
            :rules="validationDataInicio"
            clear-icon="clear"
            required
            style="margin: 0 5px; padding-top: 0px !important"
            :type="'date'"
            :outlined="false"
          />
        </div>
        <!-- CAMPO DE DATA FINAL -->
        <div class="col-lg-3 form-group clearfix mb-3">
          <v-text-field
            label="Data Final"
            :error-messages="erroDataMenorQue"
            v-model="dataFinal"
            :rules="validationDataFinal"
            clear-icon="clear"
            required
            style="margin: 0 5px; padding-top: 0px !important"
            :type="'date'"
            :outlined="false"
          />
        </div>
        <!-- CAMPO DE HORA INÍCIO -->
        <div class="col-lg-3 form-group clearfix mb-3">
          <v-text-field
            label="Hora Início"
            v-model="horaInicio"
            :rules="validationHorarioInicio"
            :type="'text'"
            required
            v-mask="'##:##'"
            placeholder="Hora Início"
            style="padding-top: 0 !important"
          ></v-text-field>
        </div>
        <!-- CAMPO DE HORA FINAL -->
        <div class="col-lg-3 form-group clearfix mb-s3">
          <v-text-field
            label="Hora Final"
            :error-messages="erroHorarioMenorQue"
            v-model="horaFinal"
            :rules="validationHorarioFinal"
            :type="'text'"
            v-mask="'##:##'"
            required
            placeholder="Hora Final"
            style="padding-top: 0 !important"
          ></v-text-field>
        </div>
        <!-- CAMPO DE DIAS DA SEMANA -->
        <div class="col-lg-12 form-group clearfix">
          <div class="d-flex align-center">
            <v-select
              v-model="diasDaSemanaEscolhidos"
              :items="$global.utils.diasDaSemana"
              label="Dias da semana"
              :placeholder="' '"
              multiple
              :rules="validationDiasDaSemana"
              chips
              item-text="label"
              item-value="value"
              class="col-4"
              style="padding: 0"
            >
            </v-select>

            <!-- CAMPO DE TEMPO DE ATENDIMENTO -->
            <v-text-field
              label="Tempo de Atendimento"
              :error-messages="error.tempoAtendimento"
              v-model="tempoAtendimento"
              :rules="validationTempoAtendimento"
              v-mask="'##:## hrs'"
              :type="'text'"
              required
              placeholder="Tempo de Atendimento"
              class="col-4 ml-5 mt-3"
            ></v-text-field>

            <!-- CAMPO DE PROCEDIMENTO -->
            <div class="col-lg-4 form-group clearfix text-dark mt-n2">
              <label for="Procedimento">Especialidade</label>
              <v-select
                v-model="especialidadeId"
                :items="especialidadeList"
                :rules="validationProcedimento"
                required
                label="Especialidade"
                item-text="descricao"
                item-value="id"
                color="black"
                solo
              >
              </v-select>
            </div>
          </div>
        </div>

        <div class="col-lg-12">
          <div class="d-flex align-center">
            <!-- CAMPO DE ENCAIXE -->
            <div class="col-lg-4 form-group">
              <label for="Procedimento">Gerar Encaixes</label>
              <v-radio-group v-model="encaixe">
                <v-radio label="Sim" value="1"></v-radio>
                <v-radio label="Não" value="0"></v-radio>
              </v-radio-group>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-12 d-flex flex-row-reverse">
        <v-btn class="btn-app-primary" @click="criarAgenda" :loading="loading">
          <span>Criar Agenda</span>
        </v-btn>
      </div>
    </v-form>
  </div>
</template>
<script>
import AgendaService from "@/services/agenda_service.js";
import ValidationService from "@/services/validation_service.js";
import VuexSnackbarMixin from "@/mixins/vuex_snackbar_mixin.js";
import VuexUsuarioMixin from "@/mixins/vuex_usuario_mixin.js";
import TipoProcedimentoMixin from "@/mixins/tipo_procedimento_mixin";
import DadosCadastraisMixin from "@/mixins/dados_cadastrais_mixin.js";
import MedicoService from "../../services/medico_service";
import EspecialidadeService from "@/services/especialidade_service.js";
import DisponibilidadeService from "@/services/disponibilidade_service.js";

export default {
  mixins: [
    VuexSnackbarMixin,
    VuexUsuarioMixin,
    TipoProcedimentoMixin,
    DadosCadastraisMixin,
  ],
  beforeMount() {
    this.validationService = ValidationService;
    this.setEspecialidades();
  },
  data: () => ({
    especialidadeService: EspecialidadeService.build(),
    medicoService: MedicoService.build(),
    disponibilidadeService: DisponibilidadeService.build(),
    loading: false, // Guarda o estado do loading de submit
    loadingListarMedicos: false,
    valid: false, // Guarda a variável que guarda o estado da validação do formulário
    procedimentoEscolhido: "", // Guarda o procedimento escolhido a partir da busca
    dataInicio: "",
    especialidadeList: [],
    especialidadeId: 0,
    dataFinal: "",
    horaInicio: "",
    horaFinal: "",
    encaixe: null,
    diasDaSemanaEscolhidos: [],
    tempoAtendimento: "",
    procedimentos: [], // Guarda a lista de procedimentos encontrados a partir da busca
    headers: [
      {
        text: "Procedimento",
        value: "procedimento",
        align: "start",
        sortable: true,
      },
      { text: "Horário", value: "horario" },
      { text: "Data", value: "date" },
      { text: "Status", value: "status" },
      { text: "Ações", align: "end" },
    ],
    error: {
      tempoAtendimento: "",
      dataInicio: "",
    },
    validationService: {},
    medicos: [],
    medicoId: 0,
  }),
  async mounted() {
    this.loadingListarMedicos = true;
    this.medicos = await this.medicoService.getMedicoListByUnidadeId(
      this.unidadeId
    );
    this.loadingListarMedicos = false;
    this.encaixe = "1";
  },
  computed: {
    unidadeId() {
      return this.$_GETTER_Unidade.id;
    },
    erroHorarioMenorQue() {
      const tempoMinimoConsulta = this.$global.consultas.tempoMinimo;
      const error = this.validationService.horarioMaiorQue(
        this.horaInicio,
        this.horaFinal,
        tempoMinimoConsulta / 60,
        `A consulta deve ter no mínimo ${tempoMinimoConsulta} minutos`
      )();
      return error === true ? "" : error;
    },
    erroDataMenorQue() {
      const error = this.validationService.dataMaiorQue(
        this.dataInicio,
        this.dataFinal
      )();
      return error === true ? "" : error;
    },
    validationDataInicio() {
      return [
        this.validationService.required("Informe uma data de início."),
        this.validationService.dataMaiorQue(
          this.dataInicio,
          this.dataFinal,
          "A data final deve ser maior que a inicial."
        ),
      ];
    },
    validationDataFinal() {
      return [
        this.validationService.required("Informe uma data final."),
        this.validationService.dataMaiorQue(
          this.dataInicio,
          this.dataFinal,
          "A data final deve ser maior que a inicial."
        ),
        this.validationService.diferencaEntreDatas(
          this.dataInicio,
          this.dataFinal,
          90,
          "O cadastro de agenda só pode ser feito em um intervalo de 90 dias."
        ),
      ];
    },
    validationHorarioInicio() {
      return [
        this.validationService.required("Informe uma hora de início."),
        this.validationService.isHorario24Horas(),
        this.validationService.horarioMaiorQue(this.horaInicio, this.horaFinal),
      ];
    },
    validationHorarioFinal() {
      return [
        this.validationService.required("Informe uma hora final."),
        this.validationService.isHorario24Horas(),
        this.validationService.horarioMaiorQue(this.horaInicio, this.horaFinal),
      ];
    },
    validationDiasDaSemana() {
      return [this.validationService.isNotEmpty("Escolha um dia da semana.")];
    },
    validationTempoAtendimento() {
      return [
        this.validationService.required("Informe um tempo de atendimento."),
        this.validationService.isHorario24Horas(),
        // this.validationService.horarioMaiorQue(
        //   "00:00",
        //   this.tempoAtendimento,
        //   60 * this.$global.consultas.tempoMinimo,
        //   `A consulta deve ter, no mínimo, ${this.$global.consultas.tempoMinimo} minutos.`,
        //   `A consulta deve ter, no mínimo, ${this.$global.consultas.tempoMinimo} minutos.`
        // ),
      ];
    },
    validationProcedimento() {
      return [
        this.validationService.required("Informe um tipo de procedimento."),
      ];
    },
    validationMedico() {
      return [this.validationService.required("Informe um médico.")];
    },
  },
  methods: {
    async setEspecialidades() {
      await this.especialidadeService
        .getEspecialidadeList()
        .then(async (response) => {
          if (response.status !== 200) return;
          const { data } = await response.json();
          const { especialidades } = data;
          this.especialidadeList = especialidades.map((item) => {
            return { ...item };
          });
        })
        .catch((e) => {
          console.error(e);
        });
    },

    criarDisponibilidade() {
      this.disponibilidadeService
        .postCriarDisponibilidade({
          unidade: JSON.parse(sessionStorage.getItem("vuex")).unidade.id,
          data_inicial: this.dataInicio,
          data_final: this.dataFinal,
          hora_inicial: this.horaInicio,
          hora_final: this.horaFinal,
          encaixe: this.encaixe,
          especialidade: this.especialidadeId,
        })
        .then(async (response) => {
          if (response.status !== 200) return;
          const { message } = await response.json();
          this.$_ACTIONS_showSnackbarMessage({
            message: message || this.$global.messages.internalServerError,
            color: response.status === 200 ? "sucess" : "error",
          });
          this.$refs.form.reset();
          this.$emit("response");
        })
        .catch((e) => {
          console.error(e);
          this.$_ACTIONS_showSnackbarMessage({
            message: this.$global.messages.internalServerError,
            color: "error",
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    criarAgenda() {
      if (!this.$refs.form.validate() || !this.valid) {
        return;
      }
      this.loading = true;
      new AgendaService().criarAgendaEspecialidade(
        {
          onSucess: (status) => (body) => {
            this.$_ACTIONS_showSnackbarMessage({
              message:
                body.message || this.$global.messages.internalServerError,
              color: status === 200 ? "sucess" : "error",
            });
            if (
              JSON.parse(sessionStorage.getItem("vuex")).unidade.id == 24 &&
              status === 200
            ) {
              this.criarDisponibilidade();
            } else {
              this.$emit("response");
              this.$refs.form.reset();
            }
          },
          onError: () => {
            this.$_ACTIONS_showSnackbarMessage({
              message: this.$global.messages.internalServerError,
              color: "error",
            });
          },
          onEnd: () => {
            this.loading = false;
          },
        },
        {
          // id_unidade: this.unidadeId,
          id_unidade: JSON.parse(sessionStorage.getItem("vuex")).unidade.id,
          data_inicial: this.dataInicio,
          data_final: this.dataFinal,
          hora_inicio: this.horaInicio,
          hora_fim: this.horaFinal,
          encaixe: this.encaixe,
          id_especialidade: this.especialidadeId,
          tempo_atendimento: this.tempoAtendimento.replace(" hrs", "").trim(),
          id_tipo_procedimento: this.TIPO_PROCEDIMENTO_MIXIN_tipo_procedimento,
          dias_semana: (() => {
            const obj = {};
            for (let i = 0; i < 7; i++) {
              obj[i] = this.diasDaSemanaEscolhidos.includes(i) ? 1 : 0;
            }
            return obj;
          })(),
          adicionado_por: this.$_GETTERS_usuario.id,
        }
      );
    },
  },
};
</script>

<style scoped>
.btn-app-primary {
  background-color: rgb(62, 104, 42) !important;
  border-color: rgb(62, 104, 42);
  color: white;
}
.form-group label {
  text-align: left !important;
  display: block;
}
</style>
