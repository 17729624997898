<template>
  <div>
    <div class="p-lg-3">
      <div class="row justify-content-between">
        <h3 class="mt-0 mb-2 font-weight-bold text-left">Caixas</h3>
      </div>
    </div>
    <div class="container">
      <v-row align="center" class="my-3">
        <v-col class="d-flex col-4">
          <v-select
            :items="unidades"
            label="Unidade"
            item-value="id"
            v-model="unidadeSelected"
          ></v-select>
        </v-col>
        <v-col class="d-flex col-4">
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="datas"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="computedFormatDate"
                label="Data inicial - Data final"
                prepend-icon="mdi-calendar"
                clearable
                v-bind="attrs"
                v-on="on"
                @click:clear="datas = null"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="datas"
              no-title
              range
              scrollable
              color="green darken-4"
            >
              <v-spacer></v-spacer>
              <v-btn text @click="menu = false"> Cancelar </v-btn>
              <v-btn dark color="success" @click="$refs.menu.save(datas)">
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
        <v-col class="d-flex col-2">
          <v-select
            :items="status"
            label="Caixa"
            item-value="valor"
            item-text="desc"
            v-model="statusSelected"
          ></v-select>
        </v-col>
        <v-col class="d-flex" style="margin-bottom: 9px;">
          <!-- <v-btn dark color="indigo" class="btnWid" @click="gerarRelatorio"> -->
          <!-- </v-btn> -->
          <button
            :disabled="verificarForm()"
            @click.prevent="buscarCaixas"
            class="btn btn-app-primary btn-rounded "
          >
            Buscar Caixas
          </button>
        </v-col>
      </v-row>
    </div>
    <div v-if="tableView">
      <div class="container">
        <div class="row">
          <div class="col-12 text-end">
            <v-btn
              v-if="caixas.length > 0"
              color="#2d7930"
              class="style-btn"
              @click="gerarTotalExcel()"
              title="Gerar Excel"
              :loading="loadingRelatorio"
              :disabled="loadingRelatorio"
            >
              Gerar Excel
              <v-icon right style="font-size: 20px" dark>
                mdi-file-excel
              </v-icon>
            </v-btn>

            <!-- <v-btn
              small
              icon
              class="mr-2"
              @click="gerarExcel(item)"
              title="Gerar Excel"
            >
              <v-icon style="font-size: 20px" dark color="#2d7930">
                mdi-file-excel
              </v-icon>
            </v-btn> -->
          </div>
        </div>
      </div>
      <v-data-table
        v-on:update:sort-by="options.ordenacao = true"
        :footer-props="{ 'items-per-page-options': [5, 10, 15, 20] }"
        :headers="headers"
        :items="caixas"
        :options.sync="options"
        :server-items-length="totalList"
        class="elevation-1"
        :loading="loading"
        :loading-text="'Carregando...'"
      >
        <template v-slot:item.actions="{ item }">
          <v-btn
            small
            icon
            class="mr-2"
            @click="detalheCaixaPdf(item)"
            title="Gerar PDF"
          >
            <v-icon style="font-size: 20px" dark color="#b51308">
              mdi-file-pdf
            </v-icon>
          </v-btn>
          <v-btn
            small
            icon
            class="mr-2"
            @click="gerarExcel(item)"
            title="Gerar Excel"
          >
            <v-icon style="font-size: 20px" dark color="#2d7930">
              mdi-file-excel
            </v-icon>
          </v-btn>
          <v-btn
            small
            icon
            class="mr-2"
            @click="detalheCaixa(item)"
            title="Detalhe Caixa"
          >
            <v-icon style="font-size: 20px" dark color="#4c35ba">
              mdi-magnify
            </v-icon>
          </v-btn>
        </template>
        <template v-slot:no-data>
          <p class="text-center">Nenhum registro encontrado</p>
        </template>
      </v-data-table>

      <v-dialog persistent fullscreen v-model="dialogCaixaDetalhe">
        <ModalCaixaDetail
          v-if="dialogCaixaDetalhe"
          :caixa="caixaSelecionada"
          :caixaId="caixaId"
          @close="dialogCaixaDetalhe = false"
        />
      </v-dialog>
    </div>
  </div>
</template>

<script>
import ModalCaixaDetail from "@/components/caixa/caixa-detalhe.vue";
import CaixaService from "../services/caixa_service.js";
import UnidadeService from "../services/unidade_service";
import Mixin from "@/mixins/vuex_snackbar_mixin.js";

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { RelatorioCaixa } from "@/components/documentos/modelos/relatorio_caixa.js";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default {
  mixins: [Mixin],
  components: {
    ModalCaixaDetail,
  },
  data: () => ({
    datas: null,
    menu: false,
    tableView: false,
    formBusca: "",
    unidades: [],
    unidadeSelected: "",
    status: [
      { valor: 3, desc: "Todos" },
      { valor: 1, desc: "Abertos" },
      { valor: 2, desc: "Fechados" },
    ],
    statusSelected: 3,
    dialogCaixaDetalhe: false,
    caixas: [],
    caixaId: "",
    caixaSelecionada: "",
    totalList: 10,
    options: {
      page: 1,
      pageCount: 0,
      itemsPerPage: 15,
      order: false,
      desc: false,
    },
    headers: [
      {
        text: "Id",
        align: "start",
        sortable: false,
        value: "id",
      },
      {
        text: "Nome",
        align: "start",
        sortable: false,
        value: "nome_criado_por",
      },
      {
        text: "Data Abertura",
        align: "start",
        sortable: false,
        value: "data_abertura_formatada",
      },
      {
        text: "Saldo Abertura",
        align: "start",
        sortable: false,
        value: "saldo_abertura_formatada",
      },
      {
        text: "Saldo Informado",
        align: "start",
        sortable: false,
        value: "saldo_informado_formatada",
      },
      {
        text: "Data Fechamento",
        align: "start",
        sortable: false,
        value: "data_fechado_formatada",
      },
      {
        text: "Hora Fechamento",
        align: "start",
        sortable: false,
        value: "hora_fechado_formatada",
      },
      {
        text: "Saldo Fechamento",
        align: "start",
        sortable: false,
        value: "saldo_fechamento_formatada",
      },

      { text: "Ações", align: "end", value: "actions", sortable: false },
    ],
    loading: false,
    loadingRelatorio: false,
    services: {
      caixaService: CaixaService.build(),
      unidadeService: UnidadeService.build(),
    },
  }),
  beforeMount() {
    this.setUnidades();
  },
  watch: {
    options: {
      handler() {
        if (this.caixas.length > 0) {
          if (this.form != "") {
            this.alterarTable();
          }
        }
      },
      deep: true,
    },
  },
  mounted() {},
  computed: {
    computedFormatDate() {
      const datasFormatadas =
        this.datas &&
        this.datas.map((item) =>
          item
            .split("-")
            .reverse()
            .join("/")
        );
      return datasFormatadas;
    },
  },
  methods: {
    async buscarCaixas() {
      const paginationParams = {
        page: 0,
        per_page: this.options.itemsPerPage,
      };
      this.datas.sort();

      const dataInicial = this.datas ? this.datas[0].replaceAll("-", "") : "";
      const dataFinal = this.datas ? this.datas[1].replaceAll("-", "") : "";
      const unidade = this.unidadeSelected ? this.unidadeSelected : "";
      const status = this.statusSelected ? this.statusSelected : 3;
      this.tableView = true;
      this.loading = true;
      this.caixas = [];
      this.totalList = 0;

      this.formBusca = {
        unidade,
        dataInicial,
        dataFinal,
        status,
      };

      await this.services.caixaService
        .getCaixaPaginatedList(
          unidade,
          dataInicial,
          dataFinal,
          status,
          paginationParams
        )
        .then(async (response) => {
          if (response.status !== 200) return;
          const { data } = await response.json();
          const { paginacao, dados } = data;
          this.totalList = paginacao.num_rows;
          for (let index = 0; index < dados.length; index++) {
            const element = dados[index];
            const novoElement = {
              ...element,
              data_abertura_formatada: this.formatarData(element.data_abertura),
              saldo_abertura_formatada: this.formatarSaldo(
                element.saldo_abertura ?? 0.0
              ),
              saldo_informado_formatada: this.formatarSaldo(
                element.saldo_informado ?? 0.0
              ),
              data_fechado_formatada: this.formatarData(
                element.data_fechamento != "1900-01-01"
                  ? element.data_fechamento
                  : ""
              ),
              hora_fechado_formatada:
                element.hora_fechamento != "00:00:00"
                  ? element.hora_fechamento
                  : "-",
              saldo_fechamento_formatada: this.formatarSaldo(
                element.saldo_fechamento ?? 0.0
              ),
            };
            this.caixas.push(novoElement);
          }
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    async alterarTable() {
      const paginationParams = {
        page: this.options.page,
        per_page: this.options.itemsPerPage,
      };
      this.tableView = true;
      this.loading = true;
      this.caixas = [];
      this.totalList = 0;
      await this.services.caixaService
        .getCaixaPaginatedList(
          this.formBusca.unidade,
          this.formBusca.dataInicial,
          this.formBusca.dataFinal,
          this.formBusca.status,
          paginationParams
        )
        .then(async (response) => {
          if (response.status !== 200) return;
          const { data } = await response.json();
          const { paginacao, dados } = data;
          this.totalList = paginacao.num_rows;
          for (let index = 0; index < dados.length; index++) {
            const element = dados[index];
            const novoElement = {
              ...element,
              data_abertura_formatada: this.formatarData(element.data_abertura),
              saldo_abertura_formatada: this.formatarSaldo(
                element.saldo_abertura
              ),
              saldo_informado_formatada: this.formatarSaldo(
                element.saldo_informado
              ),
              data_fechado_formatada: this.formatarData(
                element.data_fechamento != "1900-01-01"
                  ? element.data_fechamento
                  : ""
              ),
              hora_fechado_formatada:
                element.hora_fechamento != "00:00:00"
                  ? element.hora_fechamento
                  : "-",
              saldo_fechamento_formatada: this.formatarSaldo(
                element.saldo_fechamento
              ),
            };

            this.caixas.push(novoElement);
          }
        })
        .catch((e) => {
          console.error(e);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    detalheCaixa(item) {
      this.caixaId = item.id;
      this.caixaSelecionada = item;
      this.dialogCaixaDetalhe = true;
    },

    setUnidades() {
      this.services.unidadeService
        .getUnidadeByUsuarioLogado()
        .then((response) => {
          return response.json();
        })
        .then((response) => {
          this.unidades = response.data.map((unidade) => {
            return { text: unidade.unidade, id: unidade.id_unidade };
          });
        });
    },

    verificarForm() {
      const dataInicial = this.datas ? this.datas[0] : "";
      const dataFinal = this.datas ? this.datas[1] : "";
      const unidade = this.unidadeSelected ? this.unidadeSelected : "";

      if (!dataInicial || !dataFinal || !unidade) {
        return true;
      } else {
        return false;
      }
    },
    formatarData(data) {
      if (data) {
        return data
          .split("-")
          .reverse()
          .join("/");
      } else {
        return "-";
      }
    },
    formatarSaldo(saldo) {
      if (saldo) {
        saldo = saldo.replaceAll(",", "");
        return new Intl.NumberFormat("pt-BR", {
          style: "currency",
          currency: "BRL",
        }).format(saldo);
      } else {
        return "-";
      }
    },

    gerarTotalExcel() {
      this.loadingRelatorio = true;

      this.services.caixaService
        .relatorioExcel(
          this.formBusca.unidade,
          this.formBusca.dataInicial,
          this.formBusca.dataFinal,
          this.formBusca.status
        )
        .then(async (resp) => {
          if (resp.status) {
            this.$_ACTIONS_showSnackbarMessage({
              message: "Falha ao carregar",
              color: "error",
            });
            return;
          }
          this.$_ACTIONS_showSnackbarMessage({
            message:
              "Dados encontrados com sucesso! Gerando arquivo para Excel...",
            color: "sucess",
          });
          let link = document.createElement("a");
          // link.target = "_blank";
          let desc = this.getDesc(this.formBusca);
          link.download = `Relatório Caixa - ${desc}.xlsx`;
          let url = window.URL.createObjectURL(resp);
          link.href = url;
          link.click();
        })
        .catch(() => {
          this.$_ACTIONS_showSnackbarMessage({
            message: "Falha ao carregar",
            color: "error",
          });
        })
        .finally(() => {
          this.loadingRelatorio = false;
        });
      return;
    },

    gerarExcel(item) {
      this.services.caixaService
        .relatorioExcelbyId(item.id)
        .then(async (resp) => {
          if (resp.status) {
            this.$_ACTIONS_showSnackbarMessage({
              message: "Falha ao carregar",
              color: "error",
            });
            return;
          }
          this.$_ACTIONS_showSnackbarMessage({
            message:
              "Dados encontrados com sucesso! Gerando arquivo para Excel...",
            color: "sucess",
          });
          let link = document.createElement("a");
          // link.target = "_blank";
          link.download = `Relatório Caixa - ID Caixa ${item.id}.xlsx`;
          let url = window.URL.createObjectURL(resp);
          link.href = url;
          link.click();
        })
        .catch(() => {
          this.$_ACTIONS_showSnackbarMessage({
            message: "Falha ao carregar",
            color: "error",
          });
        })
        .finally(() => {
          this.loadingRelatorio = false;
        });
      return;
    },

    getDesc(busca) {
      let caixa =
        busca.status == 1
          ? "Caixas Abertos"
          : busca.status == 2
          ? "Caixas Fechados"
          : "Todos os Caixas";

      return `${caixa}`;
    },

    detalheCaixaPdf(caixa) {
      this.services.caixaService
        .getCaixaResumoPdf(caixa.id_unidade, caixa.id)
        .then(async (resp) => {
          const response = await resp.json();
          if (resp.status == 200) {
            this.getUnidadeInfo(response.data, caixa.id_unidade);
            // this.gerarPdf(response.data);
            // this.closeDialogPdf();
          } else if (resp.status == 206) {
            console.error(response);
          } else {
            console.error(response);
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {});
    },
    getUnidadeInfo(caixa, unidadeId) {
      this.services.unidadeService
        .getUnidadeById(unidadeId)
        .then(async (resp) => {
          let unidade = resp.data.unidade[0];
          if (unidade) {
            this.gerarPdf(caixa, unidade);
          } else {
            console.error(resp);
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {});
    },
    gerarPdf(response, unidade) {
      const docArquivo = RelatorioCaixa(
        response.resumo,
        response.detalhe,
        unidade
      );
      pdfMake.createPdf(docArquivo).download(`Relatorio_caixa.pdf`);
    },
  },
};
</script>
<style scoped>
.btn-style-green {
  color: #fff;
  background-color: #1daf80 !important;
  height: 30px !important;
  font-size: 12px;
  padding: 10px !important;
  border-radius: 50px;
  margin: 20px 0px;
}
.style-btn {
  color: #fff;
  font-weight: bold;
}
.style-btn span {
  align-items: end;
}
</style>
