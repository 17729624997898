<template>
  <v-card>
    <span v-if="renderLoading">
      <v-card-title>
        <span class="headline">Edição de perfil</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-progress-circular size="50" indeterminate color="primary" />
        </v-container>
      </v-card-text>
    </span>
    <span v-else>
       <v-card-title>
        <span class="headline">Edição de perfil</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-text-field v-model="perfil.descricao" label="Descrição"></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-data-table
                  :items-per-page="100"
                  :headers="headers"
                  :items="perfil.permissoes"
                  item-key="endpointId"
                  class="elevation-1 width-lg"
                  hide-default-footer
              >
                <template v-slot:item.ativo="{ item }">
                  <v-chip
                      :color="item.ativo ? 'green' : 'red'"
                      @click="tooglePermission(item)"
                      dark
                  >
                    {{ item.ativo ? 'Sim' : 'Não' }}
                  </v-chip>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="$emit('close')">
          Cancelar
        </v-btn>
        <v-btn class="btn-app-primary" style="color: #fff !important;" :loading="loading" text @click="salvarEdicao" :disabled="!allowEdition">
          Salvar
        </v-btn>
      </v-card-actions>
    </span>

  </v-card>
</template>

<script>
import Mixin from "@/mixins/vuex_mixin.js";
import PerfilService from "../../services/perfil_service";

export default {
  mixins:[Mixin],
  data: () => ({
    singleSelect: false,
    loading: false,
    perfil: {permissoes: []},
    renderLoading: false,
    perfilService: PerfilService.build(),
    itemsDataTable: [],
    // dataTable: {
      selected: [],
      headers: [
          {text: 'Controller', align: 'start', sortable: true, value: 'endpointController',},
          {text: 'Method', align: 'start', sortable: true, value: 'endpointMethod',},
          {text: 'Action', align: 'start', sortable: true, value: 'endpointAction',},
          {text: 'Path', align: 'start', sortable: true, value: 'endpointPath',},
          {text: 'Permitido', align: 'start', sortable: true, value: 'ativo',},
      ]
    // }
  }),
  props: {
    perfilId: {
      type: Number,
      required: true,
    }
  },
  async mounted() {
    this.renderLoading = true;
    const perfil = await this.perfilService.getDetalhesByPerfilId(this.perfilId);
    this.renderLoading = false;
    if (!perfil) {
      console.log(`Perfil ${this.perfilId} not found.`)
      this.$emit('close');
    }
    this.perfil = perfil;
    this.itemsDataTable = this.perfil.permissoes;
    console.log(this.perfil)
  },
  methods: {
    tooglePermission(permissao) {
      const permissaoToEdit = this.perfil.permissoes.find(({permissaoId}) => permissao.permissaoId === permissaoId)
      permissaoToEdit.ativo = !permissaoToEdit.ativo;
    },
    async salvarEdicao() {
      this.loading = true;
      try {
        const response = await this.perfilService.editarPerfil(this.perfilId, {
          descricao: this.perfil.descricao,
          permissao: this.perfil.permissoes.filter(({ativo}) => ativo).map(({endpointId}) => endpointId)
        });
        if (response.status !== 200) {
          this.$_ACTIONS_showSnackbarMessage({
            message: this.$global.messages.internalServerError,
            color: 'error'
          })
        }
      } catch (e) {console.error({e})}
      this.loading = false;
      this.$emit('response', this.perfil)
      // const perfil = {}
      // Object.assign(perfil, this.perfil)
      // perfil.nome = this.nome
      // // perfil.alterado_por = this.$_GETTERS_usuario.id
      // const perfilService = new PerfilService()
      // this.loading = true
      // perfilService.updateNomePerfil({
      //   onSucess: status => body => {
      //     this.$_ACTIONS_showSnackbarMessage({
      //       message: body.message || this.$global.messages.internalServerError,
      //       color: status === 200 ? 'sucess' : 'error'
      //     })
      //   },
      //   onError: () => {
      //     this.$_ACTIONS_showSnackbarMessage({
      //       message: this.$global.messages.internalServerError,
      //       color: 'error'
      //     })
      //   },
      //   onEnd: () => {
      //     this.loading = false
      //     this.$emit('response', perfil)
      //   }
      // }, perfil)
    }
  },
  computed: {
    allowEdition () {
      return true;
    },
  }
}
</script>
