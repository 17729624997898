import TipoProcedimentoService from "@/services/tipo_procedimento_service";

export default {
  data: () => ({
    TIPO_PROCEDIMENTO_MIXIN_tipos_procedimento: [],
    TIPO_PROCEDIMENTO_MIXIN_tipo_procedimento: "",
    TIPO_PROCEDIMENTO_MIXIN_loading: false,
  }),
  methods: {
    async TIPO_PROCEDIMENTO_MIXIN_atualizarLista() {
      this.TIPO_PROCEDIMENTO_MIXIN_loading = true;
      const tipoProcedimentoService = new TipoProcedimentoService();
      await tipoProcedimentoService
        .getTipoProcedimentos()
        .then(async (response) => {
          const { data } = await response.json();
          this.TIPO_PROCEDIMENTO_MIXIN_tipos_procedimento = data.tipo_procedimentos;
          this.TIPO_PROCEDIMENTO_MIXIN_loading = false;
        });
    },
  },
};
