<template>
  <div>
    <v-card min-height="100vh">
      <v-toolbar style="background-color: #1daf80 !important; color: #fff">
        <v-btn icon dark @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>
          <span class="headline">Receita Carbonada</span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-card-text>
        <v-container>
          <v-select
            v-model="convenioSelecionado"
            :items="convenios"
            label="Convênio"
            class="mb-2 col-6"
          >
          </v-select>
          <v-select
            class="col-10"
            v-model="med"
            :items="medicamentos"
            :loading="isLoading"
            item-text="nome"
            label="Medicamento"
            return-object
          ></v-select>
          <!-- <v-text-field
            v-model="medicamento"
            class="col-10"
            label="Medicamento"
          ></v-text-field> -->
          <v-text-field
            v-model="qntd"
            class="col-10"
            label="Quantidade"
          ></v-text-field>
          <v-text-field
            v-model="dosagem"
            class="col-10"
            label="Posologia"
          ></v-text-field>
          <button class="btn-style-green-add" @click="add()">
            Adicionar
          </button>
          <div class="d-flex flex-column" v-if="receita.length > 0">
            <u v-for="(item, i) in receita" :key="i">
              <li class="d-flex flex-column resp">
                <p>
                  <b>{{ i + 1 }}-</b> {{ item.medicamento }}_______{{
                    item.quantidade
                  }}
                  <v-btn @click="remover_item(i)" class="ml-3" small icon
                    ><v-hover v-slot="{ hover }"
                      ><v-icon medium :color="hover ? '#F44336' : 'gray'">
                        fas fa-times</v-icon
                      ></v-hover
                    ></v-btn
                  >
                  <br />{{ item.posologia }}
                </p>
              </li>
            </u>
          </div>
        </v-container>
        <div class="row">
          <div class="col-md-12 text-right d-flex justify-lg-space-between">
            <button
              class="btn btn-cancelar btn-padding font-weight-bold text-gray"
              @click="$emit('close')"
            >
              Cancelar
            </button>
            <button class="btn-style-green" @click="gerarPdf()">
              <span> Gerar PDF </span>
            </button>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import MedicamentoService from "@/services/medicamento_service.js";

export default {
  beforeMount() {
    this.loadMedicamentos();
  },
  data: () => ({
    receita: [],
    med: {},
    isLoading: false,
    medicamentos: [],
    medicamento: "",
    qntd: "",
    dosagem: "",
    convenios: ["Público", "Particular"],
    convenioSelecionado: "",
  }),
  watch: {
    med() {
      this.receita_fill();
    },
    medicamento(e) {
      this.medicamento = e;
      console.log(this.medicamento);
    },
    qntd(e) {
      this.qntd = e;
      console.log(this.qntd);
    },
    dosagem(e) {
      this.dosagem = e;
      console.log(this.dosagem);
    },
  },
  methods: {
    receita_fill() {
      this.dosagem = this.med.posologia;
      this.qntd = this.med.quantidade;
      this.medicamento = this.med.nome;
    },
    loadMedicamentos() {
      const medicamentoService = new MedicamentoService();
      this.isLoading = true;
      medicamentoService.getList({
        onSucess: (status) => (body) => {
          if (status === 200) {
            this.medicamentos = body.data.medicamentos;
          }
        },
        onError: (error) => {
          console.log(error);
        },
        onEnd: () => {
          this.isLoading = false;
        },
      });
    },
    add() {
      this.receita.push({
        medicamento: this.medicamento,
        quantidade: this.qntd,
        posologia: this.dosagem,
      });
      this.medicamento = "";
      this.qntd = "";
      this.dosagem = "";
      this.med = {};
    },
    remover_item(i) {
      this.receita.splice(i, 1);
    },
    gerarPdf() {
      if (this.receita == "") {
        3;
        console.log("Receita é obrigatória");
        return;
      }
      this.$emit("gerarPDF", {
        doc: "receita-carbonada",
        receita: this.receita,
        convenio: this.convenioSelecionado
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .toLowerCase(),
      });
      this.receita = [];
    },
  },
};
</script>
<style scoped>
.btn-style-green {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background-color: #1daf80 !important;
  height: 30px !important;
  font-size: 12px;
  padding: 10px !important;
  border-radius: 50px;
  margin: 20px 10px 20px 0px;
}

.btn-style-green-add {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  background-color: #1daf80 !important;
  height: 30px !important;
  font-size: 18px;
  padding: 10px !important;
  border-radius: 50px;
  margin: 20px auto 0 auto;
}
</style>
