export default {
  "AdendoController::GET->getAdendoListByConsultaId/$1": 84,
  "AdendoController::POST->register": 1,
  "AdministrativoController::GET->getAdministrativoByID/$1": 93,
  "AdministrativoController::GET->getAdministrativoByUserID/$1": 94,
  "AdministrativoController::GET->getAdministrativoList": 95,
  "AdministrativoController::POST->register": 96,
  "AdministrativoController::POST->updateAdministrativoData/$1": 97,
  "AgendaController::POST->deleteAgenda/$1": 29,
  "AgendaController::GET->getAgenda": 26,
  "AgendaController::GET->getAgendaByUnidadeAndData": 143,
  "AgendaController::POST->registerAgenda": 27,
  "AgendaController::POST->registerMultipleAgenda/$1": 30,
  "AgendaController::POST->updateAgenda/$1": 28,
  "AgendaEspecialidadeController::POST->deleteAgendaEspecialidade/$1": 213,
  "AgendaEspecialidadeController::GET->getAgendaEspecialidade": 210,
  "AgendaEspecialidadeController::GET->getAgendaEspecialidadeByUnidade/$1": 276,
  "AgendaEspecialidadeController::POST->registerAgendaEspecialidade": 211,
  "AgendaEspecialidadeController::POST->registerMultipleAgendaEspecialidade/$1": 214,
  "AgendaEspecialidadeController::POST->updateAgendaEspecialidade/$1": 212,
  "AgendamentoController::POST->agendarProximoSlotLivre": 150,
  "AgendamentoController::POST->cancelAgendamento/$1": 34,
  "AgendamentoController::GET->getAgendamentoByID/$1": 31,
  "AgendamentoController::GET->minhasConsultas": 142,
  "AgendamentoController::POST->registerAgendamento": 32,
  "AgendamentoController::POST->registerAgendamentoCliente": 33,
  "AgendamentoController::POST->registerReagendamento": 141,
  "AgendamentoController::POST->updateAgendamento/$1": 35,
  "ArquivoController::POST->deleteArquivo/$1": 65,
  "ArquivoController::GET->getArquivoList": 63,
  "ArquivoController::POST->registerArquivo": 62,
  "ArquivoController::GET->searchArquivoByID/$1": 64,
  "AssistenteController::GET->getAssistenteByID/$1": 37,
  "AssistenteController::GET->getAssistenteByUserID/$1": 38,
  "AssistenteController::GET->getAssistenteList": 39,
  "AssistenteController::POST->register": 40,
  "AssistenteController::POST->updateAssistenteData/$1": 41,
  "AuthController::POST->authenticateAdministrativo": 109,
  "AuthController::POST->authenticateAssistente": 7,
  "AuthController::POST->authenticateMedico": 5,
  "AuthController::POST->authenticatePaciente": 4,
  "AuthController::GET->confirmEmailRegistration/$1": 3,
  "CentroCustoController::POST->deleteCentroCusto/$1": 119,
  "CentroCustoController::GET->getCentroCustoList": 116,
  "CentroCustoController::POST->registerCentroCusto": 115,
  "CentroCustoController::GET->searchCentroCustoByID/$1": 120,
  "CentroCustoController::POST->updateCentroCusto/$1": 118,
  "CEPController::GET->getCEPData/$1": 25,
  "ClassificacaoRiscoController::POST->deleteClassificacaoRisco/$1": 136,
  "ClassificacaoRiscoController::GET->getClassificacaoRiscoList": 134,
  "ClassificacaoRiscoController::POST->registerClassificacaoRisco": 133,
  "ClassificacaoRiscoController::GET->searchClassificacaoRiscoByID/$1": 137,
  "ClassificacaoRiscoController::POST->updateClassificacaoRisco/$1": 135,
  "ComandaController::GET->findComandaAtivaItens": 159,
  "ComandaController::GET->findComandasByClienteId/$1": 171,
  "ComandaController::GET->findComandasByClienteIdByUnidadeId/$1/$2": 260,
  "ComandaController::GET->findComandasItemByComandaId/$1": 172,
  "ComandaController::POST->marcarItemPago/$1/$2": 173,
  "ComandaController::POST->register": 158,
  "ComandaController::POST->fecharComanda/$1": 158,
  "ConsultaController::GET->getConsultaByID/$1": 50,
  "ConsultaController::GET->getConsultaByPacienteID/$1": 48,
  "ConsultaController::GET->getConsultaByPetID/$1": 138,
  "ConsultaController::GET->getConsultaPacienteByMedicoID/$1": 49,
  "ConsultaController::GET->getMinhasConsultas/$1": 140,
  "ConsultaController::POST->registerConsulta": 51,
  "ConsultaController::POST->updateConsulta/$1": 52,
  "ConvenioController::POST->deleteConvenio/$1": 149,
  "ConvenioController::GET->getConvenioList": 146,
  "ConvenioController::GET->getConvenioProcedimentoFilteredByUnidadeAndProcedimento": 153,
  "ConvenioController::POST->registerConvenio": 145,
  "ConvenioController::GET->searchConvenioByID/$1": 147,
  "ConvenioController::POST->updateConvenio/$1": 148,
  "DashboardController::GET->getDashboardByMedicoID/$1": 36,
  "DashboardController::GET->getDashboardByPacienteID/$1": 71,
  "EspecieController::POST->deleteEspecie/$1": 114,
  "EspecieController::GET->findAll": 107,
  "EspecieController::GET->getEspecieList": 111,
  "EspecieController::POST->registerEspecie": 110,
  "EspecieController::GET->searchEspecieByID/$1": 112,
  "EspecieController::POST->store": 108,
  "EspecieController::POST->updateEspecie/$1": 113,
  "ExameController::POST->deleteExame/$1": 61,
  "ExameController::GET->getExameList": 58,
  "ExameController::POST->registerExame": 57,
  "ExameController::GET->searchExameByID/$1": 59,
  "ExameController::POST->updateExame/$1": 60,
  "EspecialidadeController::GET->getEspecialidadeList": 217,
  "EspecialidadeController::POST->registerEspecialidade": 215,
  "EspecialidadeController::POST->updateEspecialidade/$1": 219,
  "EspecialidadeController::POST->deleteEspecialidade/$1": 216,
  "FilaEspecialidadeController::GET->getFilaClienteList": 266,
  "FormaPagamentoController::POST->deleteFormaPagamento/$1": 124,
  "FormaPagamentoController::GET->getFormaPagamentoList": 122,
  "FormaPagamentoController::POST->registerFormaPagamento": 121,
  "FormaPagamentoController::GET->searchFormaPagamentoByID/$1": 125,
  "FormaPagamentoController::POST->updateFormaPagamento/$1": 123,
  "FormularioController::POST->desativaFormulario/$1": 45,
  "FormularioController::GET->getFormularioByID/$1": 47,
  "FormularioController::GET->getFormulariosByMedicoID/$1": 46,
  "FormularioController::POST->registerFormulario": 42,
  "FormularioController::POST->updateFormularioData/$1": 43,
  "FormularioController::POST->updateFormularioSimple/$1": 44,
  "GaleriaClassificacaoController::POST->deleteGaleriaClassificacao/$1": 68,
  "GaleriaClassificacaoController::GET->getGaleriaClassificacaoList": 66,
  "GaleriaClassificacaoController::POST->registerGaleriaClassificacao": 67,
  "GaleriaController::POST->deleteGaleria/$1": 56,
  "GaleriaController::GET->getGaleriaList": 54,
  "GaleriaController::POST->registerGaleria": 53,
  "GaleriaController::GET->searchGaleriaByID/$1": 55,
  "ImportacaoController::POST->registerImportacao": 82,
  "MedicamentoController::POST->deleteMedicamento/$1": 81,
  "MedicamentoController::GET->getMedicamentoList": 78,
  "MedicamentoController::POST->registerMedicamento": 77,
  "MedicamentoController::GET->searchMedicamentoByID/$1": 79,
  "MedicamentoController::POST->updateMedicamento/$1": 80,
  "MedicoController::GET->getMedicoByID/$1": 72,
  "MedicoController::GET->getMedicoByUserID/$1": 73,
  "MedicoController::GET->getMedicoList": 74,
  "MedicoController::POST->register": 75,
  "MedicoController::POST->updateMedicoData/$1": 76,
  "PacienteController::GET->getPacienteByID/$1": 13,
  "PacienteController::GET->getPacienteByUsuarioID/$1": 16,
  "PacienteController::GET->getPacienteList": 12,
  "PacienteController::POST->register": 10,
  "PacienteController::POST->selfRegister": 11,
  "PacienteController::POST->updatePacienteData/$1": 15,
  "PacienteController::POST->updatePacienteObservacao/$1": 14,
  "PerfilController::GET->getPerfilByID/$1": 87,
  "PerfilController::GET->getPerfilList": 86,
  "PerfilController::POST->register": 90,
  "PerfilController::POST->updatePerfilData/$1": 92,
  "PetController::GET->getPetByID/$1": 101,
  "PetController::GET->getPetList": 98,
  "PetController::POST->register": 100,
  "PetController::POST->updatePetData/$1": 99,
  "ProcedimentoController::POST->deleteProcedimento/$1": 22,
  "ProcedimentoController::GET->getProcedimentoFilteredByUnidadeAndConvenio": 154,
  "ProcedimentoController::GET->getProcedimentoList": 23,
  "ProcedimentoController::POST->registerProcedimento": 20,
  "ProcedimentoController::GET->searchProcedimentoByID/$1": 24,
  "ProcedimentoController::POST->updateProcedimento/$1": 21,
  "ProcedimentoValorController::POST->registerProcedimentoValor": 160,
  "ProcedimentoValorController::GET->getProcedimentoValorList": 194,
  "ProcedimentoValorController::POST->updateProcedimentoValor/$1": 195,
  "RacaController::POST->deleteRaca/$1": 106,
  "RacaController::GET->getRacaList": 102,
  "RacaController::POST->registerRaca": 104,
  "RacaController::GET->searchRacaByID/$1": 105,
  "RacaController::POST->updateRaca/$1": 103,
  "RecepcaoController::GET->getRecepcionistaByID/$1": 162,
  "RecepcaoController::GET->getRecepcionistaList": 164,
  "RecepcaoController::POST->registerRecepcionista": 163,
  "RecepcaoController::POST->updateRecepcionistaData/$1": 165,
  "RelatorioController::GET->getProcedimentosConsulta/$1": 155,
  "RelatorioController::GET->getTermoAutorizacaoEutanazia/$1": 139,
  "RequisicaoController::GET->getRequisicoesEmAbertoByPetId": 156,
  "RequisicaoController::POST->marcarComoExecutado": 157,
  "RequisicaoController::POST->register": 152,
  "SetorController::POST->deleteSetor/$1": 129,
  "SetorController::GET->getSetorList": 127,
  "SetorController::POST->registerSetor": 126,
  "SetorController::GET->searchSetorByID/$1": 130,
  "SetorController::POST->updateSetor/$1": 128,
  "TipoConvenioV2Controller::GET->findAll": 144,
  "TipoProcedimentoController::POST->deleteTipoProcedimento/$1": 18,
  "TipoProcedimentoController::GET->getTipoProcedimentoList": 19,
  "TipoProcedimentoController::POST->registerTipoProcedimento": 17,
  "TipoProcedimentoController::GET->searchTipoProcedimentoByID/$1": 131,
  "TipoProcedimentoController::POST->updateTipoProcedimento/$1": 132,
  "TriagemController::POST->register": 151,
  "UnidadeController::GET->getUnidadeList": 85,
  "UnidadeController::POST->register": 88,
  "UnidadeController::POST->updateUnidadeData/$1": 89,
  "UsuarioController::POST->changePassword/$1": 9,
  "UsuarioController::POST->forgetedPassword": 8,
  "UsuarioController::GET->getUsuarioByUsuarioID/$1": 70,
  "UsuarioController::GET->test": 2,
  "UsuarioController::POST->update/$1": 69,
};
